import { Route, RouteProps } from 'react-router-dom';
import React from 'react';

import Providers from 'src/App/components/Providers/Providers';
import { MetricsProvider } from 'src/shared/hooks/useMetrics';
import {
  AuthenticationLevel,
  TscAuthProvider,
} from 'src/shared/hooks/useTscAuth';
import { TscApolloProvider } from 'src/shared/components/TscApolloProvider/TscApolloProvider';
import { ImpersonationBanner } from '../ImpersonationBanner/ImpersonationBanner';

interface Props extends RouteProps {
  authenticationLevel?: AuthenticationLevel;
  children: React.ReactNode;
}

export const AuthRoute = ({
  children,
  authenticationLevel = 'Hirer',
  ...restProps
}: Props) => (
  <Route {...restProps}>
    <Providers
      components={[
        [TscAuthProvider, { authenticationLevel }],
        TscApolloProvider,
        MetricsProvider,
      ]}
    >
      <ImpersonationBanner />
      {children}
    </Providers>
  </Route>
);
