import {
  CountryCodes,
  ProfileTypes,
  TalentSearchUncoupledCountForQueryInputV2,
} from 'src/types';
import extractLocationList from '../utils/extractLocationList';
import { useGetLocationsQuery } from './GetLocations.generated';
import { useGetTalentSearchUncoupledCountQuery } from './GetTalentSearchUncoupledCount.generated';
import { useMetrics } from 'src/shared/hooks/useMetrics';
import * as metrics from '../metrics';
import getLinkEventPayload from '../utils/getLinkEventPayload';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';

interface Props {
  location: string;
  country: string;
  jobTitle: string;
  approachableOnly: boolean;
  hirerCountryCode: CountryCodes;
}

export const useGetSeekAndCombinedProfiles = ({
  location,
  country,
  jobTitle,
  approachableOnly,
  hirerCountryCode: countryCode,
}: Props) => {
  const { dispatchEvent } = useMetrics();
  const { identity } = useTscAuth();
  const advertiserId = identity?.advertiserId;

  const { data: locationsData, loading: locationsLoading } =
    useGetLocationsQuery({
      variables: { location, country },
      skip: !advertiserId,
    });

  const locationList = extractLocationList(locationsData);

  const baseInput: TalentSearchUncoupledCountForQueryInputV2 = {
    uncoupledFreeText: jobTitle,
    locationList,
    approachable: Boolean(approachableOnly),
    countryCode,
  };

  const basePayload = {
    locationList,
    jobTitle,
    approachableOnly,
  };

  const { data: combinedCountsData, loading: combinedCountsLoading } =
    useGetTalentSearchUncoupledCountQuery({
      variables: {
        input: {
          ...baseInput,
          profileTypes: [ProfileTypes.Combined],
        },
      },
      skip: !advertiserId || locationsLoading,
      onCompleted: (data) => {
        dispatchEvent(
          metrics.impressionLinkEvent,
          getLinkEventPayload({
            ...basePayload,
            count: data.talentSearchUncoupledCountForQueryV2,
            profileTypes: [ProfileTypes.Combined],
          }),
        );
      },
    });

  const { data: seekProfilesCountData, loading: seekProfilesCountLoading } =
    useGetTalentSearchUncoupledCountQuery({
      variables: {
        input: {
          ...baseInput,
          profileTypes: [ProfileTypes.Seek],
        },
      },
      skip: !advertiserId || locationsLoading,
      onCompleted: (data) => {
        dispatchEvent(
          metrics.impressionLinkEvent,
          getLinkEventPayload({
            ...basePayload,
            count: data.talentSearchUncoupledCountForQueryV2,
            profileTypes: [ProfileTypes.Seek],
          }),
        );
      },
    });

  return {
    loading:
      locationsLoading || combinedCountsLoading || seekProfilesCountLoading,
    combinedProfilesCount:
      combinedCountsData?.talentSearchUncoupledCountForQueryV2 || 0,
    seekProfilesCount:
      seekProfilesCountData?.talentSearchUncoupledCountForQueryV2 || 0,
    locationList,
  };
};
