import React from 'react';
import { Badge, Box, Stack, Text, Strong } from 'braid-design-system';

import {
  formatJobTitle,
  formatLastUpdated,
  formatLocation,
  formatSalary,
} from '../../utils/profileService';

import { ProfileTypes, CountryCodes } from '../../../../types';
import { InteractionEventParams } from '../../metrics';

import * as styles from '../Styles.css';
import { ProfileFieldsFragment } from '../../data/ProfileFields.fragment.generated';

interface Props {
  profile: ProfileFieldsFragment;
  index: number;
  onCandidateCardClicked: (payload: InteractionEventParams) => void;
  hirerCountry: CountryCodes;
  atsName: string | null;
}

const formatProfileTypeBadge = (
  profile: ProfileFieldsFragment,
  atsName: string | null,
) => {
  const name = atsName ?? 'Your database';
  return profile.profileType === ProfileTypes.Seek ? `SEEK` : `SEEK + ${name}`;
};

function CandidateCard({
  profile,
  index,
  onCandidateCardClicked,
  hirerCountry,
  atsName,
}: Props) {
  const handleCandidateCardClicked = () => {
    const { profileId } = profile;

    onCandidateCardClicked({
      profileId,
      profilePositionOnResults: index + 1,
      profileType: profile.profileType,
    });
  };

  return (
    <Box
      data-profile-id={profile.profileId}
      className={styles.card}
      onClick={handleCandidateCardClicked}
    >
      <Stack space="gutter">
        <Stack space="small">
          <Badge tone="info">{formatProfileTypeBadge(profile, atsName)}</Badge>

          <Stack space="xsmall">
            <Text weight="strong" size="small" truncate>
              {`${profile.firstName} ${profile.lastName}`}
            </Text>

            <Text size="small" truncate>
              {formatJobTitle(profile)}
            </Text>
          </Stack>
        </Stack>

        <Stack space="xsmall">
          <Text size="small" tone="secondary" truncate>
            <Strong>Updated {formatLastUpdated(profile)}</Strong>
          </Text>
          <Text size="small" tone="secondary" truncate>
            {formatSalary(profile.salary, hirerCountry)}
          </Text>
          <Text size="small" tone="secondary" truncate>
            {formatLocation(profile)}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}

export default CandidateCard;
