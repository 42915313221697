import React from 'react';

import { TscAuthProvider } from 'src/shared/hooks/useTscAuth';
import { Splash } from 'src/App/components/Splash/Splash';
import { ImpersonationBanner } from 'src/shared/components/ImpersonationBanner/ImpersonationBanner';

export const Root = () => (
  <TscAuthProvider>
    <ImpersonationBanner />
    <Splash />
  </TscAuthProvider>
);
