import * as Types from '../../../types/generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSimilarCandidatesSeekAndCombinedQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  isSeedProfileLocationOnly?: Types.InputMaybe<
    Types.Scalars['Boolean']['input']
  >;
  countryCode: Types.Scalars['String']['input'];
  language?: Types.InputMaybe<Types.Scalars['LanguageCodeIso']['input']>;
}>;

export type GetSimilarCandidatesSeekAndCombinedQuery = {
  seekProfiles?: {
    seedProfile?: {
      currentJobTitle?: string | null;
      firstName: string;
      lastName: string;
      profileId: number;
      workHistories: Array<{ jobTitle?: string | null }>;
    } | null;
    profiles: {
      count: number;
      items: Array<{
        profileId: number;
        firstName: string;
        lastName: string;
        currentLocation?: string | null;
        pastJobTitles?: Array<string> | null;
        profileType: Types.ProfileTypes;
        currentJobTitle?: string | null;
        lastModifiedDate?: string | null;
        salary?: {
          expected?: {
            currency: string;
            amount: Array<{ frequency: Types.SalaryFrequency; value: any }>;
          } | null;
        } | null;
        workHistories: Array<{
          companyName: string;
          endDate?: string | null;
          startDate?: string | null;
        }>;
      }>;
    };
  } | null;
  combinedProfiles?: {
    seedProfile?: {
      currentJobTitle?: string | null;
      firstName: string;
      lastName: string;
      profileId: number;
      workHistories: Array<{ jobTitle?: string | null }>;
    } | null;
    profiles: {
      count: number;
      items: Array<{
        profileId: number;
        firstName: string;
        lastName: string;
        currentLocation?: string | null;
        pastJobTitles?: Array<string> | null;
        profileType: Types.ProfileTypes;
        currentJobTitle?: string | null;
        lastModifiedDate?: string | null;
        salary?: {
          expected?: {
            currency: string;
            amount: Array<{ frequency: Types.SalaryFrequency; value: any }>;
          } | null;
        } | null;
        workHistories: Array<{
          companyName: string;
          endDate?: string | null;
          startDate?: string | null;
        }>;
      }>;
    };
  } | null;
};

export const GetSimilarCandidatesSeekAndCombinedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSimilarCandidatesSeekAndCombined' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isSeedProfileLocationOnly' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'countryCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'language' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageCodeIso' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'seekProfiles' },
            name: {
              kind: 'Name',
              value: 'talentSearchSimilarCandidatesByIdV2',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'isSeedProfileLocationOnly',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'isSeedProfileLocationOnly',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'countryCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profileTypes' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'StringValue', value: 'seek', block: false },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seedProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SeedProfileFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ProfileFields' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'combinedProfiles' },
            name: {
              kind: 'Name',
              value: 'talentSearchSimilarCandidatesByIdV2',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'isSeedProfileLocationOnly',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'isSeedProfileLocationOnly',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'countryCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profileTypes' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'StringValue',
                            value: 'combined',
                            block: false,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seedProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SeedProfileFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ProfileFields' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SeedProfileFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchProfileResultV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currentJobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workHistories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchProfileResultV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'language' },
                },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pastJobTitles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentJobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastModifiedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expected' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'frequency' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workHistories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetSimilarCandidatesSeekAndCombinedQuery__
 *
 * To run a query within a React component, call `useGetSimilarCandidatesSeekAndCombinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarCandidatesSeekAndCombinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarCandidatesSeekAndCombinedQuery({
 *   variables: {
 *      email: // value for 'email'
 *      first: // value for 'first'
 *      isSeedProfileLocationOnly: // value for 'isSeedProfileLocationOnly'
 *      countryCode: // value for 'countryCode'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetSimilarCandidatesSeekAndCombinedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSimilarCandidatesSeekAndCombinedQuery,
    GetSimilarCandidatesSeekAndCombinedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSimilarCandidatesSeekAndCombinedQuery,
    GetSimilarCandidatesSeekAndCombinedQueryVariables
  >(GetSimilarCandidatesSeekAndCombinedDocument, options);
}
export function useGetSimilarCandidatesSeekAndCombinedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimilarCandidatesSeekAndCombinedQuery,
    GetSimilarCandidatesSeekAndCombinedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSimilarCandidatesSeekAndCombinedQuery,
    GetSimilarCandidatesSeekAndCombinedQueryVariables
  >(GetSimilarCandidatesSeekAndCombinedDocument, options);
}
export type GetSimilarCandidatesSeekAndCombinedQueryHookResult = ReturnType<
  typeof useGetSimilarCandidatesSeekAndCombinedQuery
>;
export type GetSimilarCandidatesSeekAndCombinedLazyQueryHookResult = ReturnType<
  typeof useGetSimilarCandidatesSeekAndCombinedLazyQuery
>;
export type GetSimilarCandidatesSeekAndCombinedQueryResult = Apollo.QueryResult<
  GetSimilarCandidatesSeekAndCombinedQuery,
  GetSimilarCandidatesSeekAndCombinedQueryVariables
>;
