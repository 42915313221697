import React from 'react';
import { Box } from 'braid-design-system';

import * as styles from './Branded.css';

interface Props {
  children: React.ReactNode;
  truncate?: boolean;
}

function Branded({ children, truncate }: Props) {
  return (
    <Box className={`${styles.branded} ${truncate ? styles.truncate : ''}`}>
      {children}
    </Box>
  );
}

export default Branded;
