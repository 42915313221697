import { useEffect, useCallback, useState } from 'react';
import debounce from 'lodash.debounce';

const DEBOUNCE_TIMEOUT_MS = 20;

export type Config<T> = (width: number, height: number) => T | boolean;

export const sideEffects = {
  getWindowWidth: () => window.innerWidth,
  getWindowHeight: () => window.innerHeight,
};

function findMatch<T>(config: Config<T>[] | T) {
  let match;
  const width = sideEffects.getWindowWidth();
  const height = sideEffects.getWindowHeight();

  if (!Array.isArray(config)) {
    return config;
  }

  for (const option of config) {
    const _match = option(width, height);
    if (_match) {
      match = _match;
    }
  }
  return match as T;
}

function useWindowSize<T>(config: Config<T>[] | T) {
  const [match, setMatch] = useState<T>(findMatch<T>(config));

  const handleResize = useCallback(() => {
    setMatch(findMatch<T>(config));
  }, [config]);

  useEffect(() => {
    const debouncedResize = debounce(handleResize, DEBOUNCE_TIMEOUT_MS);
    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [handleResize]);

  return match;
}

export default useWindowSize;
