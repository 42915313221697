import React, { useMemo } from 'react';
import { Box, Column, Columns, Loader, Stack } from 'braid-design-system';

import { useSearchParams } from '../../../shared/hooks/useSearchParams';
import { useMetrics } from '../../../shared/hooks/useMetrics';
import { useWindow } from '../../../shared/hooks/useWindow';
import { usePreferences } from '../../../shared/hooks/usePreferences';

import * as metrics from '../metrics';
import { querySchema } from '../config';

import Title from './Title';
import Results from './Results';
import Logo from './Logo';

import inferCountryFromLocation from '../utils/inferCountryFromLocation';
import getTalentSearchUrl from '../utils/getTalentSearchUrl';
import getLinkEventPayload from '../utils/getLinkEventPayload';
import getSanitizedJobTitle from '../utils/getSanitizedJobTitle';

import { CountryCodes, Globals, ProfileTypes } from '../../../types';
import { LayoutOrientation } from '../types';

import * as styles from './Styles.css';
import NoResults from './NoResults';
import { getCountry } from 'src/shared/utils/generalUtils';
import { useGetTalentSearchUserContextQuery } from 'src/shared/hooks/useTalentSearchContext/gql/GetTalentSearchUserContext.generated';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';
import { useGetSeekAndCombinedProfiles } from '../data/useGetSeekAndCombinedProfiles';

function SeekAndCombined() {
  const { dispatchEvent } = useMetrics();
  const { openTalentSearchUrl } = useWindow();
  const { getRegion } = usePreferences();

  const {
    jobTitle: unSanitizedJobTitle,
    location: unSanitizedLocation,
    approachableOnly,
    layoutOrientation,
    layoutSize,
  } = useSearchParams(querySchema);
  const { identity } = useTscAuth();
  const advertiserId = identity?.advertiserId;

  const { data: contextData, loading: contextLoading } =
    useGetTalentSearchUserContextQuery({
      skip: !advertiserId,
    });
  const atsName = contextData?.talentSearchUserContext.ats?.name ?? null;

  const jobTitle = getSanitizedJobTitle(unSanitizedJobTitle);

  const { inferredLocation, inferredCountry } = inferCountryFromLocation(
    unSanitizedLocation,
    getRegion(),
  );
  const hirerCountry = useMemo(
    () => getCountry(getRegion()).toUpperCase() as CountryCodes,
    [getRegion],
  );

  const {
    loading: profilesLoading,
    seekProfilesCount,
    combinedProfilesCount,
    locationList,
  } = useGetSeekAndCombinedProfiles({
    jobTitle,
    location: inferredLocation,
    country: inferredCountry,
    hirerCountryCode: hirerCountry,
    approachableOnly,
  });

  const handleResultsClick = (count: number, profileType: ProfileTypes) => {
    const talentSearchUrl = getTalentSearchUrl({
      jobTitle,
      approachableOnly,
      profileTypes: [profileType],
      locationList,
    });
    const interactedLinkPayload = getLinkEventPayload({
      count,
      locationList,
      jobTitle,
      approachableOnly,
      profileTypes: [profileType],
    });

    dispatchEvent(metrics.interactedLinkEvent, interactedLinkPayload);
    openTalentSearchUrl(talentSearchUrl);
  };

  const handleNoResultsClick = () => {
    openTalentSearchUrl(Globals.UncoupledSearchPathname);
  };

  const totalCount = seekProfilesCount + combinedProfilesCount;

  const loading = profilesLoading || contextLoading;
  const resultsProps = {
    jobTitle,
    loading,
    approachableOnly,
    layoutOrientation,
    layoutSize,
    atsName,
  };

  const renderSeekResults = () => (
    <Box data-widget-result="seekOnly">
      <Results
        {...resultsProps}
        seekProfilesOnly={true}
        count={seekProfilesCount}
        onResultsClick={() =>
          handleResultsClick(seekProfilesCount, ProfileTypes.Seek)
        }
      />
    </Box>
  );

  const renderCombinedResults = () => (
    <Box data-widget-result="combined">
      <Results
        {...resultsProps}
        seekProfilesOnly={false}
        count={combinedProfilesCount}
        onResultsClick={() =>
          handleResultsClick(combinedProfilesCount, ProfileTypes.Combined)
        }
      />
    </Box>
  );

  const isHorizontalLayout = layoutOrientation === LayoutOrientation.Horizontal;

  const classNames = isHorizontalLayout
    ? `${styles.containerSeekAndCombined[layoutOrientation]} ${
        isHorizontalLayout && styles.containerSeekAndCombinedSizing[layoutSize]
      }`
    : styles.containerSeekAndCombined[layoutOrientation];

  return (
    <Box
      data-widget-id="matchingCandidates"
      data-widget-variant="seekAndCombined"
      className={classNames}
      paddingY="small"
      paddingX="medium"
    >
      {loading && (
        <Stack space="none" align="center">
          <Loader />
        </Stack>
      )}

      {!loading && totalCount === 0 && (
        <NoResults
          layoutOrientation={layoutOrientation}
          layoutSize={layoutSize}
          onClick={handleNoResultsClick}
        />
      )}

      {!loading && totalCount > 0 && (
        <Box className={styles.horizontalFlexFix}>
          <Stack space={isHorizontalLayout ? 'small' : 'medium'}>
            <Title
              count={totalCount}
              jobTitle={jobTitle}
              approachableOnly={approachableOnly}
              layoutSize={layoutSize}
              layoutOrientation={layoutOrientation}
              splitLines={!isHorizontalLayout}
            />
            {isHorizontalLayout ? (
              <Columns space="small" alignY="center">
                {combinedProfilesCount > 0 ? (
                  <Column>{renderCombinedResults()}</Column>
                ) : null}
                {seekProfilesCount > 0 ? (
                  <Column>{renderSeekResults()}</Column>
                ) : null}
                <Column width="content">
                  <Logo layoutSize={layoutSize} />
                </Column>
              </Columns>
            ) : (
              <>
                <Stack space="medium">
                  <Stack space="small">
                    {combinedProfilesCount ? (
                      <>{renderCombinedResults()}</>
                    ) : null}
                    {seekProfilesCount ? <>{renderSeekResults()}</> : null}
                  </Stack>

                  <Box className={styles.logoContainer}>
                    <Logo layoutSize={layoutSize} />
                  </Box>
                </Stack>
              </>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default SeekAndCombined;
