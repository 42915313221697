import React from 'react';
import { Box, Button, Stack } from 'braid-design-system';

import useWindow from '../../../../shared/hooks/useWindow/useWindow';

import SeekLogo from '../../../../shared/components/SeekLogo/SeekLogo';

import { Globals } from '../../../../types';

function NoResults() {
  const { openTalentSearchUrl } = useWindow();

  const handleClick = () => {
    openTalentSearchUrl(Globals.UncoupledSearchPathname);
  };

  return (
    <Box padding="gutter" id="no-results">
      <Stack space="medium" align="center">
        <Box>
          <Button tone="brandAccent" size="small" onClick={handleClick}>
            Explore candidates on SEEK Talent Search
          </Button>
        </Box>
        <SeekLogo />
      </Stack>
    </Box>
  );
}

export default NoResults;
