import React from 'react';
import { Box, Loader } from 'braid-design-system';

import * as styles from '../Styles.css';

function Loading() {
  return (
    <Box className={styles.loadingContainer}>
      <Box className={styles.loader}>
        <Loader />
      </Box>
    </Box>
  );
}

export default Loading;
