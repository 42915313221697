import { Card, Heading, Text, Stack } from 'braid-design-system';
import React from 'react';

const Title = () => (
  <Card>
    <Stack space="gutter">
      <Heading level="2">
        Connected Talent Search AuthN Troubleshooting Page
      </Heading>
      <Text>
        You can find information here that will assist with troubleshooting your
        single-sign on (SSO) experience.
      </Text>
    </Stack>
  </Card>
);

export default Title;
