import * as Types from '../../../types/generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTalentSearchUncoupledCountQueryVariables = Types.Exact<{
  input: Types.TalentSearchUncoupledCountForQueryInputV2;
}>;

export type GetTalentSearchUncoupledCountQuery = {
  talentSearchUncoupledCountForQueryV2: number;
};

export const GetTalentSearchUncoupledCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTalentSearchUncoupledCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TalentSearchUncoupledCountForQueryInputV2',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'talentSearchUncoupledCountForQueryV2',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetTalentSearchUncoupledCountQuery__
 *
 * To run a query within a React component, call `useGetTalentSearchUncoupledCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentSearchUncoupledCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentSearchUncoupledCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTalentSearchUncoupledCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTalentSearchUncoupledCountQuery,
    GetTalentSearchUncoupledCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTalentSearchUncoupledCountQuery,
    GetTalentSearchUncoupledCountQueryVariables
  >(GetTalentSearchUncoupledCountDocument, options);
}
export function useGetTalentSearchUncoupledCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTalentSearchUncoupledCountQuery,
    GetTalentSearchUncoupledCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTalentSearchUncoupledCountQuery,
    GetTalentSearchUncoupledCountQueryVariables
  >(GetTalentSearchUncoupledCountDocument, options);
}
export type GetTalentSearchUncoupledCountQueryHookResult = ReturnType<
  typeof useGetTalentSearchUncoupledCountQuery
>;
export type GetTalentSearchUncoupledCountLazyQueryHookResult = ReturnType<
  typeof useGetTalentSearchUncoupledCountLazyQuery
>;
export type GetTalentSearchUncoupledCountQueryResult = Apollo.QueryResult<
  GetTalentSearchUncoupledCountQuery,
  GetTalentSearchUncoupledCountQueryVariables
>;
