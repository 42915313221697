import { Box, Button, ContentBlock, Inline, Stack } from 'braid-design-system';
import React from 'react';
import { AccountSelector } from 'src/shared/components/AccountSelector/AccountSelector';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';

export const AccountSelect = () => {
  const { logout } = useTscAuth();
  const showSignout = false;

  return (
    <Box marginY="gutter">
      <ContentBlock width="large">
        <Stack space="gutter">
          <AccountSelector />
          {showSignout && (
            <Inline space="small">
              <Button tone="brandAccent" onClick={() => logout()}>
                Sign out
              </Button>
            </Inline>
          )}
        </Stack>
      </ContentBlock>
    </Box>
  );
};
