import { print } from './print';

export const logMetrics = () => {};

export const logGraphQLError = (
  graphQlError: any,
  operationName: any,
  operationMetadata: any,
) => {
  print('graphQlError: ', graphQlError);
  print('operationName: ', operationName);
  print('operationMetadata: ', operationMetadata);
};

export const logError = (
  networkError: any,
  operationName: any,
  operationMetadata: any,
) => {
  print('networkError: ', networkError);
  print('operationName: ', operationName);
  print('operationMetadata: ', operationMetadata);
};

export const login = () => {};
