import React, { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import graphQLClient from '@seek/hirer-graphql-client';
import * as defaults from './utils/defaults';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';
import { useAppConfig } from 'src/shared/hooks/useAppConfig';

interface Props {
  children: React.ReactNode;
}
export const TscApolloProvider = (props: Props): JSX.Element => {
  const { children } = props;
  const { graphqlUri } = useAppConfig();
  const { getToken } = useTscAuth();

  const client = useMemo(() => {
    const authMethods = {
      getAuthToken: () => getToken(false),
      renewAuthToken: () => getToken(true),
    };

    return graphQLClient({
      uri: graphqlUri,
      ...authMethods,
      ...defaults,
      site: 'employer-seek-au',
      appName: 'connected-talent-search',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
