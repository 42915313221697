import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthN } from 'src/shared/hooks/useTscAuth';

function Callback() {
  const { replace } = useHistory();
  const authN = useAuthN();

  const handleRedirect = useCallback(
    async (redirectHandler: (origin: string, url: string) => Promise<void>) => {
      await authN.handleRedirect(redirectHandler);
    },
    [authN],
  );

  useEffect(() => {
    (async () => {
      await handleRedirect(async (_, url: string) => replace(url));
    })();
  }, [handleRedirect, replace]);

  return null;
}

export default Callback;
