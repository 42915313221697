import * as Types from '../../../../types/generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTalentSearchUserContextQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetTalentSearchUserContextQuery = {
  talentSearchUserContext: {
    user: { userId: number };
    ats?: { id: number; name: string } | null;
  };
};

export const GetTalentSearchUserContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTalentSearchUserContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentSearchUserContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetTalentSearchUserContextQuery__
 *
 * To run a query within a React component, call `useGetTalentSearchUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentSearchUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentSearchUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentSearchUserContextQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTalentSearchUserContextQuery,
    GetTalentSearchUserContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTalentSearchUserContextQuery,
    GetTalentSearchUserContextQueryVariables
  >(GetTalentSearchUserContextDocument, options);
}
export function useGetTalentSearchUserContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTalentSearchUserContextQuery,
    GetTalentSearchUserContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTalentSearchUserContextQuery,
    GetTalentSearchUserContextQueryVariables
  >(GetTalentSearchUserContextDocument, options);
}
export type GetTalentSearchUserContextQueryHookResult = ReturnType<
  typeof useGetTalentSearchUserContextQuery
>;
export type GetTalentSearchUserContextLazyQueryHookResult = ReturnType<
  typeof useGetTalentSearchUserContextLazyQuery
>;
export type GetTalentSearchUserContextQueryResult = Apollo.QueryResult<
  GetTalentSearchUserContextQuery,
  GetTalentSearchUserContextQueryVariables
>;
