/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** Language Code in ISO 2-letter format */
  LanguageCodeIso: { input: any; output: any };
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: { input: any; output: any };
}

export interface AccessCv {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export type AccessCvState =
  | AccessCv
  | ConnectionPending
  | CvUnavailable
  | DownloadCv
  | InsufficientCredits;

export interface AccessEmailReceiverInput {
  /** User ID only used for logging/audit purposes */
  auditUserId: Scalars['String']['input'];
  /** Email of the user */
  email: Scalars['String']['input'];
  /** First name of the user */
  firstName: Scalars['String']['input'];
}

export interface AccessProfile {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface AccessProfileAndCv {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export type AccessProfileState =
  | AccessProfile
  | AccessProfileAndCv
  | ConnectionPending
  | DownloadProfile
  | InsufficientCredits
  | ProfileAndCvUnavailable;

export enum AccessRightFilter {
  Active = 'ACTIVE',
  AdminRole = 'ADMIN_ROLE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING',
  UserRole = 'USER_ROLE',
}

export enum AccessRightStatus {
  /** User association with advertiser is active. */
  Active = 'ACTIVE',
  /** User association with advertiser has been deactivated (equivalent to deleted). */
  Deactivated = 'DEACTIVATED',
  /** Awaiting email verification. */
  Pending = 'PENDING',
}

export enum AccountActivationStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export interface AccountBillingAddressInput {
  /** Address line of where the Advertiser would like billings to go to */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Additional address line of where the advertiser would like their billing to go to. Can be left blank if it is not needed */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Address lines of where the Advertiser would like billings to go to */
  addressLines?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Country code of where the Advertiser would like billings to go to */
  countryCodeAlpha2: Scalars['String']['input'];
  /** Postcode in which the Advertiser would like billings to go to */
  postCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
  /**
   * State in which the Advertiser would like billings to go to.
   * Can be NULL if their billing address is a country outside of Australia
   */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Suburb in which the Advertiser would like billings to go to */
  suburb: Scalars['String']['input'];
}

export interface AccountBillingEmailAddressInput {
  /** Email address to send invoices, outstanding balance notifications etc. */
  billingEmail: Scalars['String']['input'];
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID used in Hirer Accounts API.
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
}

export interface AccountBusinessIdentifiersInput {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  salesServiceTaxNumber?: InputMaybe<Scalars['String']['input']>;
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface AccountDetailsUpdateResponse {
  /** Response message we receive from calling the account/details API */
  message?: Maybe<Scalars['String']['output']>;
}

export interface AccountHierarchyOption {
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  value: Scalars['String']['output'];
}

export interface AccountPostalAddressInput {
  /** Address line of where the Advertiser resides */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Additional address line of where the advertiser resides. Can be left blank if it is not needed */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Address lines of where the Advertiser resides */
  addressLines?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Country code of where the Advertiser resides */
  countryCodeAlpha2: Scalars['String']['input'];
  /** Postcode in which the Advertiser resides */
  postCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
  /**
   * State in which the Advertiser resides
   * Can be NULL if their postal address is a country outside of Australia
   */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Suburb in which the Advertiser resides */
  suburb: Scalars['String']['input'];
  /** Boolean to signify if the user would the postal address to be the same as the billing address */
  useForBillingAddress: Scalars['Boolean']['input'];
}

export interface AccountPrimaryContactInput {
  /** Email of the person we are assigning to be the primary contact */
  email: Scalars['String']['input'];
  /** First name of the person we are assigning to be the primary contact */
  firstName: Scalars['String']['input'];
  /** Last name of the person we are assigning to be the primary contact */
  lastName: Scalars['String']['input'];
  /** Phone number of the person we are assigning to be the primary contact */
  phone: Scalars['String']['input'];
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
}

export interface AccountSelectionEdgeV3 {
  node: HirerAccountOption;
}

export interface AccountSelectionQueryInput {
  /** Return uri for successful account switching. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
}

export interface AccountSelectionResultsV3 {
  defaultAccount?: Maybe<HirerAccountOption>;
  edges: Array<AccountSelectionEdgeV3>;
  totalCount: Scalars['Int']['output'];
}

export type AccountSelectionV3Response =
  | AccountSelectionResultsV3
  | ResponseError;

export interface ActionLink {
  viewProfile?: Maybe<Scalars['String']['output']>;
}

export interface Actor {
  /** Email address of the actor. */
  email: Scalars['String']['output'];
  /** The Auth0 user id of the actor, eg 'samlp|seek-okta-global|tkilvington@seek.com.au'. */
  id: Scalars['String']['output'];
}

export interface ActorContext {
  /** Url to toggle customer service mode. */
  csToggleHref: Scalars['String']['output'];
  /**
   * Text of CS toggle button.
   * @deprecated Text defined in client for translations purposes. Can use isCsView.
   */
  csToggleText?: Maybe<Scalars['String']['output']>;
  /** Whether the actor is in Customer Service View. */
  isCsView: Scalars['Boolean']['output'];
  /** Email address of the CS user. */
  operatorEmail: Scalars['String']['output'];
}

export interface Ad extends Product {
  adType: AdType;
  /** To show or hide the price */
  hidePrice: Scalars['Boolean']['output'];
  metadata: AdMetaData;
  name: Scalars['String']['output'];
  price?: Maybe<Price>;
  productType: ProductType;
}

export interface AdBudget extends Product {
  ads: AdBudgetType;
  id: Scalars['ID']['output'];
  metadata: AdBudgetMetadata;
  name: Scalars['String']['output'];
  productType: ProductType;
  value: Money;
}

export interface AdBudgetAttributes {
  /** Id of selected Ad Budget. */
  budgetId: Scalars['ID']['input'];
}

export interface AdBudgetCartItem extends CartItemProduct {
  id: Scalars['String']['output'];
}

export interface AdBudgetMetadata {
  /** The ad posting frequency of budgets */
  adPostingFrequency: Scalars['String']['output'];
  /** Length of the contract in months */
  discountDurationMonths: Scalars['Int']['output'];
  /** Title used when displaying this product as a line item of a purchase */
  displayItemTitle: Scalars['String']['output'];
  /** Set to true when a discount is offered on standOut ads */
  hasStandOutDiscount: Scalars['Boolean']['output'];
  /** Approximate amount to convey the savings on ads as a percentage */
  percentageSavings: Scalars['Float']['output'];
}

export interface AdBudgetProduct extends CatalogueProduct {
  /** Display label for Ad posting frequency. Eg Occasional, Regular, Frequent. */
  adPostingFrequency: Scalars['String']['output'];
  /** Display label for Ad posting range. */
  adRange: Range;
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  /** Features of the adbudget product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** The group that the budget is in */
  group?: Maybe<BudgetProductGroup>;
  /** Unique ID of the adbudget product */
  id: Scalars['ID']['output'];
  /** name of the adbudget product */
  name: Scalars['String']['output'];
  /** Price of the adbudget product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  /** List of short labels describing products that can be purchased from the budget */
  purchasableProducts: PurchasableProducts;
  /** Whether the product is "purchasable" or was previously "purchased" */
  purchaseState: CatalogueProductPurchaseState;
}

export interface AdBudgetProductList {
  /** A list of ad budget products */
  items: Array<AdBudgetProduct>;
}

export interface AdBudgetProductsForUpsellInput {
  /**
   * Id of the advertiser.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** List of product ids */
  productIds: Array<Scalars['ID']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export type AdBudgetProductsForUpsellResponse =
  | AdBudgetProductList
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export enum AdBudgetType {
  BrandedOnwards = 'BRANDED_ONWARDS',
  ClassicOnwards = 'CLASSIC_ONWARDS',
  PremiumOnly = 'PREMIUM_ONLY',
}

export interface AdBudgetsByAdvertiserInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * ISO Alpha-2 country code based on the advertiser's billing address.
   * This is useful to ensure we surface the budgets matching the hirer's location
   * and we may not have their billing address in the system yet. Eg: when new NZ hirers
   * who register and create a Job at the same time on AU website. The NZ budgets will be
   * surfaced on AU site as upsell only if this param is populated by client.
   */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export interface AdMetaData {
  description?: Maybe<Scalars['String']['output']>;
  extendsAdType?: Maybe<AdType>;
  features?: Maybe<Array<Maybe<ProductFeature>>>;
  highlightText?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<SubTitle>;
  theme?: Maybe<AdTypeTheme>;
  title: Scalars['String']['output'];
}

export interface AdPerformanceReportResponse {
  success: Scalars['Boolean']['output'];
}

export interface AdPerformanceReportWidgetInput {
  jobId: Scalars['String']['input'];
}

export interface AdProduct extends CatalogueProduct {
  /** Addons available for the product */
  addOns: Array<AdProductAddOn>;
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  /** Product information to display to customers */
  displayMetadata?: Maybe<AdProductDisplayMetadata>;
  /** Expectations for a draft */
  expectationForDraft?: Maybe<Expectation>;
  /** Expectations for a job */
  expectationForJob?: Maybe<Expectation>;
  features: Array<Maybe<CatalogueProductFeature>>;
  id: Scalars['ID']['output'];
  /** @deprecated Avoid use of this field and consider displayMetadata.name to display for customers, or use 'features' to make decisions instead */
  name: Scalars['String']['output'];
  price: CatalogueProductPrice;
  pricing?: Maybe<Pricing>;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  purchaseState: CatalogueProductPurchaseState;
  /** Upgrade paths available for the product */
  upgradePaths: Array<UpgradePath>;
}

export interface AdProduct_ExpectationForDraftArgs {
  draftId?: InputMaybe<Scalars['String']['input']>;
}

export interface AdProduct_ExpectationForJobArgs {
  jobId?: InputMaybe<Scalars['String']['input']>;
}

export interface AdProductAddOn extends CatalogueProduct {
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  /** Legacy display information for the product */
  displayMetadata?: Maybe<AdProductAddOnDisplayMetadata>;
  /** The Flexible Ad Architecture features of the AddOn product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** The unique identifier of the product */
  id: Scalars['ID']['output'];
  /** The name of the product as it appears in the catalogue */
  name: Scalars['String']['output'];
  /** The price of the product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  purchaseState: CatalogueProductPurchaseState;
}

export interface AdProductAddOnCartItem extends CartItemProduct {
  id: Scalars['String']['output'];
  job?: Maybe<JobReference>;
}

export interface AdProductAddOnDisplayMetadata {
  /** Product name that can be shown to customers (for example in an ad ladder) */
  name?: Maybe<Scalars['String']['output']>;
  /** Detailed name where more context or formality is required (e.g. Order summaries, invoicing, CRM) */
  qualifiedName?: Maybe<Scalars['String']['output']>;
  /** Tagline that can be shown to customers (for example in an ad ladder) */
  tagline?: Maybe<Scalars['String']['output']>;
}

export interface AdProductCartItem extends CartItemProduct {
  id: Scalars['String']['output'];
  job?: Maybe<JobReference>;
}

export interface AdProductDisplayMetadata {
  /** Product name that can be shown to customers (for example in an ad ladder) */
  name?: Maybe<Scalars['String']['output']>;
  /** Description of upgrade product price */
  priceDescription?: Maybe<Scalars['String']['output']>;
  /** Detailed name where more context or formality is required (e.g. Order summaries, invoicing, CRM) */
  qualifiedName?: Maybe<Scalars['String']['output']>;
  /**
   * Url to redirect to the product booking form
   * @deprecated Use display.cta.url instead
   */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** Selling points or features of a product */
  sellingPoints?: Maybe<Array<SellingPoint>>;
  /** Tagline that can be shown to customers (for example in an ad ladder) */
  tagline?: Maybe<Scalars['String']['output']>;
}

export type AdProductSetResponse =
  | DisplayMetadataError
  | ProductList
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export interface AdProductUpgrade extends CatalogueProduct {
  /** Addons available for the product */
  addOns: Array<AdProductAddOn>;
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  displayMetadata?: Maybe<AdProductDisplayMetadata>;
  /** Expectations for a draft */
  expectationForDraft?: Maybe<Expectation>;
  /** Expectations for a job */
  expectationForJob?: Maybe<Expectation>;
  features: Array<Maybe<CatalogueProductFeature>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: CatalogueProductPrice;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  purchaseState: CatalogueProductPurchaseState;
  /** Upgrade paths available for the product */
  upgradePaths: Array<UpgradePath>;
}

export interface AdProductUpgrade_ExpectationForDraftArgs {
  draftId?: InputMaybe<Scalars['String']['input']>;
}

export interface AdProductUpgrade_ExpectationForJobArgs {
  jobId?: InputMaybe<Scalars['String']['input']>;
}

export interface AdProductUpgradeCartItem extends CartItemProduct {
  id: Scalars['String']['output'];
  job?: Maybe<JobReference>;
}

export interface AdProductsForAdEditingInput {
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export interface AdProductsForAdPostingInput {
  /**
   * Id of the advertiser.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export interface AdRatingLabels {
  groupLabel?: Maybe<Scalars['String']['output']>;
  locationLabel?: Maybe<Scalars['String']['output']>;
}

export interface AdRatingResponse {
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  adRatingScore?: Maybe<AnalyticsAdRatingScore>;
  applications: Scalars['Int']['output'];
  applicationsBenchmarkHigh?: Maybe<Scalars['Int']['output']>;
  applicationsBenchmarkLow?: Maybe<Scalars['Int']['output']>;
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  incompleteApplications: Scalars['Int']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  lastUpdatedTimestampUTC: Scalars['DateTime']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
}

export enum AdRatingType {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
  NotIncluded = 'NOT_INCLUDED',
  TooSoonToRate = 'TOO_SOON_TO_RATE',
  UnableToRate = 'UNABLE_TO_RATE',
  Unavailable = 'UNAVAILABLE',
}

export interface AdRatingTypeOption {
  count: Scalars['Int']['output'];
  key: FiltersAdRatingType;
}

export interface AdRatingWidgetInput {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export enum AdRepost {
  NotRepost = 'NOT_REPOST',
  Repost = 'REPOST',
}

/** AdBudgetProduct feature to indicate ad spend allowance */
export interface AdSpendFeature extends CatalogueProductFeature {
  /** Money allowance to purchase ad products */
  allowance: Money;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface AdStatusOption {
  count: Scalars['Int']['output'];
  key: AdUsageAdStatus;
}

export interface AdTipsOption {
  count: Scalars['Int']['output'];
  key: AdTipsType;
}

export enum AdTipsType {
  AddQuestions = 'ADD_QUESTIONS',
  AddSpecificLocation = 'ADD_SPECIFIC_LOCATION',
  IncreaseSalary = 'INCREASE_SALARY',
  MaximiseBranding = 'MAXIMISE_BRANDING',
  NoTips = 'NO_TIPS',
  RevealSalary = 'REVEAL_SALARY',
  ReviewAdDetails = 'REVIEW_AD_DETAILS',
  ReviewAdSummary = 'REVIEW_AD_SUMMARY',
}

export enum AdType {
  Branded = 'BRANDED',
  BrandedUpgrade = 'BRANDED_UPGRADE',
  Classic = 'CLASSIC',
  Concierge = 'CONCIERGE',
  Guaranteed = 'GUARANTEED',
  Premium = 'PREMIUM',
}

/** Discount for Ad Product by legacy AdType */
export interface AdTypeDiscount {
  /** Type of the ad. Eg CLASSIC, BRANDED, PREMIUM */
  adType: AdType;
  /** Discount in absolute money amount or percentage */
  discount: Discount;
}

/** AdBudgetProduct feature to determine discounts for Ad products. */
export interface AdTypeDiscountsFeature extends CatalogueProductFeature {
  /** Discounts applicable to the different Ad Products */
  discounts: Array<AdTypeDiscount>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export enum AdTypeTheme {
  Basic = 'BASIC',
  Primary = 'PRIMARY',
}

export type AdUpgradeProductSetResponse =
  | DisplayMetadataError
  | ProductSetExpiredError
  | ProductSetNotFoundError
  | ProductUpgradeList;

export interface AdUpgradeProductsForUpgradePurchaseInput {
  /** Job Id required to get current product(s) (optional) */
  jobId?: InputMaybe<Scalars['String']['input']>;
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export enum AdUsageAdStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
}

export interface AdUsageAdTypeTrendInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
}

export interface AdUsageAdTypeTrendResponse {
  endDate: Scalars['String']['output'];
  productDisplayNameDaily: Array<DailyTrendsProductDisplayName>;
  productDisplayNameMonthly: Array<MonthlyTrendsProductDisplayName>;
  startDate: Scalars['String']['output'];
  totalProductDisplayNames?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
}

export interface AdUsageConversionResponse {
  adClicks: ConversionRateData;
  adsWithoutRatingPercentage?: Maybe<Scalars['Float']['output']>;
  applicationsCompleted: ConversionRateData;
  applicationsStarted: ConversionRateData;
  jobSearchViews: ConversionRateData;
  linkOutAds: Scalars['Float']['output'];
}

export interface AdUsageConversionWidgetInput {
  filters: AdUsageQueryFilters;
}

export interface AdUsageFiltersResponse {
  accountHierarchies: Array<Maybe<AccountHierarchyOption>>;
  adIds: Array<Scalars['String']['output']>;
  adRatings: Array<Maybe<AdRatingTypeOption>>;
  adStatus: Array<Maybe<AdStatusOption>>;
  addOns: Array<AddOnsOption>;
  budgets: Array<Maybe<BudgetOption>>;
  classifications: Array<Maybe<ClassificationOption>>;
  isChildAccount: Scalars['Boolean']['output'];
  jobTitles: Array<Maybe<JobTitleOption>>;
  locations: Array<Maybe<LocationOption>>;
  productDisplayNames: Array<Maybe<ProductDisplayNameOption>>;
  reposts: Array<Maybe<RepostOption>>;
  tips: Array<Maybe<AdTipsOption>>;
  users: Array<Maybe<UserOption>>;
}

export interface AdUsageFiltersWidgetInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
}

export interface AdUsageQueryFilters {
  accountIds: Array<InputMaybe<Scalars['Int']['input']>>;
  adIds: Array<InputMaybe<Scalars['Int']['input']>>;
  adRatings: Array<InputMaybe<FiltersAdRatingType>>;
  adStatuses: Array<InputMaybe<AdUsageAdStatus>>;
  adTips: Array<InputMaybe<AdTipsType>>;
  adTypes: Array<InputMaybe<Scalars['String']['input']>>;
  addOns: Array<InputMaybe<Scalars['String']['input']>>;
  budgetIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobTitles: Array<InputMaybe<Scalars['String']['input']>>;
  locationIds: Array<InputMaybe<Scalars['Int']['input']>>;
  postingDates: AdUsageQueryFiltersDates;
  repostTypes: Array<InputMaybe<AdRepost>>;
  subClassificationIds: Array<InputMaybe<Scalars['Int']['input']>>;
  userHashes: Array<InputMaybe<Scalars['String']['input']>>;
}

export interface AdUsageQueryFiltersDates {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
}

export interface AdUsageSpendResponse {
  averageApplications?: Maybe<Scalars['Int']['output']>;
  averageCostByProductDisplayNames?: Maybe<
    Array<Maybe<ProductDisplayNameAverageCost>>
  >;
  averageCostPerAd?: Maybe<Money>;
  averageCostPerApplication?: Maybe<Money>;
  hidePrice: Scalars['Boolean']['output'];
  otherCurrencyAdsCount?: Maybe<Scalars['Int']['output']>;
  repostedAdsCount?: Maybe<Scalars['Int']['output']>;
  repostedAdsSpend?: Maybe<Money>;
  totalAds: Scalars['Int']['output'];
  totalApplications?: Maybe<Scalars['Int']['output']>;
  totalPostByProductDisplayNames?: Maybe<
    Array<Maybe<ProductDisplayNamePostCount>>
  >;
  totalSpend?: Maybe<Money>;
}

export interface AdUsageSpendWidgetInput {
  filters: AdUsageQueryFilters;
  isAdBudgetFilterEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  siteCurrency?: InputMaybe<Scalars['String']['input']>;
}

export interface AdUsageTrendClassification {
  classificationName: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  totalAds: Scalars['Int']['output'];
}

export interface AdUsageTrendClassificationsAndUsersInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
}

export interface AdUsageTrendClassificationsAndUsersResponse {
  classifications: Array<AdUsageTrendClassification>;
  users: Array<AdUsageTrendUser>;
}

export interface AdUsageTrendUser {
  percentage: Scalars['Float']['output'];
  totalAds: Scalars['Int']['output'];
  userFullname?: Maybe<Scalars['String']['output']>;
}

export interface AddItemToCartErrorPayload {
  message: Scalars['String']['output'];
}

export interface AddItemToCartInput {
  cartId: Scalars['ID']['input'];
  item: CartItemInput;
}

export type AddItemToCartResultPayload =
  | AddItemToCartErrorPayload
  | AddItemToCartSuccessPayload;

export interface AddItemToCartSuccessPayload {
  cart: Cart;
}

export interface AddOnsOption {
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
}

export interface AddQuestions extends TipToImproveAds {
  adCount: Scalars['Int']['output'];
  questions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: TipsToImproveAdsType;
}

export interface AddSpecificLocation extends TipToImproveAds {
  adCount: Scalars['Int']['output'];
  nonSpecificLocation: Scalars['String']['output'];
  specificLocation: Scalars['String']['output'];
  type: TipsToImproveAdsType;
}

export interface AddUserToAccountInput {
  /** Email of the person we are wanting to add to the account */
  email: Scalars['String']['input'];
  /** First name of the person we are wanting to add to the account */
  firstName: Scalars['String']['input'];
  /** Last name of the person we are wanting to add to the account */
  lastName: Scalars['String']['input'];
  /** The audience zone of the site that this user originally registered on */
  originZone: Scalars['String']['input'];
}

export interface AddUserToAccountResponse {
  /** The create date of the newly created user */
  createdDateUtc: Scalars['String']['output'];
  /** Email of the person we are wanting to add to the account */
  email: Scalars['String']['output'];
  /** First name of the person we are wanting to add to the account */
  firstName: Scalars['String']['output'];
  lastDailyCandidateMailDateUtc?: Maybe<Scalars['String']['output']>;
  /** Last name of the person we are wanting to add to the account */
  lastName: Scalars['String']['output'];
  lastWeeklyCandidateMailDateUtc?: Maybe<Scalars['String']['output']>;
  links: Array<Link>;
  /** Current status ID of the newly created user */
  statusId: Scalars['String']['output'];
  /** User ID of the newly created user */
  userId: Scalars['String']['output'];
}

export interface AdvertiserHeaderContext {
  /** Billing ID */
  billingId: Scalars['String']['output'];
  /**
   * Can Manage Company Profile
   * @deprecated Use permissionOptions.canManageCompanyProfile instead.
   */
  canManageCompanyProfile?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the user has multiple accounts. Should really be part of the user payload :thinking-face: */
  hasMultipleAccounts: Scalars['Boolean']['output'];
  /** Indicates whether the user's activation is pending for this account. */
  isActivationPending: Scalars['Boolean']['output'];
  /** Indicates whether the advertiser is an agency account. */
  isAgency: Scalars['Boolean']['output'];
  /** Name of the advertiser. */
  name: Scalars['String']['output'];
  /** Key/value pairs of permissions for a user on behalf of the advertiser. */
  permissionOptions: PermissionOptions;
  /**
   * Array of permissions for a user on behalf of the advertiser.
   * @deprecated Use permissionOptions instead.
   */
  permissions?: Maybe<Array<Permission>>;
  /** Indicates whether the account is on credit hold. */
  showOnCreditHoldMessage: Scalars['Boolean']['output'];
  /** Url to switch accounts. Will be null if user has a single account. */
  switchAccountsHref?: Maybe<Scalars['String']['output']>;
}

export enum AdvertiserStatus {
  /** An active advertiser. */
  Active = 'ACTIVE',
  /** A deactivated advertiser. */
  Deactivated = 'DEACTIVATED',
  /** A freshly migrated/created advertiser. */
  New = 'NEW',
  /** Awaiting primary contact registration. */
  Pending = 'PENDING',
  /** Awaiting advertiser account security link activation. */
  PendingActivation = 'PENDING_ACTIVATION',
}

export interface AllJobsSent {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface AnalyticsAdRating {
  score?: Maybe<AnalyticsAdRatingScore>;
  status: AnalyticsAdRatingStatus;
}

export enum AnalyticsAdRatingConfidence {
  High = 'HIGH',
  Low = 'LOW',
}

export enum AnalyticsAdRatingScore {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
}

export enum AnalyticsAdRatingStatus {
  Archived = 'ARCHIVED',
  AvailableCannotRate = 'AVAILABLE_CANNOT_RATE',
  AvailablePendingRating = 'AVAILABLE_PENDING_RATING',
  AvailableWithRating = 'AVAILABLE_WITH_RATING',
  Errored = 'ERRORED',
  NotApplicable = 'NOT_APPLICABLE',
  NotIncluded = 'NOT_INCLUDED',
  Pending = 'PENDING',
  Unavailable = 'UNAVAILABLE',
  Withheld = 'WITHHELD',
}

export enum AnalyticsBenchmarkScenario {
  Country = 'COUNTRY',
  Exact = 'EXACT',
  ExactCountry = 'EXACT_COUNTRY',
  ExactRegional = 'EXACT_REGIONAL',
  Ideal = 'IDEAL',
  IdealCountry = 'IDEAL_COUNTRY',
  IdealRegional = 'IDEAL_REGIONAL',
  SubclassCountry = 'SUBCLASS_COUNTRY',
  SubclassLocation = 'SUBCLASS_LOCATION',
  SubclassRegional = 'SUBCLASS_REGIONAL',
}

export type AnalyticsInsightsRoleAdInsightsTrendsDataPoint =
  | AnalyticsInsightsRoleAdInsightsTrendsInsufficientCompaniesItem
  | AnalyticsInsightsRoleAdInsightsTrendsSufficientDataItem;

export interface AnalyticsInsightsRoleAdInsightsTrendsInsufficientCompaniesItem {
  adsPosted: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
}

export interface AnalyticsInsightsRoleAdInsightsTrendsResponse {
  code: AnalyticsInsightsRoleUniqueApplicantsCode;
  data: Array<AnalyticsInsightsRoleAdInsightsTrendsDataPoint>;
}

export interface AnalyticsInsightsRoleAdInsightsTrendsSufficientDataItem {
  adsPosted: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  uniqueApplicants: Scalars['Int']['output'];
}

export enum AnalyticsInsightsRoleAdPerformanceCode {
  InsufficientCompanies = 'INSUFFICIENT_COMPANIES',
  InsufficientExpiredAds = 'INSUFFICIENT_EXPIRED_ADS',
  SufficientData = 'SUFFICIENT_DATA',
}

export interface AnalyticsInsightsRoleAdPerformanceCodeResponse {
  code: AnalyticsInsightsRoleAdPerformanceCode;
}

export interface AnalyticsInsightsRoleAdPerformanceData {
  medianApplicants: Scalars['Int']['output'];
  recommendation?: Maybe<AnalyticsInsightsRoleAdPerformanceRecommendation>;
}

export interface AnalyticsInsightsRoleAdPerformanceDataResponse {
  code: AnalyticsInsightsRoleAdPerformanceCode;
  data: AnalyticsInsightsRoleAdPerformanceData;
}

export type AnalyticsInsightsRoleAdPerformanceRecommendation =
  | AnalyticsInsightsRoleMostCompaniesPostedPremiumRecommendation
  | AnalyticsInsightsRolePremiumAttractedXPerAdRecommendation
  | AnalyticsInsightsRolePremiumAttractedXTimesRecommendation
  | AnalyticsInsightsRolePremiumDeliverMoreAtStartRecommendation;

export enum AnalyticsInsightsRoleAdPerformanceRecommendationType {
  MostCompaniesPostedPremium = 'MOST_COMPANIES_POSTED_PREMIUM',
  PremiumAttractedXPerAd = 'PREMIUM_ATTRACTED_X_PER_AD',
  PremiumAttractedXTimes = 'PREMIUM_ATTRACTED_X_TIMES',
  PremiumDeliverMoreAtStart = 'PREMIUM_DELIVER_MORE_AT_START',
}

export type AnalyticsInsightsRoleAdPerformanceResponse =
  | AnalyticsInsightsRoleAdPerformanceCodeResponse
  | AnalyticsInsightsRoleAdPerformanceDataResponse;

export interface AnalyticsInsightsRoleAdsPostedResponse {
  adsPosted: Scalars['Int']['output'];
}

export interface AnalyticsInsightsRoleCandidateProfiles {
  premiumTalentSearchProfilesStats?: Maybe<AnalyticsInsightsRolePremiumTalentSearchProfileStats>;
}

export interface AnalyticsInsightsRoleCandidateProfilesInput {
  countryCode: Scalars['String']['input'];
  locationIds: Array<Scalars['String']['input']>;
  normalisedRoleTitles: Array<Scalars['String']['input']>;
}

export interface AnalyticsInsightsRoleCompaniesPostingResponse {
  companiesPosting: Scalars['Int']['output'];
  topCompanies: Array<AnalyticsInsightsRoleTopCompany>;
}

export enum AnalyticsInsightsRoleCompetitionForTalentCode {
  InsufficientAdsPosted = 'INSUFFICIENT_ADS_POSTED',
  InsufficientCompanies = 'INSUFFICIENT_COMPANIES',
  SufficientData = 'SUFFICIENT_DATA',
}

export interface AnalyticsInsightsRoleCompetitionForTalentCodeResponse {
  code: AnalyticsInsightsRoleCompetitionForTalentCode;
}

export enum AnalyticsInsightsRoleCompetitionForTalentConfidence {
  High = 'HIGH',
  Low = 'LOW',
}

export interface AnalyticsInsightsRoleCompetitionForTalentData {
  confidence: AnalyticsInsightsRoleCompetitionForTalentConfidence;
  score: Scalars['Float']['output'];
  threshold: AnalyticsInsightsRoleCompetitionForTalentThreshold;
}

export interface AnalyticsInsightsRoleCompetitionForTalentDataResponse {
  code: AnalyticsInsightsRoleCompetitionForTalentCode;
  data: AnalyticsInsightsRoleCompetitionForTalentData;
}

export type AnalyticsInsightsRoleCompetitionForTalentResponse =
  | AnalyticsInsightsRoleCompetitionForTalentCodeResponse
  | AnalyticsInsightsRoleCompetitionForTalentDataResponse;

export interface AnalyticsInsightsRoleCompetitionForTalentThreshold {
  high: Scalars['Int']['output'];
  low: Scalars['Int']['output'];
}

export interface AnalyticsInsightsRoleLastUpdatedResponse {
  lastUpdatedTimestampUTC: Scalars['DateTime']['output'];
}

export interface AnalyticsInsightsRoleLocation {
  children: Array<AnalyticsInsightsRoleLocation>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  leafNodeKeys: Array<Scalars['String']['output']>;
  level: Scalars['String']['output'];
}

export interface AnalyticsInsightsRoleLocationFilterInput {
  id: Scalars['String']['input'];
  level: Scalars['String']['input'];
}

export interface AnalyticsInsightsRoleLocationsInput {
  countryCode: Scalars['String']['input'];
}

export interface AnalyticsInsightsRoleMostCompaniesPostedPremiumRecommendation {
  type: AnalyticsInsightsRoleAdPerformanceRecommendationType;
}

export interface AnalyticsInsightsRoleNormalisedRole {
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface AnalyticsInsightsRoleNormalisedRolesInput {
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText: Scalars['String']['input'];
}

export interface AnalyticsInsightsRoleNormalisedRolesResponse {
  normalisedRoles: Array<AnalyticsInsightsRoleNormalisedRole>;
}

export interface AnalyticsInsightsRolePremiumAttractedXPerAdRecommendation {
  medianApplicantsOnPremiumAds: Scalars['Int']['output'];
  type: AnalyticsInsightsRoleAdPerformanceRecommendationType;
}

export interface AnalyticsInsightsRolePremiumAttractedXTimesRecommendation {
  medianApplicantsOnNonPremiumAds: Scalars['Int']['output'];
  medianApplicantsOnPremiumAds: Scalars['Int']['output'];
  type: AnalyticsInsightsRoleAdPerformanceRecommendationType;
}

export interface AnalyticsInsightsRolePremiumDeliverMoreAtStartRecommendation {
  type: AnalyticsInsightsRoleAdPerformanceRecommendationType;
}

export interface AnalyticsInsightsRolePremiumTalentSearchProfileStats {
  approachableProfiles: Scalars['Int']['output'];
  totalProfiles: Scalars['Int']['output'];
}

export interface AnalyticsInsightsRoleRelatedRolesInput {
  limit?: InputMaybe<Scalars['Int']['input']>;
  normalisedRoleId: Scalars['String']['input'];
}

export interface AnalyticsInsightsRoleRelatedRolesResponse {
  relatedRoles: Array<AnalyticsInsightsRoleNormalisedRole>;
}

export interface AnalyticsInsightsRoleReportInput {
  countryCode: Scalars['String']['input'];
  lastUpdatedTimestampUTC: Scalars['String']['input'];
  locations: Array<AnalyticsInsightsRoleLocationFilterInput>;
  normalisedRoleIds: Array<Scalars['String']['input']>;
}

export interface AnalyticsInsightsRoleSalaryDistributionItem {
  count: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  salaryRange: AnalyticsInsightsRoleSalaryRange;
}

export interface AnalyticsInsightsRoleSalaryDistributionResponse {
  currency: Scalars['String']['output'];
  salaryDistribution: Array<AnalyticsInsightsRoleSalaryDistributionItem>;
}

export enum AnalyticsInsightsRoleSalaryInsightsCode {
  SufficientData = 'SUFFICIENT_DATA',
  SuppressInsights = 'SUPPRESS_INSIGHTS',
}

export interface AnalyticsInsightsRoleSalaryInsightsCodeResponse {
  code: AnalyticsInsightsRoleSalaryInsightsCode;
}

export interface AnalyticsInsightsRoleSalaryInsightsInput {
  salaryType: AnalyticsInsightsRoleSalaryType;
}

export interface AnalyticsInsightsRoleSalaryInsightsMajoritySalaryType {
  comparisonInPercentage: Scalars['Float']['output'];
  salaryType: AnalyticsInsightsRoleSalaryType;
  type: AnalyticsInsightsRoleSalaryInsightsType;
}

export type AnalyticsInsightsRoleSalaryInsightsResponse =
  | AnalyticsInsightsRoleSalaryInsightsCodeResponse
  | AnalyticsInsightsRoleSalaryInsightsSufficientDataResponse;

export interface AnalyticsInsightsRoleSalaryInsightsSufficientData {
  insights: Array<Maybe<AnalyticsInsightsRoleSalaryInsightsTypes>>;
}

export interface AnalyticsInsightsRoleSalaryInsightsSufficientDataResponse {
  code: AnalyticsInsightsRoleSalaryInsightsCode;
  data: AnalyticsInsightsRoleSalaryInsightsSufficientData;
}

export interface AnalyticsInsightsRoleSalaryInsightsTrendsByDays {
  comparisonInPercentage: Scalars['Float']['output'];
  type: AnalyticsInsightsRoleSalaryInsightsType;
}

export interface AnalyticsInsightsRoleSalaryInsightsTrendsByLocation {
  comparisonInPercentage: Scalars['Float']['output'];
  type: AnalyticsInsightsRoleSalaryInsightsType;
}

export enum AnalyticsInsightsRoleSalaryInsightsType {
  MajoritySalaryType = 'MAJORITY_SALARY_TYPE',
  TrendsByDays = 'TRENDS_BY_DAYS',
  TrendsByLocation = 'TRENDS_BY_LOCATION',
}

export type AnalyticsInsightsRoleSalaryInsightsTypes =
  | AnalyticsInsightsRoleSalaryInsightsMajoritySalaryType
  | AnalyticsInsightsRoleSalaryInsightsTrendsByDays
  | AnalyticsInsightsRoleSalaryInsightsTrendsByLocation;

export interface AnalyticsInsightsRoleSalaryPrivacyCheckResponse {
  code: AnalyticsInsightsSalaryPrivacyCheckCode;
}

export interface AnalyticsInsightsRoleSalaryRange {
  from: Scalars['Float']['output'];
  to: Scalars['Float']['output'];
}

export enum AnalyticsInsightsRoleSalaryRangeCode {
  SufficientData = 'SUFFICIENT_DATA',
  SuppressBounds = 'SUPPRESS_BOUNDS',
  SuppressRange = 'SUPPRESS_RANGE',
}

export interface AnalyticsInsightsRoleSalaryRangeCodeResponse {
  code: AnalyticsInsightsRoleSalaryRangeCode;
}

export type AnalyticsInsightsRoleSalaryRangeResponse =
  | AnalyticsInsightsRoleSalaryRangeCodeResponse
  | AnalyticsInsightsRoleSalaryRangeSufficientDataResponse
  | AnalyticsInsightsRoleSalaryRangeSuppressBoundsResponse;

export interface AnalyticsInsightsRoleSalaryRangeSufficientData {
  isLowConfidence: Scalars['Boolean']['output'];
  numOfAds: Scalars['Int']['output'];
  salaryMedian: Scalars['Float']['output'];
  salaryRange: AnalyticsInsightsRoleSalaryRange;
}

export interface AnalyticsInsightsRoleSalaryRangeSufficientDataResponse {
  code: AnalyticsInsightsRoleSalaryRangeCode;
  data: AnalyticsInsightsRoleSalaryRangeSufficientData;
}

export interface AnalyticsInsightsRoleSalaryRangeSuppressBoundsData {
  numOfAds: Scalars['Int']['output'];
  salaryMedian: Scalars['Float']['output'];
}

export interface AnalyticsInsightsRoleSalaryRangeSuppressBoundsResponse {
  code: AnalyticsInsightsRoleSalaryRangeCode;
  data: AnalyticsInsightsRoleSalaryRangeSuppressBoundsData;
}

export enum AnalyticsInsightsRoleSalaryType {
  Annual = 'ANNUAL',
  Combined = 'COMBINED',
  Hourly = 'HOURLY',
}

export interface AnalyticsInsightsRoleTopCompany {
  adsPosted: Scalars['Int']['output'];
  parentHirerName: Scalars['String']['output'];
}

export enum AnalyticsInsightsRoleUniqueApplicantsCode {
  InsufficientCompanies = 'INSUFFICIENT_COMPANIES',
  SufficientData = 'SUFFICIENT_DATA',
}

export interface AnalyticsInsightsRoleUniqueApplicantsCodeResponse {
  code: AnalyticsInsightsRoleUniqueApplicantsCode;
}

export interface AnalyticsInsightsRoleUniqueApplicantsDataResponse {
  code: AnalyticsInsightsRoleUniqueApplicantsCode;
  uniqueApplicants: Scalars['Int']['output'];
}

export type AnalyticsInsightsRoleUniqueApplicantsResponse =
  | AnalyticsInsightsRoleUniqueApplicantsCodeResponse
  | AnalyticsInsightsRoleUniqueApplicantsDataResponse;

export enum AnalyticsInsightsSalaryPrivacyCheckCode {
  SufficientData = 'SUFFICIENT_DATA',
  SuppressAll = 'SUPPRESS_ALL',
}

export interface AnalyticsLatestJobs {
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobId: Scalars['String']['output'];
  jobLocationLabel?: Maybe<Scalars['String']['output']>;
  jobTitle: Scalars['String']['output'];
  userFullName?: Maybe<Scalars['String']['output']>;
}

export enum AnalyticsPostTypeCategory {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT',
}

export enum AnalyticsSalaryType {
  AnnualCommission = 'ANNUAL_COMMISSION',
  AnnualPackage = 'ANNUAL_PACKAGE',
  CommissionOnly = 'COMMISSION_ONLY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
}

export interface AnalyticsTalentSearchUsageAccount {
  count: Scalars['Int']['output'];
  data: AnalyticsTalentSearchUsageAccountData;
  key: Scalars['ID']['output'];
  value: Scalars['String']['output'];
}

export interface AnalyticsTalentSearchUsageAccountData {
  displayId: Scalars['ID']['output'];
}

export interface AnalyticsTalentSearchUsageAccountTrendsItem {
  advertiserName: Scalars['String']['output'];
  percentage: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
}

export interface AnalyticsTalentSearchUsageConversionInput {
  filters: AnalyticsTalentSearchUsageFilters;
}

export interface AnalyticsTalentSearchUsageConversionResponse {
  cvPreviewCount: Scalars['Int']['output'];
  openJobCount: Scalars['Int']['output'];
  openJobPercentage: Scalars['Int']['output'];
  openMessageCount: Scalars['Int']['output'];
  openMessagePercentage: Scalars['Int']['output'];
  profileAccessCount: Scalars['Int']['output'];
  profileSearchCount: Scalars['Int']['output'];
  profileViewCount: Scalars['Int']['output'];
  sendJobCount: Scalars['Int']['output'];
  sendMessageCount: Scalars['Int']['output'];
}

export interface AnalyticsTalentSearchUsageDateRangeInput {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
}

export interface AnalyticsTalentSearchUsageFilters {
  accountIds: Array<InputMaybe<Scalars['ID']['input']>>;
  usageDates: AnalyticsTalentSearchUsageDateRangeInput;
  userHashes: Array<InputMaybe<Scalars['ID']['input']>>;
}

export interface AnalyticsTalentSearchUsageFiltersInput {
  filters: AnalyticsTalentSearchUsageFilters;
  locale: Scalars['String']['input'];
}

export interface AnalyticsTalentSearchUsageFiltersResponse {
  accounts: Array<Maybe<AnalyticsTalentSearchUsageAccount>>;
  users: Array<Maybe<AnalyticsTalentSearchUsageUser>>;
}

export interface AnalyticsTalentSearchUsageLastUpdatedResponse {
  lastUpdated: Scalars['DateTime']['output'];
}

export interface AnalyticsTalentSearchUsageOverviewInput {
  filters: AnalyticsTalentSearchUsageFilters;
  siteCurrency: Scalars['String']['input'];
}

export interface AnalyticsTalentSearchUsageOverviewResponse {
  otherCurrencyCount: Scalars['Int']['output'];
  profileAccessCount: Scalars['Int']['output'];
  profileAccessCredits: Scalars['Int']['output'];
  profileAccessPercentage: Scalars['Int']['output'];
  profileAccessSpendPer: Money;
  sendJobCount: Scalars['Int']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendJobPercentage: Scalars['Int']['output'];
  sendJobSpendPer: Money;
  sendMessageCount: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
  sendMessagePercentage: Scalars['Int']['output'];
  sendMessageSpendPer: Money;
  totalCount: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
  totalSpend: Money;
  userCount: Scalars['Int']['output'];
  userSpendAverage: Money;
}

export interface AnalyticsTalentSearchUsageTimeTrendsItem {
  profileAccessCredits: Scalars['Int']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
}

export interface AnalyticsTalentSearchUsageTimeTrendsItemByInterval {
  label: Scalars['String']['output'];
  profileAccessCredits: Scalars['Int']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
}

export interface AnalyticsTalentSearchUsageTrendsByAccountInput {
  filters: AnalyticsTalentSearchUsageFilters;
}

export interface AnalyticsTalentSearchUsageTrendsByAccountResponse {
  accounts: Array<AnalyticsTalentSearchUsageAccountTrendsItem>;
}

export interface AnalyticsTalentSearchUsageTrendsByTimeInput {
  filters: AnalyticsTalentSearchUsageFilters;
  interval: AnalyticsTalentSearchUsageTrendsByTimeInterval;
  timezone: Scalars['String']['input'];
}

export enum AnalyticsTalentSearchUsageTrendsByTimeInterval {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
}

export interface AnalyticsTalentSearchUsageTrendsByTimeResponse {
  data: Array<AnalyticsTalentSearchUsageTimeTrendsItemByInterval>;
  total: AnalyticsTalentSearchUsageTimeTrendsItem;
}

export interface AnalyticsTalentSearchUsageUser {
  count: Scalars['Int']['output'];
  key: Scalars['ID']['output'];
  value: Scalars['String']['output'];
}

export interface AnalyticsTalentSearchUsageUserUsage {
  openJobPercentage: Scalars['Int']['output'];
  openMessagePercentage: Scalars['Int']['output'];
  profileAccessCount: Scalars['Int']['output'];
  profileSearchCount: Scalars['Int']['output'];
  profileViewCount: Scalars['Int']['output'];
  sendJobCount: Scalars['Int']['output'];
  sendMessageCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
  userFullName: Scalars['String']['output'];
  userHash: Scalars['ID']['output'];
}

export interface AnalyticsTalentSearchUsageUsersListInput {
  filters: AnalyticsTalentSearchUsageFilters;
  pageNumber: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
}

export interface AnalyticsTalentSearchUsageUsersListResponse {
  list: Array<AnalyticsTalentSearchUsageUserUsage>;
  userCount: Scalars['Int']['output'];
}

export enum AnalyticsWorkType {
  Casual = 'CASUAL',
  Contract = 'CONTRACT',
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
}

export interface Application {
  adcentreCandidateId: Scalars['ID']['output'];
  adcentreProspectId: Scalars['ID']['output'];
  applicationId?: Maybe<Scalars['ID']['output']>;
  appliedDateUtc: Scalars['DateTime']['output'];
  attachmentsStats: ApplicationAttachmentsStatsResponse;
  attachmentsV2: ApplicationAttachmentsV2Response;
  billableStatus?: Maybe<BillableStatus>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateStats: ApplicationCandidateStatsResponse;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPriorityApply: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  job: ApplicationJobResponse;
  lastName: Scalars['String']['output'];
  matchedQualities: Array<MatchedQuality>;
  metadata: ApplicationMetadataResponse;
  mostRecentCompanyName?: Maybe<Scalars['String']['output']>;
  mostRecentJobTitle?: Maybe<Scalars['String']['output']>;
  mostRecentRoleMonths: Scalars['Int']['output'];
  phone: Scalars['String']['output'];
  profile: ApplicationProfileResponse;
  questionnaireSubmission: ApplicationQuestionnaireSubmissionResponse;
  rating?: Maybe<ApplicationRating>;
  source: ApplicationSource;
  statusFolder: ApplicationStatusFolder;
  verifications: ApplicationVerificationsResponse;
}

export interface Application_JobArgs {
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export enum ApplicationAttachmentType {
  CoverLetter = 'COVER_LETTER',
  Other = 'OTHER',
  ReferenceCheck = 'REFERENCE_CHECK',
  Resume = 'RESUME',
  SelectionCriteria = 'SELECTION_CRITERIA',
}

export interface ApplicationAttachmentV2 {
  attachmentType: ApplicationAttachmentType;
  blobId: Scalars['ID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  hasConvertedPdf: Scalars['Boolean']['output'];
  uploadedDate?: Maybe<Scalars['DateTime']['output']>;
  uploadedUser?: Maybe<Scalars['String']['output']>;
}

export interface ApplicationAttachmentsStats {
  hirerUploadedAttachmentCount: Scalars['Int']['output'];
}

export type ApplicationAttachmentsStatsResponse =
  | ApplicationAttachmentsStatsResponseSuccess
  | ResponseError;

export interface ApplicationAttachmentsStatsResponseSuccess {
  result: ApplicationAttachmentsStats;
}

export interface ApplicationAttachmentsV2Input {
  applicationCorrelationId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export type ApplicationAttachmentsV2Response =
  | ApplicationAttachmentsV2ResponseSuccess
  | ResponseError;

export interface ApplicationAttachmentsV2ResponseSuccess {
  result: Array<ApplicationAttachmentV2>;
}

export enum ApplicationBucket {
  Accept = 'ACCEPT',
  Inbox = 'INBOX',
  Interview = 'INTERVIEW',
  NotSuitable = 'NOT_SUITABLE',
  Offer = 'OFFER',
  Prescreen = 'PRESCREEN',
  Shortlist = 'SHORTLIST',
}

export interface ApplicationByAdcentreProspectIdInput {
  adcentreProspectId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export interface ApplicationCandidateStats {
  totalApplicationCount: Scalars['Int']['output'];
}

export type ApplicationCandidateStatsResponse =
  | ApplicationCandidateStatsResponseSuccess
  | ResponseError;

export interface ApplicationCandidateStatsResponseSuccess {
  result: ApplicationCandidateStats;
}

export interface ApplicationData {
  /** The position the prospect appeared in the candidate list starting from 1. Null if sent from CandidateDetail. */
  positionInList?: InputMaybe<Scalars['Int']['input']>;
  prospectId: Scalars['ID']['input'];
}

export interface ApplicationEmail {
  adcentreCandidateId: Scalars['ID']['output'];
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  prospectId: Scalars['ID']['output'];
  sentDate: Scalars['DateTime']['output'];
  subject: Scalars['String']['output'];
  to: Scalars['String']['output'];
}

export interface ApplicationEmailEdge {
  node: ApplicationEmail;
}

export interface ApplicationEmails {
  edges: Array<ApplicationEmailEdge>;
}

export interface ApplicationEmailsInput {
  adcentreCandidateId: Scalars['ID']['input'];
  prospectId: Scalars['ID']['input'];
}

export interface ApplicationFilters {
  homeLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nationalityRightToWorkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionnaireFilters?: InputMaybe<Array<QuestionnaireFilter>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statusFolders?: InputMaybe<Array<ApplicationStatusFolder>>;
}

export interface ApplicationInput {
  applicationCorrelationId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export type ApplicationJobResponse =
  | ApplicationJobResponseSuccess
  | ResponseError;

export interface ApplicationJobResponseSuccess {
  result: Job;
}

export interface ApplicationMetadata {
  emailCount: Scalars['Int']['output'];
  hasCoverLetter: Scalars['Boolean']['output'];
  hasResume: Scalars['Boolean']['output'];
  hasSelectionCriteria: Scalars['Boolean']['output'];
  noteCount: Scalars['Int']['output'];
}

export type ApplicationMetadataResponse =
  | ApplicationMetadataResponseSuccess
  | ResponseError;

export interface ApplicationMetadataResponseSuccess {
  result: ApplicationMetadata;
}

export interface ApplicationNote {
  content?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  createdUser?: Maybe<Scalars['String']['output']>;
  createdUserId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
}

export interface ApplicationNoteV2 {
  content: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  createdUserFirstname: Scalars['String']['output'];
  createdUserId: Scalars['ID']['output'];
  createdUserLastname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
}

export interface ApplicationNotesInput {
  applicationCorrelationId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export type ApplicationNotesResponse =
  | ApplicationNotesResponseSuccess
  | ResponseError;

export interface ApplicationNotesResponseSuccess {
  result: Array<ApplicationNoteV2>;
}

export interface ApplicationPerformanceResponse {
  adRatingConfidence: AnalyticsAdRatingConfidence;
  applications: Scalars['Int']['output'];
  hasAdRating: Scalars['Boolean']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileViewsInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
}

export interface ApplicationPerformanceWidgetInput {
  jobId: Scalars['String']['input'];
}

export interface ApplicationProfile {
  education: Array<CandidateProfileEducation>;
  expectedSalaries: Array<CandidateProfileExpectedSalary>;
  homeLocation?: Maybe<Location>;
  licences: Array<CandidateProfileLicence>;
  nationalities: ApplicationProfileNationalitiesResponse;
  referenceChecksStats: ApplicationProfileReferenceChecksStatsResponse;
  rightsToWork: Array<CandidateProfileRightToWork>;
  rightsToWorkV2: Array<CandidateProfileRightToWorkV2>;
  skills: Array<CandidateProfileSkill>;
  verifications: ApplicationProfileVerificationsResponse;
  verificationsStats: ApplicationProfileVerificationsStatsResponse;
  /** @deprecated Use verifiedIdentity from verifications instead. */
  verifiedIdentity?: Maybe<CandidateProfileVerifiedIdentity>;
  workHistory: Array<CandidateProfileWorkHistory>;
}

export interface ApplicationProfile_NationalitiesArgs {
  input: ApplicationProfileNationalitiesInput;
}

export interface ApplicationProfileNationalitiesInput {
  jobId: Scalars['ID']['input'];
}

export type ApplicationProfileNationalitiesResponse =
  | ApplicationProfileNationalitiesResponseSuccess
  | ResponseError;

export interface ApplicationProfileNationalitiesResponseSuccess {
  result: Array<CandidateProfileNationality>;
}

export interface ApplicationProfileNotSpecifiedNationality {
  countryName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
}

export interface ApplicationProfileReferenceChecksStatsAvailable {
  count: Scalars['Int']['output'];
}

export interface ApplicationProfileReferenceChecksStatsRequested {
  _empty?: Maybe<Scalars['String']['output']>;
}

export type ApplicationProfileReferenceChecksStatsResponse =
  | ApplicationProfileReferenceChecksStatsResponseSuccess
  | ResponseError;

export interface ApplicationProfileReferenceChecksStatsResponseSuccess {
  result?: Maybe<ApplicationProfileReferenceChecksStatsResult>;
}

export type ApplicationProfileReferenceChecksStatsResult =
  | ApplicationProfileReferenceChecksStatsAvailable
  | ApplicationProfileReferenceChecksStatsRequested;

export type ApplicationProfileResponse =
  | ApplicationProfileResponseSuccess
  | ResponseError;

export interface ApplicationProfileResponseSuccess {
  result?: Maybe<ApplicationProfile>;
}

export interface ApplicationProfileVerifications {
  education: Array<Verification>;
  licences: Array<Verification>;
  rightToWork: Array<Verification>;
  verifiedIdentity?: Maybe<Verification>;
  workHistory: Array<Verification>;
}

export type ApplicationProfileVerificationsResponse =
  | ApplicationProfileVerificationsSuccess
  | ResponseError;

export type ApplicationProfileVerificationsStatsResponse =
  | ApplicationProfileVerificationsStatsSuccess
  | ResponseError;

export interface ApplicationProfileVerificationsStatsSuccess {
  totalCount: Scalars['Int']['output'];
  workHistoryCount: Scalars['Int']['output'];
}

export interface ApplicationProfileVerificationsSuccess {
  result: ApplicationProfileVerifications;
}

export interface ApplicationQuestionnaireAnswer {
  id?: Maybe<Scalars['ID']['output']>;
  legacyId?: Maybe<Scalars['ID']['output']>;
  text: Scalars['String']['output'];
  verification?: Maybe<Verification>;
}

export interface ApplicationQuestionnaireQuestion {
  answerType?: Maybe<Scalars['String']['output']>;
  answers: Array<ApplicationQuestionnaireAnswer>;
  id: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<ApplicationQuestionnaireQuestionSource>;
  status: ApplicationQuestionnaireQuestionStatus;
  text: Scalars['String']['output'];
}

export enum ApplicationQuestionnaireQuestionSource {
  Custom = 'Custom',
  Library = 'Library',
}

export enum ApplicationQuestionnaireQuestionStatus {
  CannotMatch = 'CANNOT_MATCH',
  Match = 'MATCH',
  NoMatch = 'NO_MATCH',
  PartialMatch = 'PARTIAL_MATCH',
}

export interface ApplicationQuestionnaireSubmission {
  id: Scalars['ID']['output'];
  questions: Array<ApplicationQuestionnaireQuestion>;
}

export type ApplicationQuestionnaireSubmissionResponse =
  | ApplicationQuestionnaireSubmissionResponseSuccess
  | ResponseError;

export interface ApplicationQuestionnaireSubmissionResponseSuccess {
  result?: Maybe<ApplicationQuestionnaireSubmission>;
}

export interface ApplicationRating {
  rating: Scalars['Int']['output'];
}

export type ApplicationResponse = ApplicationResponseSuccess | ResponseError;

export interface ApplicationResponseSuccess {
  result: Application;
}

export enum ApplicationSort {
  ApplicationDate = 'APPLICATION_DATE',
  Name = 'NAME',
  Rating = 'RATING',
  Relevance = 'RELEVANCE',
  Score = 'SCORE',
  SearchRelevance = 'SEARCH_RELEVANCE',
}

export enum ApplicationSource {
  Native = 'NATIVE',
  Uploaded = 'UPLOADED',
}

export enum ApplicationStatusFolder {
  Accept = 'ACCEPT',
  Inbox = 'INBOX',
  Interview = 'INTERVIEW',
  NotSuitable = 'NOT_SUITABLE',
  Offer = 'OFFER',
  Prescreen = 'PRESCREEN',
  Shortlist = 'SHORTLIST',
}

export type ApplicationVerificationsResponse =
  | ApplicationVerificationsResponseSuccess
  | ResponseError;

export interface ApplicationVerificationsResponseSuccess {
  result: Array<Verification>;
}

export interface ApplicationsFacetsInput {
  filters?: InputMaybe<ApplicationsFiltersInput>;
  jobId: Scalars['ID']['input'];
}

export type ApplicationsFacetsResponse =
  | ApplicationsFacetsResponseSuccess
  | ResponseError;

export interface ApplicationsFacetsResponseSuccess {
  result: ApplicationsFacetsResult;
}

export interface ApplicationsFacetsResult {
  homeLocationFacets: Array<ApplicationsFacetsResultHomeLocationFacet>;
  nationalityFacets: Array<ApplicationsFacetsResultNationalityFacet>;
}

export interface ApplicationsFacetsResultHomeLocationFacet {
  children: Array<ApplicationsFacetsResultHomeLocationFacet>;
  count: Scalars['Int']['output'];
  homeLocation: Location;
}

export interface ApplicationsFacetsResultNationalityFacet {
  count: Scalars['Int']['output'];
  nationality: ApplicationsFacetsResultNationalityFacetNationality;
}

export type ApplicationsFacetsResultNationalityFacetNationality =
  | ApplicationProfileNotSpecifiedNationality
  | CandidateProfileNationality;

export interface ApplicationsFilterCounts {
  questionnaireFilterCounts: Array<RoleRequirementFilterCount>;
}

export interface ApplicationsFiltersInput {
  homeLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nationalityRightToWorkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionnaireFilters?: InputMaybe<Array<QuestionnaireFilter>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statusFolders?: InputMaybe<Array<ApplicationStatusFolder>>;
}

export interface ApplicationsInput {
  filters?: InputMaybe<ApplicationFilters>;
  jobId: Scalars['ID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<ApplicationsSortInput>;
}

export interface ApplicationsPageInfo {
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  preFilterTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
}

export type ApplicationsResponse = ApplicationsResponseSuccess | ResponseError;

export interface ApplicationsResponseSuccess {
  filterCounts: ApplicationsFilterCounts;
  pageInfo: ApplicationsPageInfo;
  result: Array<Application>;
  statusFolderCounts: ApplicationsStatusFolderCounts;
}

export interface ApplicationsSortInput {
  orderBy?: InputMaybe<SortOrder>;
  sortField?: InputMaybe<ApplicationSort>;
}

export interface ApplicationsStatusFolderCounts {
  acceptCount: Scalars['Int']['output'];
  inboxCount: Scalars['Int']['output'];
  interviewCount: Scalars['Int']['output'];
  newCount: Scalars['Int']['output'];
  notSuitableCount: Scalars['Int']['output'];
  offerCount: Scalars['Int']['output'];
  prescreenCount: Scalars['Int']['output'];
  shortlistCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  viewedCount: Scalars['Int']['output'];
}

export interface ApprovedIntegrationRelationship {
  hirerAccountId: Scalars['ID']['output'];
  hirerOid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  relationship: IntegrationRelationshipType;
}

export interface AssociatedAction {
  code: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  job?: Maybe<InteractedJob>;
  subject?: Maybe<Scalars['String']['output']>;
}

export interface AssociatedParty {
  firstName?: Maybe<Scalars['String']['output']>;
  formattedName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
}

export interface AuditSuccessfulLoginPayload {
  success: Scalars['Boolean']['output'];
}

/**
 * Country ID - ['bca', 'bni', 'bri', 'cimb', 'mandiri']
 * Country TH - ['kbank', 'scb', 'bbl', 'ktb', 'bay', 'tmb', 'uob']
 * Country PH - ['bpi', 'unionbank']
 */
export enum Bank2C2P {
  Bay = 'bay',
  Bbl = 'bbl',
  Bca = 'bca',
  Bni = 'bni',
  Bpi = 'bpi',
  Bri = 'bri',
  Cimb = 'cimb',
  Kbank = 'kbank',
  Ktb = 'ktb',
  Mandiri = 'mandiri',
  Scb = 'scb',
  Tmb = 'tmb',
  Unionbank = 'unionbank',
  Uob = 'uob',
}

export type BillableApplicantsCountResponse =
  | BillableApplicantsCountResponseSuccess
  | ResponseError;

export interface BillableApplicantsCountResponseSuccess {
  result: Scalars['Int']['output'];
}

export interface BillableApplicantsData {
  billableApplicantsCount: BillableApplicantsCountResponse;
  count: Scalars['Int']['output'];
  maxBillableApplicants: MaxBillableApplicantsResponse;
}

export type BillableApplicantsResponse =
  | BillableApplicantsResponseSuccess
  | ResponseError;

export interface BillableApplicantsResponseSuccess {
  data?: Maybe<BillableApplicantsData>;
}

export enum BillableStatus {
  Billable = 'BILLABLE',
  NotBillable = 'NOT_BILLABLE',
}

export interface BillingContact {
  /** Email address of the user */
  email?: Maybe<Scalars['String']['output']>;
}

export enum BillingStatus {
  Ok = 'OK',
  OnCreditHold = 'ON_CREDIT_HOLD',
}

export interface Brand {
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isLiveOnAd?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  /** @deprecated Please use Brand.owner instead. */
  ownerId?: Maybe<Scalars['String']['output']>;
  state?: Maybe<BrandState>;
}

export type BrandAssetStrategies = CoverStrategies | LogoStrategies;

export interface BrandAssetType {
  id: Scalars['String']['output'];
  strategies: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
}

export enum BrandAssetTypeEnum {
  Cover = 'cover',
  Logo = 'logo',
}

export interface BrandAssetTypeInput {
  id: Scalars['String']['input'];
  strategies: Scalars['JSON']['input'];
  url: Scalars['String']['input'];
}

export interface BrandAssets {
  cover?: Maybe<BrandAssetType>;
  logo: BrandAssetType;
}

export interface BrandAssetsInput {
  cover?: InputMaybe<BrandAssetTypeInput>;
  logo: BrandAssetTypeInput;
}

export interface BrandOwner {
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<BrandOwnerType>;
}

export interface BrandOwnerInput {
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BrandOwnerType>;
}

export enum BrandOwnerType {
  AdvertiserId = 'advertiserId',
  SeekId = 'seekId',
}

export enum BrandState {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
}

export interface BrandedAd {
  bulletPointsUsed: Scalars['Boolean']['output'];
  coverImageUsed: Scalars['Boolean']['output'];
  logoUsed: Scalars['Boolean']['output'];
  type: RecommendationType;
}

/**
 * AdBudgetProduct feature to indicate budget is allowed to purchase ads of branded tier and onwards.
 * Also known as 'BrandedOnwards' in legacy terms.
 */
export interface BrandedFeaturesFeature extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface BrandingAsset {
  /** @deprecated Non translated string. Will return english only. */
  deleteWarning?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  isLiveOnAd?: Maybe<Scalars['Boolean']['output']>;
  isOnDefaultBrand?: Maybe<Scalars['Boolean']['output']>;
  numberOfBrands?: Maybe<Scalars['Int']['output']>;
  numberOfLiveAds?: Maybe<Scalars['Int']['output']>;
  strategies: BrandAssetStrategies;
  url: Scalars['String']['output'];
}

/** AdProductUpgrade feature that indicates branding properties available for ad product */
export interface BrandingFeature extends CatalogueProductFeature {
  /** @deprecated borderForSearchListing is deprecated. Use StyleEnhancementFeature instead. */
  borderForSearchListing: Scalars['Boolean']['output'];
  /**
   * Number of bullet points available on job ad. Only positive numbers allowed.
   * @deprecated bulletPoints is deprecated. Use BulletPointsFeature instead.
   */
  bulletPoints: Scalars['Int']['output'];
  /** Indicates job ad can have cover image */
  hasCoverImage: Scalars['Boolean']['output'];
  /** Indicates job ad can have a logo */
  hasLogo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mask: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface BrandingInput {
  brandId?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}

export interface BudgetOption {
  count: Scalars['Int']['output'];
  data: BudgetOptionInfo;
  key: Scalars['String']['output'];
}

export interface BudgetOptionInfo {
  expiryTimestampUTC?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
}

export interface BudgetProductGroup {
  displayName: Scalars['String']['output'];
  key: Scalars['String']['output'];
}

/** Product feature that indicates bullet points for an existing ad. */
export interface BulletPointsFeature extends CatalogueProductFeature {
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

/** Business numbers and identifiers such as ABN, BRN, TIN, etc. */
export interface BusinessIdentifiers {
  businessNumber?: Maybe<Scalars['String']['output']>;
  salesServiceTaxNumber?: Maybe<Scalars['String']['output']>;
  taxIdentificationNumber?: Maybe<Scalars['String']['output']>;
}

export enum BusinessType {
  Bpo = 'BPO',
  Corporate = 'Corporate',
  Franchisee = 'Franchisee',
  Government = 'Government',
  NotForProfit = 'NotForProfit',
  Other = 'Other',
  Recruiter = 'Recruiter',
  Sme = 'SME',
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export interface CandidateDemandResponse {
  comparisonPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  currentPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  hasCandidateDemand: Scalars['Boolean']['output'];
}

export interface CandidateDemandWidgetInput {
  jobId: Scalars['String']['input'];
}

export enum CandidateLabel {
  ApplicationStarts = 'APPLICATION_STARTS',
  Candidates = 'CANDIDATES',
}

export interface CandidateProfileEducation {
  completed: Scalars['Boolean']['output'];
  completionDate?: Maybe<CandidateProfileEducationCompletionDate>;
  credential: Array<CredentialInfo>;
  highlights?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  institute: Scalars['String']['output'];
  name: Scalars['String']['output'];
  verification?: Maybe<Verification>;
}

export interface CandidateProfileEducationCompletionDate {
  month?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
}

export interface CandidateProfileExpectedSalary {
  currency?: Maybe<Scalars['String']['output']>;
  frequency: SalaryFrequency;
  maximumSalaryValue?: Maybe<Scalars['Float']['output']>;
  minimumSalaryValue: Scalars['Float']['output'];
}

export interface CandidateProfileLicence {
  credential: Array<CredentialInfo>;
  description?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<CandidateProfileMonthYear>;
  formattedExpiryDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issueDate?: Maybe<CandidateProfileMonthYear>;
  issuingOrganisation?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  noExpiryDate: Scalars['Boolean']['output'];
  verification?: Maybe<Verification>;
}

export interface CandidateProfileMonthYear {
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
}

export interface CandidateProfileNationality {
  countryName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
}

export interface CandidateProfileNationality_CountryNameArgs {
  input: CandidateProfileNationalityCountryNameInput;
}

export interface CandidateProfileNationalityCountryNameInput {
  language: Scalars['String']['input'];
}

export interface CandidateProfileRightToWork {
  answer: Scalars['String']['output'];
  country: Scalars['String']['output'];
}

export interface CandidateProfileRightToWorkDisplayLabelInput {
  language: Scalars['String']['input'];
}

export interface CandidateProfileRightToWorkV2 {
  displayLabel: Scalars['String']['output'];
  id: Scalars['ID']['output'];
}

export interface CandidateProfileRightToWorkV2_DisplayLabelArgs {
  input: CandidateProfileRightToWorkDisplayLabelInput;
}

export interface CandidateProfileSkill {
  keyword: Scalars['String']['output'];
}

export interface CandidateProfileVerifiedIdentity {
  fullName: Scalars['String']['output'];
}

export interface CandidateProfileWorkHistory {
  achievements?: Maybe<Scalars['String']['output']>;
  company: Scalars['String']['output'];
  endDate?: Maybe<CandidateProfileMonthYear>;
  id: Scalars['ID']['output'];
  startDate: CandidateProfileMonthYear;
  title: Scalars['String']['output'];
}

export interface CandidateSupplyResponse {
  comparisonPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  currentPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  hasCandidateSupply: Scalars['Boolean']['output'];
}

export interface CandidateSupplyWidgetInput {
  jobId: Scalars['String']['input'];
}

export interface CappedUnitPrice {
  cappedPrice: CatalogueProductPrice;
  maxBillableUnits: Scalars['Int']['output'];
  metadata: CappedUnitPriceMetadata;
  type: Scalars['String']['output'];
  unitPrice: CatalogueProductPrice;
}

export interface CappedUnitPriceMetadata {
  unitType: Scalars['String']['output'];
}

export interface Cart {
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isOrderReady: Scalars['Boolean']['output'];
  items: Array<CartItem>;
  productSetId?: Maybe<Scalars['String']['output']>;
  promoCode?: Maybe<Scalars['String']['output']>;
}

export interface CartItem {
  parentReferenceId?: Maybe<Scalars['String']['output']>;
  product: CartItemProduct;
  referenceId: Scalars['String']['output'];
}

export interface CartItemInput {
  parentReferenceId?: InputMaybe<Scalars['String']['input']>;
  product: CartItemProductInput;
  referenceId: Scalars['String']['input'];
}

export interface CartItemJobReferenceInput {
  id: Scalars['String']['input'];
  revision: Scalars['ID']['input'];
}

export interface CartItemProduct {
  id: Scalars['String']['output'];
}

export interface CartItemProductInput {
  id: Scalars['ID']['input'];
  job?: InputMaybe<CartItemJobReferenceInput>;
  productType: CartItemProductTypeInput;
}

export enum CartItemProductTypeInput {
  AdBudget = 'AD_BUDGET',
  AdProduct = 'AD_PRODUCT',
  AdProductAddOn = 'AD_PRODUCT_ADD_ON',
  AdProductUpgrade = 'AD_PRODUCT_UPGRADE',
}

export interface CartNotFoundError {
  message: Scalars['String']['output'];
}

export interface CatalogueProduct {
  /** Information on how to display the product */
  display?: Maybe<ProductDisplay>;
  /** Features of the catalogue product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** Unique ID of the catalogue product */
  id: Scalars['ID']['output'];
  /** Name of the catalogue product */
  name: Scalars['String']['output'];
  /** Price of the catalogue product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** The unique product code for this product. Human-readable alternative to ID */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Whether the product is "purchasable" or was previously "purchased" */
  purchaseState: CatalogueProductPurchaseState;
}

export interface CatalogueProductAttributes {
  jobId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  productSetId: Scalars['String']['input'];
  /** TODO: what if the set doesn't contain any JobAds? it is optional */
  revisionId?: InputMaybe<Scalars['String']['input']>;
}

export interface CatalogueProductFeature {
  /** Unique ID of the catalogue product feature */
  id: Scalars['ID']['output'];
  /** Name of the catalogue product feature, ie. Listing, Branding, StandardTalentSearch etc */
  name: Scalars['String']['output'];
  /** Type of the catalogue product feature, ie. Listing, Branding, StandardTalentSearch etc */
  type: Scalars['String']['output'];
}

export type CatalogueProductPrice = HiddenPrice | Price;

export enum CatalogueProductPurchaseState {
  Purchasable = 'PURCHASABLE',
  Purchased = 'PURCHASED',
}

export interface CategoriesInput {
  categoryId: Scalars['String']['input'];
  subCategoryId: Scalars['String']['input'];
}

export interface Category {
  categoryId: Scalars['Int']['output'];
  categoryName: Scalars['String']['output'];
  subCategoryId: Scalars['Int']['output'];
  subCategoryName: Scalars['String']['output'];
}

export interface CategorySuggestion {
  category: Category;
  probability: Scalars['Float']['output'];
}

export enum ChannelCode {
  Alipayhk = 'ALIPAYHK',
  Billease = 'BILLEASE',
  Dana = 'DANA',
  Gcash = 'GCASH',
  Grab = 'GRAB',
  Hkfpsqr = 'HKFPSQR',
  Homecredit = 'HOMECREDIT',
  Linkaja = 'LINKAJA',
  Ovo = 'OVO',
  Paymaya = 'PAYMAYA',
  Paypal = 'PAYPAL',
  Shpqr = 'SHPQR',
}

export interface ChildAccountBannerResponse {
  isChild: Scalars['Boolean']['output'];
}

export interface Claim {
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface ClassificationOption {
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  subClassifications?: Maybe<Array<ClassificationOption>>;
  value: Scalars['String']['output'];
}

export interface ClicksOnYourAdResponse {
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  appConversionInPercentage?: Maybe<Scalars['Float']['output']>;
  hasAdRating: Scalars['Boolean']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileClicksInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
  totalClicks: Scalars['Int']['output'];
}

export interface ClicksOnYourAdWidgetInput {
  jobId: Scalars['String']['input'];
}

export interface CompanyAddress {
  country?: Maybe<Scalars['String']['output']>;
}

export interface CompanyProfile {
  awards?: Maybe<Array<CompanyProfileAward>>;
  branding?: Maybe<CompanyProfileBranding>;
  cultureAndValues?: Maybe<CompanyProfileCultureAndValues>;
  customStatement?: Maybe<CompanyProfileCustomStatement>;
  featured?: Maybe<CompanyProfileFeatured>;
  gallery?: Maybe<Array<CompanyProfilePhoto>>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<CompanyProfileMetadata>;
  missionStatement?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  overview?: Maybe<CompanyProfileOverview>;
  perksAndBenefits?: Maybe<Array<CompanyProfilePerksAndBenefits>>;
  profilePublic?: Maybe<Scalars['Boolean']['output']>;
  reviews: Reviews;
}

export interface CompanyProfile_ReviewsArgs {
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
}

export interface CompanyProfileAction {
  actionType: CompanyProfileActionType;
  companyId: Scalars['ID']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
}

export enum CompanyProfileActionType {
  Claim = 'CLAIM',
  Manage = 'MANAGE',
}

export interface CompanyProfileAdvertiserClaimInput {
  businessType?: InputMaybe<BusinessType>;
  businessTypeOther?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  companySize?: InputMaybe<Scalars['String']['input']>;
  relationshipStatus: RelationshipStatus;
  zone: Scalars['String']['input'];
}

export interface CompanyProfileAdvertiserClaimResponse {
  businessType?: Maybe<BusinessType>;
  businessTypeOther?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  companySize?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  hirerUserId?: Maybe<Scalars['String']['output']>;
  reasons?: Maybe<Array<Maybe<Reason>>>;
  relationshipStatus: RelationshipStatus;
  staffUserId?: Maybe<Scalars['String']['output']>;
}

export interface CompanyProfileAward {
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  year: Scalars['Int']['output'];
}

export interface CompanyProfileAwardInput {
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface CompanyProfileBranding {
  brandId?: Maybe<Scalars['ID']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  logo: Scalars['String']['output'];
}

export interface CompanyProfileBrandingInput {
  brandId?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  logo: Scalars['String']['input'];
}

export interface CompanyProfileCultureAndValues {
  description: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  values?: Maybe<Array<CompanyProfileCultureValue>>;
}

export interface CompanyProfileCultureAndValuesInput {
  description: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  values?: InputMaybe<Array<CompanyProfileCultureValueInput>>;
}

export interface CompanyProfileCultureValue {
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface CompanyProfileCultureValueInput {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
}

export interface CompanyProfileCustomStatement {
  description: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface CompanyProfileCustomStatementInput {
  description: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
}

export interface CompanyProfileFeatured {
  description?: Maybe<Array<Scalars['String']['output']>>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
}

export interface CompanyProfileFeaturedInput {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  videoUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface CompanyProfileIds {
  companyIds?: Maybe<Array<Scalars['ID']['output']>>;
}

export interface CompanyProfileInput {
  awards?: InputMaybe<Array<CompanyProfileAwardInput>>;
  branding?: InputMaybe<CompanyProfileBrandingInput>;
  cultureAndValues?: InputMaybe<CompanyProfileCultureAndValuesInput>;
  customStatement?: InputMaybe<CompanyProfileCustomStatementInput>;
  featured?: InputMaybe<CompanyProfileFeaturedInput>;
  gallery?: InputMaybe<Array<CompanyProfilePhotoInput>>;
  id: Scalars['ID']['input'];
  missionStatement?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  overview?: InputMaybe<CompanyProfileOverviewInput>;
  perksAndBenefits?: InputMaybe<Array<CompanyProfilePerksAndBenefitsInput>>;
}

export interface CompanyProfileMetadata {
  profilePublic: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface CompanyProfileOverview {
  description?: Maybe<Array<Scalars['String']['output']>>;
  industry?: Maybe<Scalars['String']['output']>;
  primaryLocation?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  specialities?: Maybe<Array<Scalars['String']['output']>>;
  website?: Maybe<CompanyProfileWebsite>;
}

export interface CompanyProfileOverviewInput {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Scalars['String']['input']>;
  primaryLocation?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  specialities?: InputMaybe<Array<Scalars['String']['input']>>;
  website?: InputMaybe<CompanyProfileWebsiteInput>;
}

export interface CompanyProfilePerksAndBenefits {
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface CompanyProfilePerksAndBenefitsInput {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
}

export interface CompanyProfilePhoto {
  date?: Maybe<Scalars['Int']['output']>;
  link: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
}

export interface CompanyProfilePhotoInput {
  date?: InputMaybe<Scalars['Int']['input']>;
  link: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface CompanyProfileWebsite {
  displayName?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
}

export interface CompanyProfileWebsiteInput {
  displayName?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
}

export interface ComparativeAttribute {
  description?: Maybe<Scalars['String']['output']>;
  helpTip?: Maybe<InclusionHelpTip>;
  key: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface ComparativeSection {
  attributes?: Maybe<Array<ComparativeAttribute>>;
  title: Scalars['String']['output'];
}

export interface ComparativeSections {
  sections?: Maybe<Array<ComparativeSection>>;
}

export interface ConfirmInvoiceOrderInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  billToAgent: Scalars['Boolean']['input'];
  idempotencyKey: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
}

export type ConfirmInvoiceOrderPayload =
  | InvoiceOrderNotFoundPayload
  | InvoiceOrderSuccessPayload
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PromotionInvalidError;

export interface ConnectedTalentSearchRedirectUrlInput {
  /** Return URI to redirect to after selecting an account. */
  returnUri: Scalars['String']['input'];
}

export interface ConnectionOptions {
  accessCv?: Maybe<AccessCvState>;
  accessProfile?: Maybe<AccessProfileState>;
  exportProfile?: Maybe<Array<ExportProfileState>>;
  sendJob?: Maybe<SendJobState>;
  sendMessage?: Maybe<SendMessageState>;
}

export interface ConnectionPending {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface ConnectionStatusPayload {
  connectionStatus: TalentSearchConnectionStatus;
  creditsRedeemedDateUtc?: Maybe<Scalars['String']['output']>;
  nextAvailableDateUtc?: Maybe<Scalars['String']['output']>;
}

export interface Contract {
  /** Info on discounts that are available for Ad products, only available for SEEK_CONTRACT types */
  adDiscounts?: Maybe<Array<Maybe<ContractAdDiscount>>>;
  /** The date when contract was created eg. '2019-10-09T11:00:00.000Z' */
  createdDate: Scalars['String']['output'];
  current: Scalars['Boolean']['output'];
  expiry: Expiry;
  /** The date from when contract is consumable eg. '2019-11-01T11:00:00.000Z' */
  validFromDate: Scalars['String']['output'];
  value: ContractValue;
}

export interface ContractAdDiscount {
  adType: AdType;
  /** Minimum possible discount for an AdType according to the contract and the current variable ad prices */
  minimumPercentageSavings?: Maybe<Scalars['Float']['output']>;
}

export interface ContractBalance {
  adType: AdType;
  adTypeDescription?: Maybe<Scalars['String']['output']>;
  contractType: ContractType;
  contracts?: Maybe<Array<Contract>>;
  dollarBased: Scalars['Boolean']['output'];
  /** To show or hide the balance */
  hideBalance: Scalars['Boolean']['output'];
  lowBalance: Scalars['Boolean']['output'];
  recommendUpSell: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  totalValue: ContractValue;
}

export interface ContractBalanceGroup {
  /** Collection of contracts grouped by the contract name */
  contracts: Array<ContractDetails>;
  /** Name of the contract which can be shown to hirers, e.g. "Premium Ad Budget" */
  name: Scalars['String']['output'];
  /** Total balance of remaining and original amount of the contracts group */
  totalBalance: ContractBalanceSummary;
}

export interface ContractBalanceSummary {
  /** The currency the contract is based on */
  currency: Scalars['String']['output'];
  /** The original amount the hirer had purchased for their budget */
  originalAmount: Money;
  /** The amount remaining on the hirer budget that can be spent */
  remainingAmount: Money;
}

export interface ContractDetails {
  /** Denotes the 'amount' of the budget in minor units (E.g. cents in AUD) */
  balance: ContractBalanceSummary;
  /** The ISO timestamp indicating when the contract was created in CRM */
  createdAt: Scalars['String']['output'];
  /** The ISO timestamp indicating when the contract ends */
  endDate: Scalars['String']['output'];
  /** The ISO timestamp indicating when the contract is applicable for use by the hirer */
  startDate: Scalars['String']['output'];
}

export interface ContractDollarValue {
  original: Money;
  remaining: Money;
}

export enum ContractType {
  AdPack = 'AD_PACK',
  MasterAgreement = 'MASTER_AGREEMENT',
  PricingGroup = 'PRICING_GROUP',
  SeekContract = 'SEEK_CONTRACT',
  Subscription = 'SUBSCRIPTION',
  TalentSearchContract = 'TALENT_SEARCH_CONTRACT',
}

export interface ContractUnitValue {
  original: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
}

export interface ContractValue {
  dollars: ContractDollarValue;
  units?: Maybe<ContractUnitValue>;
}

export interface ConversionRateData {
  adConversionRate?: Maybe<Scalars['Float']['output']>;
  mobileRate: Scalars['Float']['output'];
  similarAdAverage: Scalars['Float']['output'];
  similarAdConversionRate?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
}

export interface CorrectUserIncorrectHirerResponseSuccess {
  result: HirerJobAccess;
}

export interface CountriesInput {
  /** Used to generate the 'contextualName', this should usually represent the country the hire is located in. If it isn't provided it will fallback to the hirer's nation */
  contextual?: InputMaybe<Scalars['String']['input']>;
  /** Restricts the location suggestion search to only include these countries in the results. */
  only?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Controls the order of results so that you can specify for one countries results to appear above other(s). If it isn't provided it will use the order of 'only' as fallback. If 'only' isn't provided then it will fallback to 'contextual' as first preference. */
  preferenceOrder?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface Country {
  /** 2 digit code in ISO 3166-1 alpha-2 format eg GB */
  countryCode: Scalars['String']['output'];
  /** Full name of the country eg United Kingdom */
  name: Scalars['String']['output'];
}

export interface CountrySelectionOptions {
  /** List of countries to populate a drop-down list or autocomplete. */
  options: Array<Country>;
}

export interface CountryType {
  /** Full name of the country */
  defaultName: Scalars['String']['output'];
  /** Type of the location, eg. "State" / "Country" */
  kind: Scalars['String']['output'];
}

export interface CoverStrategies {
  jdpCover: Scalars['String']['output'];
  jdpCoverThumbnail: Scalars['String']['output'];
}

export interface Create2C2PPaymentIntentForInvoiceInput {
  /** The phone number of payer when OVO, BILLEASE or HOMECREDIT channelcode is used. */
  accountNo?: InputMaybe<Scalars['String']['input']>;
  /** bank used by 2c2p to identify the bank */
  bank?: InputMaybe<Bank2C2P>;
  /** optional, only for e-wallets. channelCode used by 2c2p to identify the channel */
  channelCode?: InputMaybe<ChannelCode>;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** The return url for the frontend to redirect to after payment is completed. */
  frontendReturnUrl?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: Array<InvoiceInput>;
  /** Type of payment method */
  paymentMethodType: PaymentMethodType2C2P;
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
}

export interface Create2C2PPaymentIntentInput {
  /** The phone number of payer when OVO, BILLEASE or HOMECREDIT channelcode is used. */
  accountNo?: InputMaybe<Scalars['String']['input']>;
  /** bank used by 2c2p to identify the bank */
  bank?: InputMaybe<Bank2C2P>;
  /** optional, only for e-wallets. channelCode used by 2c2p to identify the channel */
  channelCode?: InputMaybe<ChannelCode>;
  /** The return url for the frontend to redirect to after payment is completed. */
  frontendReturnUrl?: InputMaybe<Scalars['String']['input']>;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /** Type of payment method */
  paymentMethodType: PaymentMethodType2C2P;
}

export type Create2c2pPaymentIntentPayload =
  | InvoiceError
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PaymentIntent2C2PPayload
  | PaymentIntentChargeError
  | PaymentMethodError
  | PromotionInvalidError;

export interface CreateApplicationNoteInput {
  content: Scalars['String']['input'];
  prospectId: Scalars['ID']['input'];
}

export interface CreateBrandInput {
  assets: BrandAssetsInput;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
}

export type CreateBrandPayload =
  | CreateBrandSuccessPayload
  | InvalidInputPayload;

export interface CreateBrandSuccessPayload {
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  state?: Maybe<BrandState>;
}

export interface CreateCustomQuestionErrorResult {
  reason: Scalars['String']['output'];
}

export interface CreateCustomQuestionInput {
  /** Language code */
  languageId: Scalars['String']['input'];
  /** Custom question to be created */
  question: CustomQuestion;
}

export type CreateCustomQuestionOutputUnion =
  | CreateCustomQuestionErrorResult
  | CreateCustomQuestionResult;

export interface CreateCustomQuestionResult {
  question: OutputCustomQuestion;
  validationFailures: Array<CustomQuestionValidationFailure>;
}

export interface CreateDraftJobInput {
  /**
   * Adcentre advertiserId
   * @deprecated No longer required. Will be retired once the front-end is updated.
   */
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Selected branding info */
  branding?: InputMaybe<DraftJobBrandingInput>;
  /** Selected category and sub-category */
  categories?: InputMaybe<Array<DraftJobCategoryInput>>;
  /** CreatedAt date will only update when used in migration */
  createdAt?: InputMaybe<Scalars['String']['input']>;
  /** The Job Description, aka "Write my own" */
  details?: InputMaybe<Scalars['String']['input']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   * @deprecated Will be removed once deprecated express api
   */
  draftProgress?: InputMaybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType?: InputMaybe<DraftJobFlowType>;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: InputMaybe<Array<DraftJobGuidedDetailsInput>>;
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: InputMaybe<Scalars['String']['input']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: InputMaybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The job location info */
  locations?: InputMaybe<Array<DraftJobLocationInput>>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: InputMaybe<DraftJobPaymentOption>;
  /** Hirer's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Selected products info */
  products?: InputMaybe<DraftJobProductsInput>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: InputMaybe<Scalars['String']['input']>;
  /** Min/Max salaries info */
  salary?: InputMaybe<DraftJobSalaryDetailsInput>;
  /** The status of the draft */
  status?: InputMaybe<DraftStatus>;
  /** The job summary to appear in search */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** The job title(s) */
  titles?: InputMaybe<Array<DraftJobRoleTitleInput>>;
  /** The video info, e.g. video url */
  video?: InputMaybe<DraftJobVideoInput>;
  /** Work arrangements, ie Remote, Onsite, Hybrid */
  workArrangements?: InputMaybe<Array<WorkArrangement>>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: InputMaybe<Array<WorkType>>;
}

export interface CreateDraftJobPayload {
  /**
   * The successfully created draft job id. Deprecated "Int" version.
   * @deprecated Will be removed once no clients are calling it
   */
  draftId?: Maybe<Scalars['Int']['output']>;
  /** The successfully created draft job id */
  id?: Maybe<Scalars['String']['output']>;
}

export interface CreateJobInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<BrandingInput>;
  draftId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
}

export interface CreateJobResponse {
  /** The key used to create the job */
  idempotencyKey: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  /** The id of the revision that was created for the job */
  revisionId: Scalars['String']['output'];
}

export interface CreateJobRevisionInput {
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  /** The workflow context that the Job Revision is being created in. e.g. upgrade-purchase */
  workflowContext?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateJobRevisionPayload {
  revisionId: Scalars['String']['output'];
}

export interface CreateOrderInput {
  /**
   * Advertiser's Id.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Advertiser's nation.
   * @deprecated not necessary
   */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** Selected line items. Contains input attributes associated to selected product. */
  lineItems: Array<OrderRequestInputItem>;
  /** Idempotency key for create order request */
  orderCreationId?: InputMaybe<Scalars['String']['input']>;
  /** Promotion code. */
  promoCode?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateOrderPayload {
  order?: Maybe<Order>;
}

export interface CreateOrderSuccessPayload {
  order?: Maybe<Order>;
  orderCreationId?: Maybe<Scalars['String']['output']>;
  promotion?: Maybe<Promotion>;
}

export type CreateOrderWithPromotionPayload =
  | CreateOrderSuccessPayload
  | PromotionInvalidError;

export interface CreateProductSetForAdEditingInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId: Scalars['String']['input'];
  /** The current job details as seen by the hirer during edit */
  jobAdDetails: JobAdDetails;
  jobId: Scalars['ID']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export interface CreateProductSetForAdEditingPayload {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForAdPostingInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  jobAdDetails: JobAdDetails;
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
  /** The user intent */
  userIntent?: InputMaybe<UserIntentType>;
}

export interface CreateProductSetForAdPostingPayload {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForAdRefreshInput {
  /** The draft ID associated to the job refresh flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The job ID associated to the job refresh flow */
  jobId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
  /** The user intent */
  userIntent?: InputMaybe<UserIntentType>;
}

export interface CreateProductSetForAdRefreshPayload {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForAdUpgradePurchaseInput {
  /** The ID of the Job that is to be updated */
  jobId: Scalars['String']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /**
   * The unique identifier linking tracking events associated with a user's interaction within a specific UI flow that can
   * result in a purchase. It enables the aggregation of data related to actions such as posting a job ad, paying for it,
   * upgrading the ad, and other relevant activities occurring within the same user session.
   */
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
  /** The user intent */
  userIntent?: InputMaybe<UserIntentType>;
}

export interface CreateProductSetForAdUpgradePurchasePayload {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The product IDs that are purchasable */
  productIds: Array<Scalars['ID']['output']>;
  /** The created product set */
  productSet: ProductSet;
  /** Products to display */
  products: Array<AdProductUpgrade>;
}

export interface CreateProductSetForBudgetsPurchaseForHirerInput {
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** The unique identifier linking tracking events associated with a user's interaction within a specific UI flow that can result in a purchase. */
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export interface CreateProductSetForBudgetsPurchaseForHirerPayload {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForBudgetsPurchaseForVisitorInput {
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
  /** The randomly generated UUIDv4 of the logged out user */
  visitorId: Scalars['ID']['input'];
}

export interface CreateProductSetForBudgetsPurchaseForVisitorPayload {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForMultiLocationInput {
  /**
   * The ID of the advertiser
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The current job details as seen by the hirer during edit */
  jobAdDetails: JobAdDetails;
  /** ID of the original job being posted to multiple locations */
  jobId: Scalars['String']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export interface CreateProductSetForMultiLocationPayload {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForPriceCheckerInput {
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  jobAdDetails: JobAdDetails;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export interface CreateProductSetForPriceCheckerPreviewInput {
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  jobAdDetails: JobAdDetails;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export interface CreateProductSetForPriceCheckerPreviewPayload {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateQuestionnaireInput {
  /** @deprecated Internally resolved via auth */
  adCentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** This is the country code for the website domain being used to post job and create questionnaire. e.g values au and nz as strings */
  domainCountryCode: Scalars['String']['input'];
  language: Scalars['String']['input'];
  sourceQuestionnaireId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateQuestionnaireResponse {
  questionnaireId?: Maybe<Scalars['String']['output']>;
  questionnaireUrl?: Maybe<Scalars['String']['output']>;
}

export interface CreateSavedSearchInput {
  createdDate: Scalars['String']['input'];
  criteria: TalentSearchCriteriaInput;
  name: Scalars['String']['input'];
}

export interface CreateSavedSearchPayload {
  savedSearch?: Maybe<TalentSearchSavedSearch>;
}

export interface CreateStripePaymentIntentForInvoiceInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** Number of days before the payment intent expires and is disabled for payment. Minimum of at least 1 day */
  expiryDays?: InputMaybe<Scalars['Int']['input']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: Array<InvoiceInput>;
  /** Type of payment method */
  paymentMethod: StripePaymentMethod;
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
}

export interface CreateStripePaymentIntentInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /** Type of payment method */
  paymentMethod: StripePaymentMethod;
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
}

export type CreateStripePaymentIntentPayload =
  | InvoiceError
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PaymentMethodError
  | PromotionInvalidError
  | StripePaymentIntentPayload;

export interface CredentialInfo {
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
}

export interface CreditCard {
  /** The brand of credit card processor such as 'Visa' or 'MasterCard' */
  brand: Scalars['String']['output'];
  /** The expiry month of the credit from 1 to 12, such as '02' for February or '12' for December. */
  expiryMonth: Scalars['String']['output'];
  /** A four digit number representing the expiry year, e.g. '2022' */
  expiryYear: Scalars['String']['output'];
  /** The last (typically 4) digits of a credit card, e.g. '4234' */
  lastDigits: Scalars['String']['output'];
  /** A token that uniquely represents the card, e.g. 'card_1HPZvMAtb48kSZAqvNZabcde' */
  token: Scalars['String']['output'];
}

export enum CreditCardBrandInput {
  Amex = 'AMEX',
  MasterCard = 'MASTER_CARD',
  Visa = 'VISA',
}

export enum Currency {
  Aud = 'AUD',
  Hkd = 'HKD',
  Idr = 'IDR',
  Myr = 'MYR',
  Nzd = 'NZD',
  Php = 'PHP',
  Sgd = 'SGD',
  Thb = 'THB',
}

export interface CurrentAdvertiserContext {
  /** ID of the advertiser account. */
  advertiserId: Scalars['ID']['output'];
  /** Optional billing ID provided by CRM. */
  billingId?: Maybe<Scalars['String']['output']>;
  /**
   * Can Manage Company Profile
   * @deprecated Use permissionOptions.canManageCompanyProfile instead.
   */
  canManageCompanyProfile?: Maybe<Scalars['Boolean']['output']>;
  /** User claims relevant to the specified advertiser */
  claims?: Maybe<Array<Maybe<Claim>>>;
  /** Indicates whether the advertiser is an agency account. */
  isAgency: Scalars['Boolean']['output'];
  /** Name of the advertiser account. */
  name: Scalars['String']['output'];
  /** Key/value pairs of permissions for a user on behalf of the advertiser. */
  permissionOptions: PermissionOptions;
  /**
   * Array of permissions for a user on behalf of the advertiser.
   * @deprecated Use permissionOptions instead.
   */
  permissions?: Maybe<Array<Permission>>;
  /** Status of Profile Properties related to the account of the user currently signed in */
  profileProperties?: Maybe<ProfileProperties>;
  /** Role of user relevant to the specified advertiser. Observed to be null for migrated or impersonated customers. */
  role?: Maybe<Scalars['String']['output']>;
  /** SeekWeb Advertiser ID. */
  seekId: Scalars['ID']['output'];
  /** The advertiser's status. */
  status: AdvertiserStatus;
  /** Effective Talent Search access for this user against this advertiser */
  talentSearchAccess?: Maybe<TalentSearchAccess>;
}

export type CurrentAdvertiserResponse =
  | CurrentAdvertiserContext
  | MissingAdvertiserIdClaimError;

export interface CurrentUserContext {
  /** Email of the user currently signed in */
  email: Scalars['String']['output'];
  /** First name of the user. */
  firstName: Scalars['String']['output'];
  /** Adcentre user id. */
  id: Scalars['String']['output'];
  /** Last name of the user. */
  lastName: Scalars['String']['output'];
  /** First and last names of the user. */
  name: Scalars['String']['output'];
  /** Verification status of the user. */
  status: Scalars['String']['output'];
}

export type CurrentUserResponse = CurrentUserContext | MissingUserIdClaimError;

export interface CustomContent {
  id: Scalars['String']['output'];
}

export interface CustomQuestion {
  /** Options */
  options: Array<Option>;
  /** Hirer Question Type e.g SingleSelect, FreeText */
  questionType: HirerQuestionType;
  /** Question Text */
  text: Scalars['String']['input'];
}

export interface CustomQuestionValidationFailure {
  /** Custom Question Validation Failure Message */
  message: Scalars['String']['output'];
}

export interface CvUnavailable {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface DailyTrendsProductDisplayName {
  day: Scalars['String']['output'];
  items?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
}

export type DashboardJobsCountsResponse =
  | DashboardJobsCountsResponseSuccess
  | ResponseError;

export interface DashboardJobsCountsResponseSuccess {
  directExpired?: Maybe<Scalars['Int']['output']>;
  directPosted?: Maybe<Scalars['Int']['output']>;
  draftsCount?: Maybe<Scalars['Int']['output']>;
  indirectExpired?: Maybe<Scalars['Int']['output']>;
  indirectPosted?: Maybe<Scalars['Int']['output']>;
}

export interface DashboardJobsInput {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** The maximum number of results per page. Default is 5. */
  limit?: InputMaybe<Scalars['Int']['input']>;
}

export type DashboardJobsResponse =
  | DashboardJobsResponseSuccess
  | ResponseError;

export interface DashboardJobsResponseSuccess {
  jobs: JobConnection;
}

export interface DateAxisLabel {
  date: Scalars['Date']['output'];
  label?: Maybe<Scalars['String']['output']>;
}

export interface DeleteBrandingAssetInput {
  assetId: Scalars['ID']['input'];
  assetType: BrandAssetTypeEnum;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface DeleteBrandingAssetPayload {
  success?: Maybe<Scalars['Boolean']['output']>;
  successMessage?: Maybe<Scalars['String']['output']>;
}

export interface DeleteDraftJobInput {
  /** The id of the draft job to be deleted. */
  id: Scalars['String']['input'];
}

export interface DeleteDraftJobPayload {
  /** Indicates if deleting the draft job was successful or not. */
  successful: Scalars['Boolean']['output'];
}

export interface DeleteSavedSearchPayload {
  deletedCriteriaHash?: Maybe<Scalars['String']['output']>;
}

export interface DeleteStripeSavedCreditCardInput {
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String']['input'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
}

export interface DeleteStripeSavedCreditCardPayload {
  /** Status of the credit card deletion */
  successful?: Maybe<Scalars['Boolean']['output']>;
}

export interface DeleteUploadedSupportDocInput {
  applicationCorrelationId: Scalars['ID']['input'];
  blobId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export type DeleteUploadedSupportDocResponse =
  | DeleteUploadedSupportDocResponseSuccess
  | ResponseError;

export interface DeleteUploadedSupportDocResponseSuccess {
  result: DeleteUploadedSupportDocResult;
}

export interface DeleteUploadedSupportDocResult {
  blobId: Scalars['ID']['output'];
}

export interface DigitalIdentity {
  isVerified: Scalars['Boolean']['output'];
  verifiedFields: Array<VerifiedField>;
}

export interface DisableMessageType {
  en: Scalars['String']['output'];
}

export interface Discount {
  discountAmount: Money;
  discountPercentage: Scalars['Float']['output'];
}

export interface DisplayBanner {
  enabled: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DisplayCallToAction {
  enabled: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DisplayComparative {
  content?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface DisplayComparatives {
  content: Array<DisplayComparative>;
  enabled: Scalars['Boolean']['output'];
}

export interface DisplayCustom {
  content: Array<CustomContent>;
  enabled: Scalars['Boolean']['output'];
}

export interface DisplayExpectations {
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DisplayHighlights {
  content: Array<Highlight>;
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DisplayImage {
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
}

export interface DisplayInclusions {
  content: Array<Inclusion>;
  enabled: Scalars['Boolean']['output'];
  title?: Maybe<DisplayInclusionsTitle>;
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DisplayInclusionsTitle {
  content: Scalars['String']['output'];
}

export interface DisplayMetadataError extends Error {
  message: Scalars['String']['output'];
}

export interface DisplayPanel {
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DisplayPrice {
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DisplayRating {
  enabled: Scalars['Boolean']['output'];
  size?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

export enum DisplayRecommendation {
  Exclusive = 'exclusive',
  Inclusive = 'inclusive',
}

export interface DisplayStyledContent {
  content: Scalars['String']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

/** DisplayTag feature for tags to be shown on candidate search results */
export interface DisplayTagFeature extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tag: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface DisplayTagline {
  content: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DisplayTitle {
  content: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

export interface DownloadCv {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface DownloadProfile {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface DownloadProfileResponse {
  profileUrl: Scalars['String']['output'];
  resumeUrl?: Maybe<Scalars['String']['output']>;
}

export interface DraftJob {
  /**
   * Adcentre adType that is old way of representing "products"
   * @deprecated Will be removed once deprecated express api
   */
  adType?: Maybe<Scalars['String']['output']>;
  /** A reference provided by the advertiser to facilitate searching in draft job lists. */
  advertiserJobReference?: Maybe<Scalars['String']['output']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: Maybe<Scalars['String']['output']>;
  /** Selected branding info */
  branding?: Maybe<DraftJobBranding>;
  /** Selected category and sub-category */
  categories?: Maybe<Array<DraftJobCategory>>;
  /** Date/time in UTC (ISO 8601 format) the draft job was created. */
  createdDate: Scalars['String']['output'];
  /** The Job Description, aka "Write my own" */
  details?: Maybe<Scalars['String']['output']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   * @deprecated Will be removed once deprecated express api
   */
  draftProgress?: Maybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: Maybe<Scalars['String']['output']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType?: Maybe<DraftJobFlowType>;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: Maybe<Array<Maybe<DraftJobGuidedDetails>>>;
  /** Flag to indicate if AI assistance has been used to generate job description and summary */
  hasAIAssistanceBeenUsed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: Maybe<Scalars['String']['output']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: Maybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The job location info. Uses the Location type and will replace locations. */
  locationsV2?: Maybe<Array<Location>>;
  /** Details of the user who created the draft. */
  owner?: Maybe<HirerUserDetails>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: Maybe<DraftJobPaymentOption>;
  /** The phone number for the job */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Selected products info */
  products?: Maybe<DraftJobProducts>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: Maybe<Scalars['String']['output']>;
  /** Min/Max salaries info */
  salary?: Maybe<DraftJobSalaryDetails>;
  status: DraftStatus;
  /** The job summary to appear in search */
  summary?: Maybe<Scalars['String']['output']>;
  /** The draft job title. */
  title?: Maybe<Scalars['String']['output']>;
  /**
   * The job title(s)
   * @deprecated Use title instead
   */
  titles?: Maybe<Array<Maybe<DraftJobRoleTitle>>>;
  /** The video info, e.g. video url */
  video?: Maybe<DraftJobVideo>;
  /** Work arrangements, ie Remote, Onsite, Hybrid */
  workArrangements?: Maybe<Array<WorkArrangement>>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: Maybe<Array<Maybe<WorkType>>>;
}

export interface DraftJobBranding {
  id?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
}

export interface DraftJobBrandingInput {
  id: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}

export interface DraftJobCategory {
  adcentreCategoryId?: Maybe<Scalars['String']['output']>;
  adcentreSubCategoryId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  subCategoryId?: Maybe<Scalars['String']['output']>;
}

export interface DraftJobCategoryInput {
  adcentreCategoryId?: InputMaybe<Scalars['String']['input']>;
  adcentreSubCategoryId?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['String']['input'];
  subCategoryId: Scalars['String']['input'];
}

export enum DraftJobFlowType {
  Create = 'CREATE',
  Edit = 'EDIT',
  Multilocation = 'MULTILOCATION',
}

export interface DraftJobGuidedDetails {
  type: GuidedDetailsType;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface DraftJobGuidedDetailsInput {
  type: GuidedDetailsType;
  values: Array<Scalars['String']['input']>;
}

export interface DraftJobLocation {
  seekId: Scalars['String']['output'];
}

export interface DraftJobLocationInput {
  id: Scalars['String']['input'];
}

export interface DraftJobNotFoundPayload {
  reason: Scalars['String']['output'];
}

export type DraftJobPayload = DraftJobNotFoundPayload | DraftJobSuccessPayload;

export enum DraftJobPaymentOption {
  Contract = 'CONTRACT',
  CreditCard = 'CREDIT_CARD',
  Invoice = 'INVOICE',
  SavedCreditCard = 'SAVED_CREDIT_CARD',
}

export interface DraftJobProducts {
  productIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  productSetId?: Maybe<Scalars['String']['output']>;
}

export interface DraftJobProductsInput {
  /** List of product id */
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Unique ID of the product set */
  productSetId?: InputMaybe<Scalars['String']['input']>;
}

export enum DraftJobProgress {
  Classify = 'CLASSIFY',
  Manage = 'MANAGE',
  PayAndPost = 'PAY_AND_POST',
  SelectAdType = 'SELECT_AD_TYPE',
  Write = 'WRITE',
}

export interface DraftJobRoleTitle {
  language: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
}

export interface DraftJobRoleTitleInput {
  language: Scalars['String']['input'];
  title: Scalars['String']['input'];
}

export enum DraftJobSalaryCurrency {
  Aud = 'AUD',
  Bdt = 'BDT',
  Cny = 'CNY',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Idr = 'IDR',
  Inr = 'INR',
  Jpy = 'JPY',
  Myr = 'MYR',
  Nzd = 'NZD',
  Php = 'PHP',
  Sgd = 'SGD',
  Thb = 'THB',
  Usd = 'USD',
  Vnd = 'VND',
}

export interface DraftJobSalaryDetails {
  currency?: Maybe<DraftJobSalaryCurrency>;
  displayValue?: Maybe<Scalars['String']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  maximum?: Maybe<Scalars['SafeInt']['output']>;
  minimum?: Maybe<Scalars['SafeInt']['output']>;
  type?: Maybe<SalaryType>;
}

export interface DraftJobSalaryDetailsInput {
  /** The currency for the salary */
  currency?: InputMaybe<DraftJobSalaryCurrency>;
  /** The text to be displayed to show salary to candidates */
  displayValue?: InputMaybe<Scalars['String']['input']>;
  /** Should the salary text be displayed or not */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** The max salary value */
  maximum?: InputMaybe<Scalars['SafeInt']['input']>;
  /** The min salary value */
  minimum?: InputMaybe<Scalars['SafeInt']['input']>;
  /** The pay type (e.g. annual package, hourly rate) */
  type?: InputMaybe<SalaryType>;
}

export interface DraftJobSuccessPayload {
  draft: DraftJob;
  flowType?: Maybe<DraftJobFlowType>;
  /** @deprecated Use draft.status field instead */
  status: DraftStatus;
}

export interface DraftJobVideo {
  url: Scalars['String']['output'];
}

export interface DraftJobVideoInput {
  url: Scalars['String']['input'];
}

export enum DraftJobWritingMode {
  Guided = 'GUIDED',
  Standard = 'STANDARD',
}

export interface DraftJobsInput {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** The maximum number of results per page. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The number of results to skip where the value must be a multiple of `limit`.
   * `null` defaults to an offset of `0`.
   * Example: With a `limit` of `5` results per page, an `offset` of `0` implies the first page of results, and an `offset` of `15` implies the fourth page of results.
   */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** The draft job search term. */
  term?: InputMaybe<Scalars['String']['input']>;
}

export enum DraftStatus {
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Open = 'OPEN',
  Pending = 'PENDING',
}

export interface DrawdownContractDetails {
  /** @deprecated not necessary */
  adType: AdType;
  /** This the ID of the contract being used for drawdown. */
  contractId: Scalars['String']['output'];
  /** A UI consumable name of the contract for consistent naming. */
  contractName: Scalars['String']['output'];
  /** @deprecated Use contractName instead, this will be removed */
  contractType: ContractType;
  /**
   * The current balance of the contract.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  currentValue?: Maybe<DrawdownContractValue>;
  /**
   * The contract is flexible and not unit based.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  dollarBased?: Maybe<Scalars['Boolean']['output']>;
  /**
   * The expiry date of the contract.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  expiryDate?: Maybe<Scalars['String']['output']>;
  /**
   * The remaining contract balance after paying for the current purchase.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  remainingValue?: Maybe<DrawdownContractValue>;
}

export interface DrawdownContractValue {
  dollars?: Maybe<Money>;
}

export interface DrawdownItem {
  amount: Scalars['SafeInt']['output'];
  drawdownContractDetails: DrawdownContractDetails;
}

export enum DrawdownType {
  Balance = 'BALANCE',
  Pack = 'PACK',
  Payable = 'PAYABLE',
}

/** Feature to determine duration of a catalogue product is active. Eg AdBudgetProduct active for 12 months. */
export interface DurationFeature extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  /** Unit of the duration. Eg YEAR, MONTH, DAY */
  unit: DurationUnit;
  /** Size of duration. Eg 60, 12 */
  value: Scalars['Int']['output'];
}

export enum DurationUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Second = 'SECOND',
  Week = 'WEEK',
  Year = 'YEAR',
}

export interface EditJobInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<BrandingInput>;
  draftId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export interface EditJobResponse {
  revisionId?: Maybe<Scalars['String']['output']>;
}

/** Base error interface. */
export interface Error {
  message: Scalars['String']['output'];
}

export interface Expectation {
  /** Expected completed applications for the AdProduct */
  applicationsCompleted?: Maybe<Scalars['Int']['output']>;
  /** Product expectations for the AdProduct */
  content?: Maybe<Array<Maybe<ProductExpectation>>>;
  /** Correlation ID for the expectations */
  correlationId?: Maybe<Scalars['String']['output']>;
}

export interface ExpectedSalaryV2 {
  amount: Array<SalaryAmountV2>;
  currency: Scalars['String']['output'];
}

export interface ExpireJobInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** When the expiryTime is set the job will be updated to expire at that time. This is only available during impersonation sessions */
  expiryTime?: InputMaybe<Scalars['DateTime']['input']>;
  jobId: Scalars['String']['input'];
  /** The reason for expiry - defaults to MANUAL */
  reason?: InputMaybe<ExpireReason>;
}

export interface ExpireJobResponse {
  jobId: Scalars['String']['output'];
}

export enum ExpireReason {
  Fraudulent = 'FRAUDULENT',
  Manual = 'MANUAL',
  Natural = 'NATURAL',
  Noncompliant = 'NONCOMPLIANT',
}

export interface Expiry {
  daysRemaining: Scalars['Int']['output'];
  expiresSoon: Scalars['Boolean']['output'];
  expiryDate: Scalars['String']['output'];
}

export type ExportProfileState =
  | ConnectionPending
  | CvUnavailable
  | DownloadCv
  | ExportProfileWithAtsJob
  | ExportProfileWithoutAtsJob
  | InsufficientCredits
  | ViewProfile;

export interface ExportProfileWithAtsJob {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface ExportProfileWithoutAtsJob {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface Feature {
  /** Unique identifier for the feature */
  key: Scalars['String']['output'];
  /** Reason for the Variant value being returned */
  reason: Scalars['String']['output'];
  /** Variant being returned for the specified Feature Flag, supported values are: Boolean, String, Number, JSON */
  variant: Scalars['JSON']['output'];
}

export enum FiltersAdRatingType {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
  NoRating = 'NO_RATING',
}

export enum Frequency {
  Daily = 'DAILY',
  EveryApplication = 'EVERY_APPLICATION',
  Never = 'NEVER',
  Weekly = 'WEEKLY',
}

export interface GenerateJobContentInput {
  categoryName: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  draftId: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  locationDescription: Scalars['String']['input'];
  priorJobDescription?: InputMaybe<Scalars['String']['input']>;
  subCategoryName: Scalars['String']['input'];
  workType: Scalars['String']['input'];
}

export type GenerateJobContentResponsePayload =
  JobContentGenerationResponseSuccessPayload;

export interface GetBrandingAssetsInput {
  assetType: BrandAssetTypeEnum;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface GetProductSetInput {
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
  /** Specific UseCase for the Frontend Experience e.g. AddonsOnly */
  purchaseContextUseCase?: InputMaybe<PurchaseContextUseCase>;
}

export type GetProductSetResponse =
  | ProductSet
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export interface GetSurchargeAmountInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The currency of the payment. */
  paymentCurrency: Currency;
  /** The total amount to be paid for the payment intent. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
}

export interface GetSurchargeAmountPayload {
  /** The surcharge amount for payment method */
  surchargeAmount: Scalars['SafeInt']['output'];
  /** Determine if the surcharge is applicable for payment method */
  surchargeApplicable: Scalars['Boolean']['output'];
}

export interface GranularLocation {
  nonSpecificLocation?: Maybe<Scalars['String']['output']>;
  specificLocation?: Maybe<Scalars['String']['output']>;
  type: RecommendationType;
}

export enum GuidedDetailsType {
  AboutBusiness = 'ABOUT_BUSINESS',
  Benefits = 'BENEFITS',
  Qualifications = 'QUALIFICATIONS',
  Responsibilities = 'RESPONSIBILITIES',
}

export interface HeaderFooterContext {
  /** Advertiser-specific information. This will be null until account selection is complete. */
  advertiser?: Maybe<AdvertiserHeaderContext>;
  /** Context supplied by Identity Provider (IdP). */
  identityContext?: Maybe<IdentityHeaderContext>;
  /** Profile information about the current user. */
  user?: Maybe<UserHeaderContext>;
}

export interface HeaderFooterContextInput {
  /** Location object. Optional. Supplied by client in order to support customer service view toggling. */
  location?: InputMaybe<WindowLocation>;
}

export interface HelpTip {
  /** Content of a help tip */
  content?: Maybe<Scalars['String']['output']>;
  /** Image to help describe a product */
  image?: Maybe<HelpTipImage>;
}

export interface HelpTipImage {
  /** Alternative text used in the UI */
  altText: Scalars['String']['output'];
  /** Image path where staging/prod url path is prepended */
  url: Scalars['String']['output'];
}

export interface HiddenPrice {
  /** price access level (priceVisibility) if hidePrice is enabled for the hirer */
  priceVisibility: PriceVisibility;
}

export interface Highlight {
  content: Scalars['String']['output'];
}

export interface HirerAccount {
  /** Hirer account activation status */
  activationStatus: AccountActivationStatus;
  /** Seek billing account ID used by CRM */
  billingAccountId?: Maybe<Scalars['String']['output']>;
  /** The billing address of the account */
  billingAddress: PhysicalAddress;
  /** The billing contact of the account */
  billingContact: BillingContact;
  billingStatus?: Maybe<BillingStatus>;
  /** Country-specific identifier like ABN, NZBN */
  businessIdentifier?: Maybe<Scalars['String']['output']>;
  /** Business numbers and identifiers such as ABN, BRN, TIN, etc. */
  businessIdentifiers?: Maybe<BusinessIdentifiers>;
  /** Business name that gets collected on the registration form */
  businessName: Scalars['String']['output'];
  contractsSummary?: Maybe<HirerContractsSummary>;
  /** Indicates if the account is managed by an agency */
  hasAgent?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the LinkOut feature is enabled for the hirer. */
  hasLinkOutEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Show or hide all pricing from hirer */
  hidePrice?: Maybe<Scalars['Boolean']['output']>;
  hirerGroup?: Maybe<HirerGroup>;
  /** Indicates which type hirer belongs to */
  hirerType?: Maybe<HirerType>;
  id: Scalars['String']['output'];
  /** Partner integrations details */
  integrations: Array<Integration>;
  /** Indicates whether the advertiser is yet to be approved in CRM. */
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the account is archived and in deactivated status. */
  isArchived: Scalars['Boolean']['output'];
  /** Indicates whether the Mirroring/Hyperlinks permission is enabled in CRM. */
  isEsmAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser is managed by a Sales Consultant. */
  isManagedAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser would prefer to hide their company name in their job ads. Null if the hirer has not set their preference yet. */
  isPrivateAdvertiser?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the account is approved for Talent Search access. New accounts have to wait 24 hours from when the account is approved before access to Talent Search access is granted. */
  isTalentSearchApproved?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser is marked as a test account in CRM. */
  isTestAccount?: Maybe<Scalars['Boolean']['output']>;
  /** The details of the agency that manages the account if there is one */
  managingAgency?: Maybe<ManagingAgency>;
  /** A country code that indicates which SEEK legal entity the business transacts with and the experience the user will be shown. */
  managingCountry?: Maybe<Scalars['String']['output']>;
  nation?: Maybe<Scalars['String']['output']>;
  /** Indicates the zone the account was originally created in. */
  originZone?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Parent>;
  /** The postal address of the account */
  postalAddress: PhysicalAddress;
  /** To show or hide the different price states */
  priceVisibility?: Maybe<PriceVisibility>;
  /** Indicates if a hirer has a pricing group contract available */
  pricingGroupContractExists?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates which pricing model hirer belongs to, either Fixed or Horizon */
  pricingModel?: Maybe<PricingModel>;
  /** The primary contact of the account */
  primaryContact: PrimaryContact;
  /** @deprecated This field is no longer neccessary due to FAA */
  rules?: Maybe<Array<Maybe<Rule>>>;
  /**
   * Hirer (Advertiser) account status
   * @deprecated Use activationStatus instead
   */
  status: HirerAccountStatus;
  /** The list of users associated with the account */
  users: Array<User>;
}

export interface HirerAccountDetails {
  /** Seek billing account ID used by CRM */
  billingAccountId?: Maybe<Scalars['String']['output']>;
  /** The billing address of the account */
  billingAddress: PhysicalAddress;
  /** The billing contact of the account */
  billingContact: BillingContact;
  /** Country-specific identifier like ABN, NZBN */
  businessIdentifier?: Maybe<Scalars['String']['output']>;
  /** Business name that gets collected on the registration form */
  businessName?: Maybe<Scalars['String']['output']>;
  /** The details of the agency that manages the account if there is one */
  managingAgency: ManagingAgency;
  /** The postal address of the account */
  postalAddress: PhysicalAddress;
  /** The primary contact of the account */
  primaryContact: PrimaryContact;
  /** Hirer (Advertiser) account status */
  status: HirerAccountStatus;
}

export interface HirerAccountEdge {
  membershipStatus: Scalars['String']['output'];
  node: HirerAccount;
  role: Scalars['String']['output'];
}

/** A hirer account to display in the account selection UI. (AccountSelectionV3Response) */
export interface HirerAccountOption {
  /** Status of access right connecting the user to the advertiser. */
  accessRightStatus: AccessRightStatus;
  /** Optional billing ID provided by CRM. */
  billingId?: Maybe<Scalars['String']['output']>;
  /** Child accounts, applicable to agencies. Will be visually nested during account selection. */
  childAccounts: Array<HirerAccountOption>;
  /** SeekWeb Advertiser ID. */
  hirerAccountId: Scalars['ID']['output'];
  /** The Hirer Account Status */
  hirerAccountStatus: AdvertiserStatus;
  /** Name of the advertiser account. */
  name: Scalars['String']['output'];
  /** The URL where the user will be redirected to after selecting the account. */
  redirectUrl: Scalars['String']['output'];
  /** The role of the user on the account - Either "USER" (standard) or "ADMINISTRATOR" */
  role: Role;
}

/** A hirer account to display in the account selection UI. (AccountSelectionV3Response) */
export interface HirerAccountOption_RedirectUrlArgs {
  input?: InputMaybe<RedirectUrlInput>;
}

export enum HirerAccountStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  PendingActivation = 'PENDING_ACTIVATION',
}

export interface HirerAccountUserQueryInput {
  /** Request to get user permissions to account. Can be "ACCESS_DETAILS" */
  include?: InputMaybe<UsersParamsInclude>;
  /** User ID of the user we want to find */
  userId: Scalars['String']['input'];
}

export interface HirerAccountsResult {
  edges: Array<HirerAccountEdge>;
  totalCount: Scalars['Int']['output'];
}

export interface HirerActivitiesByJobInput {
  /**
   * The SEEK ID of the advertiser
   * @deprecated Prefer advertiser ID claim from bearer token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the job */
  jobId: Scalars['String']['input'];
}

export interface HirerActivity {
  /** A list of extra data related the activity */
  activityData: Array<HirerActivityData>;
  /** The time when the activity occurred */
  activityTime: Scalars['DateTime']['output'];
  /** The type of the activity */
  activityType: HirerActivityType;
  id: Scalars['ID']['output'];
  /** Partial list of the  job applications related to the activity, max 3 job applications. */
  partialJobApplicationList: Array<HirerActivityJobApplication>;
  /** First name of the account user who triggers the activity. */
  userFirstName: Scalars['String']['output'];
  /** Last name of the account user who triggers the activity. */
  userLastName: Scalars['String']['output'];
}

export interface HirerActivityConnection {
  edges: Array<HirerActivityEdge>;
  pageInfo: PageInfo;
}

export interface HirerActivityData {
  /** The name of data. eg: noteContent */
  name: Scalars['String']['output'];
  /** The value of data. eg: content of the note */
  value?: Maybe<Scalars['String']['output']>;
}

export interface HirerActivityEdge {
  /** String based cursor for the edge node */
  cursor: Scalars['String']['output'];
  /** Node representing hirer activity */
  node: HirerActivity;
}

export interface HirerActivityJobApplication {
  /** Prospect id of job application in Adcentre */
  adcentreProspectId: Scalars['Int']['output'];
  /** First name of the candidate. */
  candidateFirstName: Scalars['String']['output'];
  /** Last name of the candidate. */
  candidateLastName: Scalars['String']['output'];
}

export enum HirerActivityType {
  NoteAdded = 'NOTE_ADDED',
  NoteDeleted = 'NOTE_DELETED',
}

export interface HirerContractsSummary {
  /** Whether the hirer has active budget contracts (excludes pricing groups) */
  hasAnyActiveBudgetContracts: Scalars['Boolean']['output'];
  /** Whether the hirer has active pricing group contracts (excludes budgets) */
  hasAnyActivePricingGroupContracts: Scalars['Boolean']['output'];
}

export interface HirerDashboardArticle {
  /** Title of the image */
  imageTitle: Scalars['String']['output'];
  /** Image URL */
  imageUrl: Scalars['String']['output'];
  /** Minutes to read article */
  timeToRead: Scalars['Int']['output'];
  /** Title of the article */
  title: Scalars['String']['output'];
  /** Article URL */
  url: Scalars['String']['output'];
}

export interface HirerDashboardBudgetUpsellInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  dataSource?: InputMaybe<Scalars['String']['input']>;
}

export interface HirerDashboardBudgetUpsellResponse {
  /** Max contract discount rate used for display on the hirer dashboard */
  maxContractDiscountRate?: Maybe<Scalars['Float']['output']>;
  /** Set to true when a hirer is eligible for ad budget upsell on the hirer dashboard */
  upsellEligible: Scalars['Boolean']['output'];
}

export interface HirerDashboardHiringAdviceList {
  /** Articles to display on the dashboard */
  articles?: Maybe<Array<Maybe<HirerDashboardArticle>>>;
  count: Scalars['Int']['output'];
}

export interface HirerDashboardTalentSearch {
  premiumTalentSearchHref?: Maybe<Scalars['String']['output']>;
}

export interface HirerExperience {
  /**
   * The hirer control type of this question - one of 'Default', 'Range', 'RangeUpTo', 'RangeAtLeast', 'AdvancedAvailability',
   * this will be null when the question source is custom
   */
  controlType?: Maybe<Scalars['String']['output']>;
  /** Null if the question is not mandatory, defined if the question is mandataory */
  mandatory?: Maybe<MandatoryData>;
  /** Describes if simplified answers were selected - either "simplifiedAnswers" or "answers" */
  selectionType: Scalars['String']['output'];
  /** The simplified answers for the question - present only if selectionType is "simplifiedAnswers" */
  simplifiedAnswers?: Maybe<Array<SimplifiedAnswers>>;
}

export enum HirerGroup {
  Advertiser = 'ADVERTISER',
  Agent = 'AGENT',
  RecruitmentSoftwareProvider = 'RECRUITMENT_SOFTWARE_PROVIDER',
}

export interface HirerJobAccess {
  correctHirerAccountId: Scalars['ID']['output'];
}

export interface HirerNotificationCategory {
  description: Scalars['String']['output'];
  id: HirerNotificationCategoryType;
  label: Scalars['String']['output'];
}

export enum HirerNotificationCategoryType {
  HirerAdviceInsights = 'HIRER_ADVICE_INSIGHTS',
  HirerJobAdPosting = 'HIRER_JOB_AD_POSTING',
  HirerMobile = 'HIRER_MOBILE',
  HirerPartnerUpdates = 'HIRER_PARTNER_UPDATES',
  HirerProductSolutions = 'HIRER_PRODUCT_SOLUTIONS',
  HirerSurveyCompetitions = 'HIRER_SURVEY_COMPETITIONS',
}

export interface HirerNotificationChannel {
  id: HirerNotificationChannelType;
  label: Scalars['String']['output'];
}

export enum HirerNotificationChannelType {
  Email = 'EMAIL',
  Mobile = 'MOBILE',
}

export interface HirerNotificationPreference {
  product: HirerNotificationProduct;
  subscriptions: Array<HirerNotificationSubscription>;
}

export interface HirerNotificationPreferenceCategory {
  category: HirerNotificationCategory;
  preferences: Array<HirerNotificationPreference>;
}

export enum HirerNotificationPreferenceUpdateReason {
  PreferenceUi = 'PREFERENCE_UI',
}

export interface HirerNotificationProduct {
  action?: Maybe<HirerNotificationProductAction>;
  description: Scalars['String']['output'];
  id: HirerNotificationProductType;
  label: Scalars['String']['output'];
}

export interface HirerNotificationProductAction {
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
}

export interface HirerNotificationProductInput {
  productId: HirerNotificationProductType;
  subscriptions: Array<HirerNotificationSubscriptionInput>;
}

export enum HirerNotificationProductType {
  MarketingAdviceInsights = 'MARKETING_ADVICE_INSIGHTS',
  MarketingJobAdPosting = 'MARKETING_JOB_AD_POSTING',
  MarketingMobile = 'MARKETING_MOBILE',
  MarketingPartnerUpdates = 'MARKETING_PARTNER_UPDATES',
  MarketingProductUpdates = 'MARKETING_PRODUCT_UPDATES',
  MarketingSurveyResearch = 'MARKETING_SURVEY_RESEARCH',
}

export interface HirerNotificationSubscription {
  channel: HirerNotificationChannel;
  id: Scalars['String']['output'];
  subscribed: Scalars['Boolean']['output'];
}

export interface HirerNotificationSubscriptionInput {
  channelId: HirerNotificationChannelType;
  subscribed: Scalars['Boolean']['input'];
}

export interface HirerNotificationUpdatePreferenceInput {
  locale: Scalars['String']['input'];
  reason: HirerNotificationPreferenceUpdateReason;
  updates: Array<HirerNotificationProductInput>;
  zone: Scalars['String']['input'];
}

export enum HirerQuestionType {
  FreeText = 'FreeText',
  SingleSelect = 'SingleSelect',
}

export enum HirerType {
  Bpo = 'BPO',
  Corporate = 'CORPORATE',
  Government = 'GOVERNMENT',
  JoraLocal = 'JORA_LOCAL',
  Recruiter = 'RECRUITER',
  Sme = 'SME',
  SubscriptionPartner = 'SUBSCRIPTION_PARTNER',
}

export interface HirerUserDetails {
  /** Email address of the hirer user */
  email: Scalars['String']['output'];
  /** Family name of the hirer user (formerly known as "Last Name" in ANZ systems) */
  familyName: Scalars['String']['output'];
  /** Full name of the hirer user, ie "givenName familyName". */
  fullName: Scalars['String']['output'];
  /** Given name of the hirer user (formerly known as "First Name" in ANZ Systems) */
  givenName: Scalars['String']['output'];
  /** The id of the hirer user (formerly known as "userId") */
  hirerUserId: Scalars['String']['output'];
  /** Permissions of the hirer user on the hirer account */
  permissions: UserPermissions;
  /** Hirer User status */
  status: HirerUserStatus;
}

export interface HirerUserEmailFoundPayloadV2 {
  /** Email address of the hirer user */
  email: Scalars['String']['output'];
  /** ID used to uniquely identify the hirer user */
  hirerUserId: Scalars['String']['output'];
  /** Hirer User status */
  status: HirerUserStatus;
}

export interface HirerUserEmailInputV2 {
  /** Email address of the hirer user */
  email: Scalars['String']['input'];
}

export interface HirerUserEmailNotFoundPayloadV2 {
  /**
   * message to return when we can't find the email in the Hirer Users system.
   * This is not a failure response as it is entirely possible to search for an
   * email that does not exist
   */
  message: Scalars['String']['output'];
}

export type HirerUserEmailPayloadV2 =
  | HirerUserEmailFoundPayloadV2
  | HirerUserEmailNotFoundPayloadV2
  | ResponseError;

export interface HirerUserQueryInput {
  /** ID used to uniquely identify the hirer user */
  hirerUserId: Scalars['String']['input'];
}

export enum HirerUserStatus {
  /** The User is verified and not archived (DEACTIVATED). */
  Active = 'ACTIVE',
  /** The User has been archived (DEACTIVATED). */
  Deactivated = 'DEACTIVATED',
  /** The User is not verified and was created by a team member (add to account). */
  Pending = 'PENDING',
  /** The User has requested their data to be removed. */
  Redacted = 'REDACTED',
  /** The User is not verified and was created by first time account registration. */
  Unverified = 'UNVERIFIED',
}

export interface Identity {
  /** Operator making requests on behalf of user. */
  actor?: Maybe<Actor>;
  /**
   * Indicates that the user is CS operator, regardless of whether they're actively impersonating.
   * Either true or undefined.
   */
  allowImpersonation?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates that the CS operator has enabled administrator mode. Either true or undefined. */
  showCustomerServiceView?: Maybe<Scalars['Boolean']['output']>;
  /** User requesting access to a resource. */
  subject: Subject;
}

export interface IdentityHeaderContext {
  /** Details of the cs user making requests on behalf of user. */
  act?: Maybe<ActorContext>;
  /** Email address of the user. */
  email: Scalars['String']['output'];
}

export interface IdentityHeaderContext_ActArgs {
  input?: InputMaybe<HeaderFooterContextInput>;
}

export interface Image {
  /** Description of an image */
  description: Scalars['String']['output'];
  /** Image path where staging/prod url path is prepended */
  url: Scalars['String']['output'];
}

export interface Inclusion {
  content: Scalars['String']['output'];
  helpTip?: Maybe<InclusionHelpTip>;
  icon?: Maybe<InclusionIcon>;
  styledContents?: Maybe<Array<DisplayStyledContent>>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface InclusionHelpTip {
  content: Scalars['String']['output'];
  contents?: Maybe<Array<InclusionHelpTipContent>>;
  image?: Maybe<InclusionHelpTipImage>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface InclusionHelpTipContent {
  value: Scalars['String']['output'];
}

export interface InclusionHelpTipImage {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
}

export interface InclusionIcon {
  id?: Maybe<Scalars['String']['output']>;
  tone?: Maybe<Scalars['String']['output']>;
}

export interface InitiateAccessCvV2Input {
  advertiserEmail: Scalars['String']['input'];
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
}

export interface InitiateAccessProfileAndCvV2Input {
  advertiserEmail: Scalars['String']['input'];
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
}

export interface InitiateAccessProfileV2Input {
  advertiserEmail: Scalars['String']['input'];
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
}

export interface InitiateConnectionPayload {
  connectionId: Scalars['String']['output'];
}

export type InitiateConnectionResponse =
  | InitiateConnectionPayload
  | ResponseError
  | TalentSearchConnectionResponseError;

export interface InitiateExportProfileV2Input {
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
}

export interface InitiateExportProfileWithAtsJobV2Input {
  externalReferenceId: Scalars['String']['input'];
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
}

export interface InitiateSendJobV2Input {
  jobContext?: InputMaybe<JobContext>;
  jobId: Scalars['ID']['input'];
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
}

export interface InitiateSendMessageV2Input {
  advertiserEmail: Scalars['String']['input'];
  advertiserFirstName: Scalars['String']['input'];
  advertiserLastName: Scalars['String']['input'];
  advertiserPhone?: InputMaybe<Scalars['String']['input']>;
  advertiserTitle: Scalars['String']['input'];
  body: Scalars['String']['input'];
  /** @deprecated No longer required */
  isAtsHirer?: InputMaybe<Scalars['Boolean']['input']>;
  jobContext?: InputMaybe<JobContext>;
  origin: TalentSearchConnectionOrigin;
  profileId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
}

export interface InsufficientCredits {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface Integration {
  partner: IntegrationPartner;
  relationships: Array<IntegrationRelationship>;
}

export interface IntegrationConnection {
  edges: Array<IntegrationEdge>;
  totalCount: Scalars['Int']['output'];
}

export interface IntegrationEdge {
  node: Integration;
}

export interface IntegrationPartner {
  adPerformancePanel: Scalars['Boolean']['output'];
  configurableRelationships: Array<IntegrationRelationshipType>;
  description?: Maybe<Scalars['String']['output']>;
  hasApacIntegration?: Maybe<Scalars['Boolean']['output']>;
  hasMiddlewareRequirement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  jobAdPreview: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  newPartner: Scalars['Boolean']['output'];
  partnerBlurbEN?: Maybe<Scalars['String']['output']>;
  partnerBlurbID?: Maybe<Scalars['String']['output']>;
  partnerBlurbTH?: Maybe<Scalars['String']['output']>;
  releaseNotes?: Maybe<Scalars['String']['output']>;
  releaseNotesImageUrl?: Maybe<Scalars['String']['output']>;
  releaseNotesStatus?: Maybe<Scalars['String']['output']>;
  screeningQuestions: Scalars['Boolean']['output'];
  siteUrl?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  tier: IntegrationPartnerTier;
  type: Scalars['String']['output'];
}

export enum IntegrationPartnerTier {
  ConnectedMiddleware = 'CONNECTED_MIDDLEWARE',
  ConnectedPartners = 'CONNECTED_PARTNERS',
  GoldPartners = 'GOLD_PARTNERS',
  KeyMiddlewarePartners = 'KEY_MIDDLEWARE_PARTNERS',
  PlatinumPartners = 'PLATINUM_PARTNERS',
  SilverPartners = 'SILVER_PARTNERS',
}

export interface IntegrationRelationship {
  id: Scalars['ID']['output'];
  relationshipType: IntegrationRelationshipType;
}

export interface IntegrationRelationshipType {
  name: Scalars['String']['output'];
  typeCode: IntegrationRelationshipTypeEnum;
}

export enum IntegrationRelationshipTypeEnum {
  ApplicationExport = 'APPLICATION_EXPORT',
  ApplicationPrefill = 'APPLICATION_PREFILL',
  JobPosting = 'JOB_POSTING',
  ProactiveSourcing = 'PROACTIVE_SOURCING',
}

export interface InteractedJob {
  location?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface InteractedJob_LocationArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface InvalidInputPayload {
  /** Reason of invalid used input. i.e. 'Validation error: logo is required to create a brand' */
  reason?: Maybe<Scalars['String']['output']>;
}

export interface Invoice {
  /** The ID of the invoice. */
  id: Scalars['ID']['input'];
  /** The status of the invoice. */
  invoiceStatus: InvoiceStatus;
}

export interface InvoiceData {
  invoices?: Maybe<Array<Maybe<InvoiceItem>>>;
  loadMoreCursor?: Maybe<Scalars['String']['output']>;
}

/** Error for invalid, expired, or not found invoices. */
export interface InvoiceError extends Error {
  message: Scalars['String']['output'];
}

export interface InvoiceFiltersInput {
  cursor?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  invoiceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvoiceStatus>;
}

export interface InvoiceInput {
  amount: Scalars['SafeInt']['input'];
  reference: Scalars['String']['input'];
}

export interface InvoiceItem {
  amount: Scalars['Float']['output'];
  amountPayable?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  documentDate: Scalars['String']['output'];
  documentStatus: InvoiceStatus;
  documentType: Scalars['String']['output'];
  dueDate: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  invoiceStatus: Scalars['String']['output'];
  migrated: Scalars['Boolean']['output'];
  netAmount: Scalars['Float']['output'];
  netPayable?: Maybe<Scalars['Float']['output']>;
  paidAmount?: Maybe<Scalars['Float']['output']>;
  payments?: Maybe<Array<Maybe<InvoicePayment>>>;
  taxAmount: Scalars['Float']['output'];
  taxPayable?: Maybe<Scalars['Float']['output']>;
  taxType: Scalars['String']['output'];
}

export interface InvoiceOrderNotFoundPayload {
  /** Requested OrderId could not be found. */
  reason?: Maybe<Scalars['String']['output']>;
}

export interface InvoiceOrderSuccessPayload {
  responseStatus: Scalars['String']['output'];
}

export interface InvoicePayment {
  ccLast4Digit: Scalars['String']['output'];
  chequeNo: Scalars['String']['output'];
  eftReference: Scalars['String']['output'];
  paymentAmount: Scalars['Float']['output'];
  paymentCardType: Scalars['String']['output'];
  paymentDate: Scalars['String']['output'];
  paymentDoc?: Maybe<Scalars['String']['output']>;
  paymentMethod: Scalars['String']['output'];
  paymentReference: Scalars['String']['output'];
}

export enum InvoiceStatus {
  /** The invoice is due. */
  Due = 'due',
  /** The invoice is paid. */
  Paid = 'paid',
  /** The invoice is pending. */
  Pending = 'pending',
}

export interface Job {
  /** @deprecated Use `productDisplayName` instead and if empty fallback to `adType` */
  adType?: Maybe<JobAdType>;
  /** A reference provided by the advertiser to facilitate searching in job lists. */
  advertiserJobReference?: Maybe<Scalars['String']['output']>;
  applicationFormUrl?: Maybe<Scalars['String']['output']>;
  billableApplicants: BillableApplicantsResponse;
  branding?: Maybe<Brand>;
  canBeRefreshed: Scalars['Boolean']['output'];
  canBeUpgraded: Scalars['Boolean']['output'];
  canShowHomeLocationFilterInCandidateManagement: Scalars['Boolean']['output'];
  canShowNationalityFilterInCandidateManagement: Scalars['Boolean']['output'];
  candidatesCount?: Maybe<Scalars['Int']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was created.
   * The job is only accessible to candidates once it has a `listingDate`.
   */
  createdDate: Scalars['String']['output'];
  /** Number of days remaining until the job gets expired, if the job end date is in the future. */
  daysRemaining?: Maybe<Scalars['Int']['output']>;
  /**
   * Expiry date/time in UTC (ISO 8601 format).
   * This may be null if the job is not live yet.
   */
  expiryDate?: Maybe<Scalars['String']['output']>;
  /**
   * The job ID.
   * This should contain the same values as `jobId` and will eventually replace the `jobId` field.
   */
  id: Scalars['String']['output'];
  /** Indicates whether this is a private ad */
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  jobDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `id` instead */
  jobId: Scalars['String']['output'];
  jobSummary?: Maybe<Scalars['String']['output']>;
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was searchable and accessible to candidates.
   * This may be later than `createdDate` as a hirer may create the job but complete the payment at a later date.
   */
  listingDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated listingId is deprecated and use `id` instead */
  listingId: ListingIdResponse;
  locationId?: Maybe<Scalars['String']['output']>;
  /**
   * Location details resolved from the `locationId`
   * While the schema allows multiple locations, only one will be returned for AU/NZ.
   */
  locations: Array<Location>;
  maxSalary?: Maybe<Scalars['SafeInt']['output']>;
  minSalary?: Maybe<Scalars['SafeInt']['output']>;
  newCandidatesCount?: Maybe<Scalars['Int']['output']>;
  /** Details of the user who created the job. */
  owner?: Maybe<HirerUserDetails>;
  payPerPlacementEligibility: PayPerPlacementEligibilityResponse;
  payType?: Maybe<Scalars['String']['output']>;
  performanceRating?: Maybe<AnalyticsAdRating>;
  /** Hirer's phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  placement: JobPlacementResponse;
  placementV2: JobPlacementV2Response;
  /** @deprecated positionId is deprecated and use `id` instead */
  positionId: PositionIdResponse;
  /**
   * The channel the job was posted from. This does not refer to which underlying system used to post the job.
   * @deprecated Use `postType`
   */
  postChannel?: Maybe<PostChannel>;
  /** The postType from hirer jobs api, ie Direct or Indirect. */
  postType: PostType;
  productAddOnCodes?: Maybe<Array<Scalars['String']['output']>>;
  productCode?: Maybe<Scalars['String']['output']>;
  productDisplayName?: Maybe<Scalars['String']['output']>;
  /** Product features belonging to the job */
  productFeatures?: Maybe<Array<CatalogueProductFeature>>;
  questionnaireUrl?: Maybe<Scalars['String']['output']>;
  referenceCheckBalance: JobReferenceCheckBalanceResponse;
  salaryCurrency?: Maybe<JobSalaryCurrency>;
  salaryText?: Maybe<Scalars['String']['output']>;
  status: JobStatus;
  subCategoryId?: Maybe<Scalars['String']['output']>;
  subCategoryName?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
  workArrangements?: Maybe<Array<WorkArrangement>>;
  workType?: Maybe<WorkType>;
}

export interface JobAd {
  adRating?: Maybe<AdRatingType>;
  addOns: Array<Maybe<Scalars['String']['output']>>;
  applications: Scalars['Int']['output'];
  groupKeyApac: Scalars['String']['output'];
  hidePrice: Scalars['Boolean']['output'];
  isLatestGroupApac: Scalars['Boolean']['output'];
  isRefresh: Scalars['Boolean']['output'];
  isRepost: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  locationLabel: Scalars['String']['output'];
  otherBudgetAmount: Money;
  productDisplayName: Scalars['String']['output'];
  status: AdUsageAdStatus;
  totalNetAmount: Money;
  userFullName?: Maybe<Scalars['String']['output']>;
}

export enum JobAdBookingCountryCode {
  Au = 'AU',
  Hk = 'HK',
  Id = 'ID',
  My = 'MY',
  Nz = 'NZ',
  Ph = 'PH',
  Sg = 'SG',
  Th = 'TH',
}

export interface JobAdDetails {
  locationId: Scalars['String']['input'];
  roleTitle: Scalars['String']['input'];
  subClassificationId: Scalars['String']['input'];
}

/** AdProductUpgrade feature that indicates the ability for editing the details of an existing ad. */
export interface JobAdEditFeature extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface JobAdListResponse {
  count: Scalars['Int']['output'];
  hasMultipleCurrencies: Scalars['Boolean']['output'];
  jobAds?: Maybe<Array<Maybe<JobAd>>>;
  lastUpdated: Scalars['DateTime']['output'];
}

export interface JobAdListWidgetInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  pageNumber: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
}

export interface JobAdRevisionAttributes {
  /** Id of the existing Job Ad. */
  jobId: Scalars['String']['input'];
  /** Revision id of the existing Job Ad. */
  revisionId: Scalars['String']['input'];
}

export enum JobAdType {
  Branded = 'BRANDED',
  BrandedUpgrade = 'BRANDED_UPGRADE',
  Classic = 'CLASSIC',
  Concierge = 'CONCIERGE',
  Guaranteed = 'GUARANTEED',
  Premium = 'PREMIUM',
}

export interface JobConnection {
  /** The list of `HirerJob` edges for the current page. */
  edges: Array<JobEdge>;
  /**
   * The total number of jobs across all pages.
   * The UI can use the `totalCount` and input `limit` to compute the total page count.
   */
  totalCount: Scalars['Int']['output'];
}

export interface JobContentGenerationResponseSuccessPayload {
  id: Scalars['String']['output'];
  jobDescription: Scalars['String']['output'];
  jobSummary: Scalars['String']['output'];
}

export interface JobContext {
  jobId: Scalars['ID']['input'];
}

/**
 * Providing `null` for a field will reset it.
 * This doesn't apply to fields that are required by Job Services such as `categories`, `locations`, and `title`. They will always maintain their original value unless a new value is provided.
 */
export interface JobDetailsInput {
  /** A reference provided by the advertiser to facilitate searching in draft job lists. */
  advertiserJobReference?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Updated branding info */
  branding?: InputMaybe<BrandingInput>;
  /** Updated category and subcategory */
  categories?: InputMaybe<CategoriesInput>;
  /** Indicates whether this is a private ad */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Updated job description */
  jobDescription?: InputMaybe<Scalars['String']['input']>;
  /** Updated key selling points */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Updated job location(s) */
  locations?: InputMaybe<Array<LocationInput>>;
  /** Updated salary information (incl. currency and range) */
  salary?: InputMaybe<SalaryInput>;
  /** Updated job summary */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Updated job title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Updated video url */
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Updated work arrangements AKA workplace options */
  workArrangements?: InputMaybe<Array<WorkArrangement>>;
  /** Updated work types */
  workTypes?: InputMaybe<Array<WorkType>>;
}

export interface JobEdge {
  /** A `JobNode` from the pagination query. */
  node: JobNode;
}

export interface JobInput {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The job ID. */
  jobId: Scalars['String']['input'];
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /**
   * The AdCentre position ID
   * @deprecated Use `jobId` instead
   */
  positionId?: InputMaybe<Scalars['ID']['input']>;
}

export type JobNode = DraftJob | Job;

export interface JobPlacement {
  shouldShowPlacementDialog: Scalars['Boolean']['output'];
}

export enum JobPlacementPostType {
  AdPostingApi = 'AdPostingApi',
  Direct = 'Direct',
}

export type JobPlacementResponse = JobPlacementResponseSuccess | ResponseError;

export interface JobPlacementResponseSuccess {
  result: JobPlacement;
}

export enum JobPlacementStatus {
  Filled = 'FILLED',
  InProgress = 'IN_PROGRESS',
  NotFilled = 'NOT_FILLED',
}

export interface JobPlacementV2 {
  canShowPlacementPrompt: Scalars['Boolean']['output'];
  status?: Maybe<JobPlacementStatus>;
}

export type JobPlacementV2Response =
  | JobPlacementV2ResponseSuccess
  | ResponseError;

export interface JobPlacementV2ResponseSuccess {
  result: JobPlacementV2;
}

export interface JobReference {
  id: Scalars['String']['output'];
  revision: Scalars['ID']['output'];
}

export interface JobReferenceCheckBalance {
  availableBalance: Scalars['Int']['output'];
  totalBalance: Scalars['Int']['output'];
}

export type JobReferenceCheckBalanceResponse =
  | JobReferenceCheckBalanceResponseSuccess
  | ResponseError;

export interface JobReferenceCheckBalanceResponseSuccess {
  result?: Maybe<JobReferenceCheckResult>;
}

export interface JobReferenceCheckExpired {
  _empty?: Maybe<Scalars['String']['output']>;
}

export type JobReferenceCheckResult =
  | JobReferenceCheckBalance
  | JobReferenceCheckExpired;

export type JobResponse =
  | CorrectUserIncorrectHirerResponseSuccess
  | JobResponseSuccess
  | ResponseError;

export interface JobResponseSuccess {
  result: Job;
}

export enum JobSalaryCurrency {
  Aud = 'AUD',
  Bdt = 'BDT',
  Cny = 'CNY',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Idr = 'IDR',
  Inr = 'INR',
  Jpy = 'JPY',
  Myr = 'MYR',
  Nzd = 'NZD',
  Php = 'PHP',
  Sgd = 'SGD',
  Thb = 'THB',
  Usd = 'USD',
  Vnd = 'VND',
}

export interface JobSent {
  sentDate: Scalars['String']['output'];
}

export enum JobStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  PendingAccountApproval = 'PENDING_ACCOUNT_APPROVAL',
  Suspended = 'SUSPENDED',
}

export enum JobStatusFilter {
  /** The job has been posted and is active. */
  Active = 'ACTIVE',
  /** The job has been blocked by customer service or an automated process. */
  Blocked = 'BLOCKED',
  /** The job has expired. */
  Expired = 'EXPIRED',
  /** The account which posted the job has not yet been approved. */
  PendingAccountApproval = 'PENDING_ACCOUNT_APPROVAL',
  /** The job has been suspended. */
  Suspended = 'SUSPENDED',
}

export interface JobTitleOption {
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
}

export interface JobsInput {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** The page number to fetch. */
  page: Scalars['Int']['input'];
  /** The number of jobs to fetch in a single page. */
  pageSize: Scalars['Int']['input'];
  /**
   * Fetch only open or closed jobs.
   * When `null`, open and closed jobs are returned.
   */
  statuses?: InputMaybe<Array<JobStatusFilter>>;
  /** Text for which to search in fields such as job titles and reference. */
  textSearch?: InputMaybe<Scalars['String']['input']>;
}

export type JobsResponse = JobsResponseSuccess | ResponseError;

export interface JobsResponseSuccess {
  result: JobConnection;
}

export interface LastUpdatedResponse {
  lastUpdated: Scalars['DateTime']['output'];
}

export interface LayoutDisplay {
  content?: Maybe<LayoutDisplayContent>;
  format: Scalars['String']['output'];
  productCap: ProductCap;
  sectionOrder: Array<Scalars['String']['output']>;
  sortProductIds: Array<Scalars['ID']['output']>;
}

export interface LayoutDisplayContent {
  comparatives?: Maybe<ComparativeSections>;
}

/** This specific type is used for order summary */
export interface LineItemProduct extends Product {
  /** Description of the line item */
  description: Scalars['String']['output'];
  /** Name used when displaying this product as a line item of a purchase */
  name: Scalars['String']['output'];
  /** Type of product purchased */
  productType: ProductType;
}

export interface Link {
  href: Scalars['String']['output'];
  rel: Scalars['String']['output'];
}

export type ListingIdResponse = ListingIdResponseSuccess | ResponseError;

export interface ListingIdResponseSuccess {
  result: Scalars['ID']['output'];
}

export interface LocalisedContactInfo {
  /** Business hours. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  businessHours?: Maybe<Scalars['String']['output']>;
  /** Contact number. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  contactNumber?: Maybe<Scalars['String']['output']>;
  /** Contact us link. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  contactUsLink?: Maybe<Scalars['String']['output']>;
  /** Help centre link. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  helpCentreLink?: Maybe<Scalars['String']['output']>;
}

export interface LocalisedContactInfoInput {
  locale: Scalars['String']['input'];
  site: Scalars['String']['input'];
}

export interface Location {
  country: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSeekMarketCountry: Scalars['Boolean']['output'];
  parent?: Maybe<Location>;
  type: Scalars['String']['output'];
}

export interface Location_DescriptionArgs {
  input?: InputMaybe<LocationDescriptionInput>;
}

export interface Location_DisplayDescriptionArgs {
  input?: InputMaybe<LocationDisplayDescriptionInput>;
}

export enum LocationContext {
  /** Uses JoB Posting Filter logic. This will be the default if context is not specified. */
  JobPosting = 'JOB_POSTING',
  /** Uses Talent Search Filter logic. */
  TalentSearch = 'TALENT_SEARCH',
}

export interface LocationDescriptionInput {
  displayCountry: Scalars['String']['input'];
  language: Scalars['String']['input'];
}

export interface LocationDisplayDescriptionInput {
  displayCountry?: InputMaybe<Scalars['String']['input']>;
  displayFormat?: InputMaybe<LocationDisplayFormat>;
  language: Scalars['String']['input'];
}

export enum LocationDisplayFormat {
  Long = 'LONG',
  Short = 'SHORT',
}

export interface LocationInput {
  country: Scalars['String']['input'];
  description: Scalars['String']['input'];
  displayDescription: Scalars['String']['input'];
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
}

export interface LocationOption {
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
}

export interface LocationSearchResult {
  country: Scalars['String']['output'];
  countryId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated unified location service doesn't return this field */
  matchedSearchString?: Maybe<Array<MatchedSearchString>>;
  parents: Array<Maybe<LocationSearchResult>>;
  type: Scalars['String']['output'];
}

export interface LogoStrategies {
  jdpLogo: Scalars['String']['output'];
  serpLogo: Scalars['String']['output'];
}

export interface LowApply {
  averageConversionRate: Scalars['Float']['output'];
  conversionRate: Scalars['Float']['output'];
  type: RecommendationType;
}

export interface LowClick {
  averageConversionRate: Scalars['Float']['output'];
  conversionRate: Scalars['Float']['output'];
  type: RecommendationType;
}

export interface LowSalary {
  benchmarkLocationLabel: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  normalisedRoleTitle: Scalars['String']['output'];
  salaryRangeMax: Scalars['Float']['output'];
  salaryRangeMin: Scalars['Float']['output'];
  salaryType: AnalyticsSalaryType;
  suggestedAverageRangeMax?: Maybe<Scalars['Int']['output']>;
  suggestedAverageRangeMin?: Maybe<Scalars['Int']['output']>;
  suggestedRangeMax?: Maybe<Scalars['Int']['output']>;
  suggestedRangeMin?: Maybe<Scalars['Int']['output']>;
  type: RecommendationType;
}

export interface Maintenance {
  disableMessage?: Maybe<DisableMessageType>;
  disablePayment?: Maybe<Scalars['Boolean']['output']>;
  paymentMedium?: Maybe<Array<Maybe<PaymentMediumType>>>;
  paymentMethod: Scalars['String']['output'];
  showMaintenance?: Maybe<Scalars['Boolean']['output']>;
}

export interface ManagingAgency {
  /** Id of the agency. */
  advertiserId?: Maybe<Scalars['String']['output']>;
}

export interface MandatoryData {
  /** Tooltip-friendly answer text to display for mandatory questions */
  mandatoryAnswerText: Array<Scalars['String']['output']>;
}

export interface MarkApplicationsReadInput {
  prospectIds: Array<Scalars['ID']['input']>;
}

export interface MarkApplicationsReadPayload {
  success: Scalars['Boolean']['output'];
}

export interface MarketInsightMonth {
  calendarMonth: Scalars['String']['output'];
  count: Scalars['Int']['output'];
}

export interface MarketInsightsTitleResponse {
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
}

export interface MarketInsightsTitleWidgetInput {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface MarketPerformanceResponse {
  highAds: Scalars['Int']['output'];
  lowAds: Scalars['Int']['output'];
  noRatingAds: Scalars['Int']['output'];
  normalAds: Scalars['Int']['output'];
  totalAds: Scalars['Int']['output'];
}

export interface MarketPerformanceWidgetInput {
  filters: AdUsageQueryFilters;
}

export interface MatchedQuality {
  displayLabel: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isTop: Scalars['Boolean']['output'];
  relevanceScore: Scalars['Float']['output'];
}

export interface MatchedSearchString {
  matches?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  text: Scalars['String']['output'];
}

export type MaxBillableApplicantsResponse =
  | MaxBillableApplicantsResponseSuccess
  | ResponseError;

export interface MaxBillableApplicantsResponseSuccess {
  result: Scalars['Int']['output'];
}

export interface MaximiseBranding extends TipToImproveAds {
  adCount: Scalars['Int']['output'];
  bulletPointsUsed: Scalars['Boolean']['output'];
  coverImageUsed: Scalars['Boolean']['output'];
  logoUsed: Scalars['Boolean']['output'];
  type: TipsToImproveAdsType;
}

export interface MissingAdvertiserIdClaimError extends Error {
  message: Scalars['String']['output'];
}

export interface MissingUserIdClaimError extends Error {
  message: Scalars['String']['output'];
}

export interface Money {
  currency: Currency;
  value: Scalars['SafeInt']['output'];
}

export interface MonthlyTrendsProductDisplayName {
  items?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
  month: Scalars['String']['output'];
}

export interface MostAdsByPerformanceCategory {
  classification: MostAdsByPerformanceEntity;
  user: MostAdsByPerformanceEntity;
}

export interface MostAdsByPerformanceEntity {
  label?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
}

export interface MostAdsByPerformanceResponse {
  high: MostAdsByPerformanceCategory;
  low: MostAdsByPerformanceCategory;
}

export interface MostAdsByPerformanceWidgetInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
}

export interface Mutation {
  _empty?: Maybe<Scalars['String']['output']>;
  addItemToCart?: Maybe<AddItemToCartResultPayload>;
  addOrRemoveProfileToTalentPools: Scalars['String']['output'];
  addUserToAccount?: Maybe<AddUserToAccountResponse>;
  applyCoverToAllBrands?: Maybe<Array<Maybe<Brand>>>;
  auditSuccessfulLogin: AuditSuccessfulLoginPayload;
  clearDefaultBrand?: Maybe<Brand>;
  completeAdditionalAdvertiserRegistration: SecureLinkResponse;
  completeUserRegistration: SecureLinkResponse;
  /** Request to confirm an invoice order. Returns request status. */
  confirmInvoiceOrder?: Maybe<ConfirmInvoiceOrderPayload>;
  /** Creates a Payment Intent and provides the paymentLink where user can complete the payment and paymentToken where client can check the payment status */
  create2C2PPaymentIntent: Create2c2pPaymentIntentPayload;
  /** Creates a Payment Intent for invoices and provides the paymentLink where user can complete the payment and paymentToken where client can check the payment status */
  create2C2PPaymentIntentForInvoices: Create2c2pPaymentIntentPayload;
  createApplicationNote: ApplicationNote;
  createBrand?: Maybe<CreateBrandPayload>;
  createCustomQuestion: CreateCustomQuestionOutputUnion;
  createDraftJob: CreateDraftJobPayload;
  createJob?: Maybe<CreateJobResponse>;
  createJobRevision: CreateJobRevisionPayload;
  /** Request to create a order from selected items. Returns Order. */
  createOrder?: Maybe<CreateOrderPayload>;
  /** Request to create an order with a promotion code applied. Returns Order. */
  createOrderWithPromotion?: Maybe<CreateOrderWithPromotionPayload>;
  createProductSetForAdEditing: CreateProductSetForAdEditingPayload;
  createProductSetForAdPosting: CreateProductSetForAdPostingPayload;
  createProductSetForAdRefresh: CreateProductSetForAdRefreshPayload;
  createProductSetForAdUpgradePurchase: CreateProductSetForAdUpgradePurchasePayload;
  createProductSetForBudgetsPurchaseForHirer: CreateProductSetForBudgetsPurchaseForHirerPayload;
  createProductSetForBudgetsPurchaseForVisitor: CreateProductSetForBudgetsPurchaseForVisitorPayload;
  /** createProductSet mutation used when posting a job to multiple locations */
  createProductSetForMultiLocation: CreateProductSetForMultiLocationPayload;
  createProductSetForPriceChecker: CreateProductSetForAdPostingPayload;
  createProductSetForPriceCheckerPreview: CreateProductSetForAdPostingPayload;
  createQuestionnaire?: Maybe<CreateQuestionnaireResponse>;
  createSavedSearch?: Maybe<CreateSavedSearchPayload>;
  /** Creates a Payment Intent and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createStripePaymentIntent: CreateStripePaymentIntentPayload;
  /** Creates a Payment Intent for invoices and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createStripePaymentIntentForInvoice: CreateStripePaymentIntentPayload;
  createTalentPool: TalentSearchPool;
  deleteBrand?: Maybe<Brand>;
  deleteBrandingAsset?: Maybe<DeleteBrandingAssetPayload>;
  deleteDraftJob?: Maybe<DeleteDraftJobPayload>;
  deleteSavedSearch?: Maybe<DeleteSavedSearchPayload>;
  /** To delete a saved credit card using provided payment method id. */
  deleteStripeSavedCreditCard?: Maybe<DeleteStripeSavedCreditCardPayload>;
  deleteTalentPool: Scalars['String']['output'];
  deleteUploadedSupportDoc: DeleteUploadedSupportDocResponse;
  editJob?: Maybe<EditJobResponse>;
  expireJob?: Maybe<ExpireJobResponse>;
  generateJobContent: GenerateJobContentResponsePayload;
  initiateAccessCvV2: InitiateConnectionResponse;
  initiateAccessProfileAndCvV2: InitiateConnectionResponse;
  initiateAccessProfileV2: InitiateConnectionResponse;
  initiateExportProfileV2: InitiateConnectionResponse;
  initiateExportProfileWithAtsJobV2: InitiateConnectionResponse;
  initiateSendJobV2: InitiateConnectionResponse;
  initiateSendMessageV2: InitiateConnectionResponse;
  markApplicationsRead: MarkApplicationsReadPayload;
  orderReferenceCheck: OrderReferenceCheckResponse;
  patchCompanyProfile?: Maybe<PatchCompanyProfileResponse>;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payByStripeSavedCreditCard?: Maybe<PayByStripeSavedCreditCardPayload>;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payByStripeSavedCreditCardForInvoice?: Maybe<PayByStripeSavedCreditCardPayload>;
  processUploadedSupportDoc: ProcessUploadedSupportDocResponse;
  publishCPQAEvent?: Maybe<PublishCpqaEventPayload>;
  publishJobAdBookingEvent?: Maybe<PublishJobAdBookingEventPayload>;
  publishJobPostedEvent?: Maybe<PublishJobPostedEventPayload>;
  refreshCartProductSet?: Maybe<RefreshCartProductSetResultPayload>;
  refreshJob?: Maybe<RefreshJobResponse>;
  registerAdditionalAdvertiser?: Maybe<RegisterPayload>;
  registerNewUser?: Maybe<RegisterPayload>;
  reinstate?: Maybe<ReinstateResponse>;
  removeApplicationNote: RemoveApplicationNotePayload;
  removeCartItem?: Maybe<RemoveCartItemResultPayload>;
  removeCartPromoCode?: Maybe<RemoveCartPromoCodeResultPayload>;
  removeUserFromAccount?: Maybe<AccountDetailsUpdateResponse>;
  /** Creates relationships between a hirer and partner */
  requestIntegrationRelationships: RequestIntegrationRelationshipsPayload;
  resendAccountActivationEmail: ResendAccountActivationEmailResponse;
  sendAccessEvent: SendAccessEventPayload;
  sendApplicationEmail: ApplicationEmail;
  sendBulkApplicationEmail: SendBulkApplicationEmailPayload;
  /** Send a verification email to a hirer. */
  sendEmailVerificationEmail?: Maybe<SendEmailVerificationEmailPayload>;
  /** Send a enrolment to MFA email to a hirer. */
  sendEnrolmentEmail?: Maybe<SendEnrolmentEmailPayload>;
  /** Send email to hirer account users notifying they have access to a specific job */
  sendHirerAccountUsersJobAccessEmail?: Maybe<SendHirerAccountUsersJobAccessEmailPayload>;
  /** Track the details of a successful login which cannot be reliably tracked server-side. */
  sendLoginCallbackEvent?: Maybe<SendLoginCallbackEventPayload>;
  sendPasswordResetEmail: SendEmailResponse;
  sendRegistrationEmail: SendEmailResponse;
  setDefaultBrand?: Maybe<Brand>;
  /** Set default notification preference for current logged in user for a specific job */
  setDefaultNotificationPreference: NotificationPreference;
  /** Set job access for hirer account users for a specific job */
  setHirerAccountUsersJobAccess?: Maybe<SetHirerAccountUsersJobAccessPayload>;
  /** Set notification preferences for account users for a specific job */
  setNotificationPreferences: SetNotificationPreferencesPayload;
  shareTalentPool: Scalars['String']['output'];
  submitCompanyProfileAdvertiserClaim?: Maybe<Scalars['Boolean']['output']>;
  submitCompanyProfileClaim?: Maybe<CompanyProfileAdvertiserClaimResponse>;
  /**
   * Track they query text entered or chosen location id after a user has been presented with location suggestions.
   * By tracking the outcome of a location suggestion request the accuracy of location data in future can be improved.
   *
   * The sessionId should also be sent to the "locations" query so that tracking between the suggestions provided and the chosen
   * result can be completed.
   */
  trackLocationSuggestion: TrackLocationSuggestionPayload;
  triggerActivation: TriggerActivationPayload;
  updateAccountBillingAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountBillingEmailAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountPostalAddress?: Maybe<AccountDetailsUpdateResponse>;
  /** @deprecated Use updateAccountPrimaryContactV2 instead */
  updateAccountPrimaryContact?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountPrimaryContactV2?: Maybe<AccountDetailsUpdateResponse>;
  updateApplicationRating: ApplicationRating;
  updateApplicationsStatus: UpdateApplicationsStatusPayload;
  updateBrand?: Maybe<UpdateBrandPayload>;
  updateBusinessIdentifiers?: Maybe<AccountDetailsUpdateResponse>;
  updateCartProductSet?: Maybe<UpdateCartProductSetResultPayload>;
  updateCartPromoCode?: Maybe<UpdateCartPromoCodeResultPayload>;
  updateDraftJob: UpdateDraftPayload;
  updateHirerNotificationPreferences: Array<HirerNotificationPreferenceCategory>;
  updateJobDetails: UpdateJobDetailsResponse;
  updateJobForCartItemByReference?: Maybe<UpdateJobForCartItemByReferenceResultPayload>;
  updateJobPlacement: UpdateJobPlacementResponse;
  updateTalentPool: Scalars['String']['output'];
  updateUserDetails: UpdateUserDetailsResponse;
  updateUserPermissions?: Maybe<UpdateUserPermissionsResponse>;
  /** upsert review reply method means: if the review reply exists, update it, otherwise create it */
  upsertReviewReply: UpsertReviewReplyResponse;
}

export interface Mutation_AddItemToCartArgs {
  input: AddItemToCartInput;
}

export interface Mutation_AddOrRemoveProfileToTalentPoolsArgs {
  input: TalentSearchAddOrRemoveProfileTalentPoolsInput;
}

export interface Mutation_AddUserToAccountArgs {
  input: AddUserToAccountInput;
}

export interface Mutation_ApplyCoverToAllBrandsArgs {
  brandId: Scalars['String']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface Mutation_ClearDefaultBrandArgs {
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface Mutation_CompleteAdditionalAdvertiserRegistrationArgs {
  input: SecureLinkInput;
}

export interface Mutation_CompleteUserRegistrationArgs {
  input: SecureLinkInput;
}

export interface Mutation_ConfirmInvoiceOrderArgs {
  input: ConfirmInvoiceOrderInput;
}

export interface Mutation_Create2C2PPaymentIntentArgs {
  input: Create2C2PPaymentIntentInput;
}

export interface Mutation_Create2C2PPaymentIntentForInvoicesArgs {
  input: Create2C2PPaymentIntentForInvoiceInput;
}

export interface Mutation_CreateApplicationNoteArgs {
  input: CreateApplicationNoteInput;
}

export interface Mutation_CreateBrandArgs {
  input: CreateBrandInput;
}

export interface Mutation_CreateCustomQuestionArgs {
  input: CreateCustomQuestionInput;
}

export interface Mutation_CreateDraftJobArgs {
  input: CreateDraftJobInput;
}

export interface Mutation_CreateJobArgs {
  input: CreateJobInput;
}

export interface Mutation_CreateJobRevisionArgs {
  input: CreateJobRevisionInput;
}

export interface Mutation_CreateOrderArgs {
  input: CreateOrderInput;
}

export interface Mutation_CreateOrderWithPromotionArgs {
  input: CreateOrderInput;
}

export interface Mutation_CreateProductSetForAdEditingArgs {
  input: CreateProductSetForAdEditingInput;
}

export interface Mutation_CreateProductSetForAdPostingArgs {
  input: CreateProductSetForAdPostingInput;
}

export interface Mutation_CreateProductSetForAdRefreshArgs {
  input: CreateProductSetForAdRefreshInput;
}

export interface Mutation_CreateProductSetForAdUpgradePurchaseArgs {
  input: CreateProductSetForAdUpgradePurchaseInput;
}

export interface Mutation_CreateProductSetForBudgetsPurchaseForHirerArgs {
  input?: InputMaybe<CreateProductSetForBudgetsPurchaseForHirerInput>;
}

export interface Mutation_CreateProductSetForBudgetsPurchaseForVisitorArgs {
  input: CreateProductSetForBudgetsPurchaseForVisitorInput;
}

export interface Mutation_CreateProductSetForMultiLocationArgs {
  input: CreateProductSetForMultiLocationInput;
}

export interface Mutation_CreateProductSetForPriceCheckerArgs {
  input: CreateProductSetForPriceCheckerInput;
}

export interface Mutation_CreateProductSetForPriceCheckerPreviewArgs {
  input: CreateProductSetForPriceCheckerPreviewInput;
}

export interface Mutation_CreateQuestionnaireArgs {
  input: CreateQuestionnaireInput;
}

export interface Mutation_CreateSavedSearchArgs {
  input: CreateSavedSearchInput;
}

export interface Mutation_CreateStripePaymentIntentArgs {
  input: CreateStripePaymentIntentInput;
}

export interface Mutation_CreateStripePaymentIntentForInvoiceArgs {
  input: CreateStripePaymentIntentForInvoiceInput;
}

export interface Mutation_CreateTalentPoolArgs {
  input: TalentSearchCreateTalentPoolInput;
}

export interface Mutation_DeleteBrandArgs {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface Mutation_DeleteBrandingAssetArgs {
  input: DeleteBrandingAssetInput;
}

export interface Mutation_DeleteDraftJobArgs {
  input: DeleteDraftJobInput;
}

export interface Mutation_DeleteSavedSearchArgs {
  criteriaHash: Scalars['String']['input'];
}

export interface Mutation_DeleteStripeSavedCreditCardArgs {
  input: DeleteStripeSavedCreditCardInput;
}

export interface Mutation_DeleteTalentPoolArgs {
  input: TalentSearchDeleteTalentPoolInput;
}

export interface Mutation_DeleteUploadedSupportDocArgs {
  input: DeleteUploadedSupportDocInput;
}

export interface Mutation_EditJobArgs {
  input: EditJobInput;
}

export interface Mutation_ExpireJobArgs {
  input: ExpireJobInput;
}

export interface Mutation_GenerateJobContentArgs {
  input: GenerateJobContentInput;
}

export interface Mutation_InitiateAccessCvV2Args {
  input: InitiateAccessCvV2Input;
}

export interface Mutation_InitiateAccessProfileAndCvV2Args {
  input: InitiateAccessProfileAndCvV2Input;
}

export interface Mutation_InitiateAccessProfileV2Args {
  input: InitiateAccessProfileV2Input;
}

export interface Mutation_InitiateExportProfileV2Args {
  input: InitiateExportProfileV2Input;
}

export interface Mutation_InitiateExportProfileWithAtsJobV2Args {
  input: InitiateExportProfileWithAtsJobV2Input;
}

export interface Mutation_InitiateSendJobV2Args {
  input: InitiateSendJobV2Input;
}

export interface Mutation_InitiateSendMessageV2Args {
  input: InitiateSendMessageV2Input;
}

export interface Mutation_MarkApplicationsReadArgs {
  input: MarkApplicationsReadInput;
}

export interface Mutation_OrderReferenceCheckArgs {
  input: OrderReferenceCheckInput;
}

export interface Mutation_PatchCompanyProfileArgs {
  input: CompanyProfileInput;
}

export interface Mutation_PayByStripeSavedCreditCardArgs {
  input: PayByStripeSavedCreditCardInput;
}

export interface Mutation_PayByStripeSavedCreditCardForInvoiceArgs {
  input: PayByStripeSavedCreditCardForInvoiceInput;
}

export interface Mutation_ProcessUploadedSupportDocArgs {
  input: ProcessUploadedSupportDocInput;
}

export interface Mutation_PublishCpqaEventArgs {
  input: PublishCpqaEventInput;
}

export interface Mutation_PublishJobAdBookingEventArgs {
  input: PublishJobAdBookingEventInput;
}

export interface Mutation_PublishJobPostedEventArgs {
  input?: InputMaybe<PublishJobPostedEventInput>;
}

export interface Mutation_RefreshCartProductSetArgs {
  input: UpdateCartProductSetInput;
}

export interface Mutation_RefreshJobArgs {
  input: RefreshJobInput;
}

export interface Mutation_RegisterAdditionalAdvertiserArgs {
  input: RegisterAdditionalAdvertiserInput;
}

export interface Mutation_RegisterNewUserArgs {
  input: RegisterNewUserInput;
}

export interface Mutation_ReinstateArgs {
  input: ReinstateInput;
}

export interface Mutation_RemoveApplicationNoteArgs {
  input: RemoveApplicationNoteInput;
}

export interface Mutation_RemoveCartItemArgs {
  input: RemoveCartItemInput;
}

export interface Mutation_RemoveCartPromoCodeArgs {
  input: RemoveCartPromoCodeInput;
}

export interface Mutation_RemoveUserFromAccountArgs {
  input: RemoveUserFromAccountInput;
}

export interface Mutation_RequestIntegrationRelationshipsArgs {
  input: RequestIntegrationRelationshipsInput;
}

export interface Mutation_ResendAccountActivationEmailArgs {
  input: ResendAccountActivationEmailInput;
}

export interface Mutation_SendApplicationEmailArgs {
  input: SendApplicationEmailInput;
}

export interface Mutation_SendBulkApplicationEmailArgs {
  input: SendBulkApplicationEmailInput;
}

export interface Mutation_SendHirerAccountUsersJobAccessEmailArgs {
  input: SendHirerAccountUsersJobAccessEmailInput;
}

export interface Mutation_SendPasswordResetEmailArgs {
  input: SendPasswordResetEmailInput;
}

export interface Mutation_SendRegistrationEmailArgs {
  input: SendRegistrationEmailInput;
}

export interface Mutation_SetDefaultBrandArgs {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface Mutation_SetDefaultNotificationPreferenceArgs {
  input: SetDefaultNotificationPreferenceInput;
}

export interface Mutation_SetHirerAccountUsersJobAccessArgs {
  input: SetHirerAccountUsersJobAccessInput;
}

export interface Mutation_SetNotificationPreferencesArgs {
  input: SetNotificationPreferencesInput;
}

export interface Mutation_ShareTalentPoolArgs {
  input: TalentSearchShareTalentPoolInput;
}

export interface Mutation_SubmitCompanyProfileAdvertiserClaimArgs {
  input: CompanyProfileAdvertiserClaimInput;
}

export interface Mutation_SubmitCompanyProfileClaimArgs {
  input: CompanyProfileAdvertiserClaimInput;
}

export interface Mutation_TrackLocationSuggestionArgs {
  input: TrackLocationSuggestionInput;
}

export interface Mutation_UpdateAccountBillingAddressArgs {
  input?: InputMaybe<AccountBillingAddressInput>;
}

export interface Mutation_UpdateAccountBillingEmailAddressArgs {
  input?: InputMaybe<AccountBillingEmailAddressInput>;
}

export interface Mutation_UpdateAccountPostalAddressArgs {
  input?: InputMaybe<AccountPostalAddressInput>;
}

export interface Mutation_UpdateAccountPrimaryContactArgs {
  input?: InputMaybe<AccountPrimaryContactInput>;
}

export interface Mutation_UpdateAccountPrimaryContactV2Args {
  input: UpdateAccountPrimaryContactV2Input;
}

export interface Mutation_UpdateApplicationRatingArgs {
  input: UpdateApplicationRatingInput;
}

export interface Mutation_UpdateApplicationsStatusArgs {
  input: UpdateApplicationsStatusInput;
}

export interface Mutation_UpdateBrandArgs {
  input: UpdateBrandInput;
}

export interface Mutation_UpdateBusinessIdentifiersArgs {
  input: AccountBusinessIdentifiersInput;
}

export interface Mutation_UpdateCartProductSetArgs {
  input: UpdateCartProductSetInput;
}

export interface Mutation_UpdateCartPromoCodeArgs {
  input: UpdateCartPromoCodeInput;
}

export interface Mutation_UpdateDraftJobArgs {
  input: UpdateDraftJobInput;
}

export interface Mutation_UpdateHirerNotificationPreferencesArgs {
  input: HirerNotificationUpdatePreferenceInput;
}

export interface Mutation_UpdateJobDetailsArgs {
  input: UpdateJobDetailsInput;
}

export interface Mutation_UpdateJobForCartItemByReferenceArgs {
  input: UpdateJobForCartItemByReferenceInput;
}

export interface Mutation_UpdateJobPlacementArgs {
  input: UpdateJobPlacementInput;
}

export interface Mutation_UpdateTalentPoolArgs {
  input: TalentSearchUpdateTalentPoolInput;
}

export interface Mutation_UpdateUserDetailsArgs {
  input: UserDetailsInput;
}

export interface Mutation_UpdateUserPermissionsArgs {
  input: UpdateUserPermissionsInput;
}

export interface Mutation_UpsertReviewReplyArgs {
  input: UpsertReviewReplyInput;
}

export interface MyJobInformationJobEdit {
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timestampUTC: Scalars['DateTime']['output'];
}

export interface MyJobInformationResponse {
  addOns: Array<Maybe<Scalars['String']['output']>>;
  advertiserName: Scalars['String']['output'];
  analyticsProductDisplayName: Scalars['String']['output'];
  applyUrl?: Maybe<Scalars['String']['output']>;
  classificationName: Scalars['String']['output'];
  createdByEmail: Scalars['String']['output'];
  createdByName: Scalars['String']['output'];
  isLinkOut: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  isSalaryHidden: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  latestEdit?: Maybe<MyJobInformationJobEdit>;
  mirrorClassificationName?: Maybe<Scalars['String']['output']>;
  mirrorSubClassificationName?: Maybe<Scalars['String']['output']>;
  postTypeCategory: AnalyticsPostTypeCategory;
  salary: MyJobInformationSalary;
  subClassificationName: Scalars['String']['output'];
  workType: AnalyticsWorkType;
}

export interface MyJobInformationSalary {
  additionalText?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  maximum: Scalars['Float']['output'];
  minimum: Scalars['Float']['output'];
  type?: Maybe<AnalyticsSalaryType>;
}

export interface MyJobInformationWidgetInput {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface NewAdvertiser {
  /** Business identification number. */
  businessIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Business name. */
  businessName: Scalars['String']['input'];
  /** Business phone number. */
  businessPhoneNumber: Scalars['String']['input'];
  /** Billing country. */
  country: Scalars['String']['input'];
  /** Indicates whether the employer is an agency account. */
  isAgency?: InputMaybe<Scalars['Boolean']['input']>;
  /** The zone where the user registered. */
  originZone?: InputMaybe<Scalars['String']['input']>;
  /** The company's tax number. Used for countries where their tax and business number differ. */
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface NewUser {
  /** Given name */
  firstName: Scalars['String']['input'];
  /** Family name */
  lastName: Scalars['String']['input'];
  /** The audience zone of the site that this user originally registered on */
  originZone?: InputMaybe<Scalars['String']['input']>;
}

export interface NoJobs {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface NotificationPreference {
  /** Email which notification will be sent to if account user chooses to receive notification for every new application. */
  applicationEmail?: Maybe<Scalars['String']['output']>;
  /** Frequency of receiving notifications. */
  frequency: Frequency;
  /** Id of the account user who receives the notification. Positive number only. */
  userId: Scalars['ID']['output'];
}

export interface NotificationPreferenceInput {
  /** Email which notification will be sent to if account user chooses to receive notification for every new application. */
  applicationEmail?: InputMaybe<Scalars['String']['input']>;
  /** Frequency of receiving notifications. */
  frequency: Frequency;
  /** Id of the account user who receives the notification. Positive number only. */
  userId: Scalars['ID']['input'];
}

export interface Option {
  /** Option Order */
  order: Scalars['Int']['input'];
  /** Option Text */
  text: Scalars['String']['input'];
}

export interface OptionWithId {
  /** Option Id */
  id: Scalars['ID']['output'];
  /** Option Order */
  order: Scalars['Int']['output'];
  /** Option Text */
  text: Scalars['String']['output'];
}

export interface Order {
  orderId: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
}

/** Error for order that has past expiry date. */
export interface OrderExpiredError extends Error {
  message: Scalars['String']['output'];
}

/** Error for order without expiryTime or paymentBreakdown, any status not in pending, or an incomplete priced order. */
export interface OrderInvalidError extends Error {
  message: Scalars['String']['output'];
}

export interface OrderItem {
  productReference?: InputMaybe<Scalars['String']['input']>;
  productType: ProductType;
}

export interface OrderLineItem {
  /** The full name of the country (eg. Australia, Hong Kong) that the order item is relevant to. Localised if locale is provided. */
  country?: Maybe<Scalars['String']['output']>;
  /** Drawdown items of the order item. */
  drawdownItems: Array<DrawdownItem>;
  /** Drawdown type of the order item. */
  drawdownType: DrawdownType;
  /** Price of the order item. */
  price: Price;
  pricing?: Maybe<Pricing>;
  /** Reference to product. Eg JobAd, AdPack, AdBudget. */
  product: Product;
}

/** Error for order not found. */
export interface OrderNotFoundError extends Error {
  message: Scalars['String']['output'];
}

export interface OrderProductAttributesInput {
  /** Required input attributes if product selected is an Ad Budget. */
  adBudget?: InputMaybe<AdBudgetAttributes>;
  catalogueProduct?: InputMaybe<CatalogueProductAttributes>;
  /** Required input attributes if product selected is a Job Ad. */
  jobAd?: InputMaybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is a Job Ad Edit. */
  jobAdEdit?: InputMaybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is a Job Ad Extend. */
  jobAdExtend?: InputMaybe<JobAdRevisionAttributes>;
}

export interface OrderReferenceCheckInput {
  applicationCorrelationId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export type OrderReferenceCheckResponse =
  | OrderReferenceCheckResponseSuccess
  | ResponseError;

export interface OrderReferenceCheckResponseSuccess {
  success: Scalars['Boolean']['output'];
}

export interface OrderRequestInputItem {
  /** Required attributes associated to the product. Key name to attributes must match product type. Eg. jobAd for JOB_AD, adBudget for AD_BUDGET. */
  productAttributes: OrderProductAttributesInput;
  /** Type of product. Eg. Jod ad, Job ad edit, Ad budget */
  productType: ProductTypeInput;
}

export interface OrderSummary {
  /** All the line items of the order summary. */
  lineItems: Array<OrderLineItem>;
  /** The ID of the entity the order summary is built on. */
  orderId: Scalars['ID']['output'];
  /** This is the sum of the line item prices. The subTotal does not include taxes. */
  subTotal: Money;
  /** Optional surcharges that can be applied to the order. The order total does not include surcharges. */
  surcharges: Array<Surcharge>;
  /** The tax of the order summary. */
  tax?: Maybe<Tax>;
  /** Total price of the order summary. Includes taxes. */
  total: Money;
}

export interface OutputCustomQuestion {
  /** Question Id */
  id: Scalars['ID']['output'];
  /** Options */
  options: Array<OptionWithId>;
  /** Hirer Question Type e.g SingleSelect, FreeText */
  questionType: HirerQuestionType;
  /** Question Text */
  text: Scalars['String']['output'];
}

export interface PageInfo {
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
}

export interface PageTitleActionsResponse {
  advertiserName: Scalars['String']['output'];
  classificationName: Scalars['String']['output'];
  displayCurrency?: Maybe<Scalars['String']['output']>;
  isBranded: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobExpiryTimestampUTC?: Maybe<Scalars['DateTime']['output']>;
  jobId: Scalars['String']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobSummary?: Maybe<Scalars['String']['output']>;
  jobTitle: Scalars['String']['output'];
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastUpdatedTimestampUTC?: Maybe<Scalars['String']['output']>;
  latestJobs: Array<AnalyticsLatestJobs>;
  postTypeCategory: AnalyticsPostTypeCategory;
  salaryAdditionalText?: Maybe<Scalars['String']['output']>;
  serpLogoUrl?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
}

export interface PageTitleActionsWidgetInput {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface PaginationInput {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}

export interface Parent {
  advertiserId?: Maybe<Scalars['ID']['output']>;
  nation?: Maybe<Scalars['String']['output']>;
}

export interface PatchCompanyProfileResponse {
  id: Scalars['String']['output'];
}

export interface PayByStripeSavedCreditCardForInvoiceInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** Number of days before the payment intent expires and is disabled for payment. Minimum of at least 1 day */
  expiryDays?: InputMaybe<Scalars['Int']['input']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: Array<InvoiceInput>;
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
}

export interface PayByStripeSavedCreditCardInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
}

export type PayByStripeSavedCreditCardPayload =
  | InvoiceError
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PromotionInvalidError
  | SavedCreditCardPaymentError
  | SavedCreditCardPaymentSuccessPayload;

export type PayPerPlacementEligibilityResponse =
  | PayPerPlacementEligibleResponse
  | PayPerPlacementNonEligibleResponse
  | ResponseError;

export interface PayPerPlacementEligibleResponse {
  product: PayPerPlacementProduct;
  redirectUrl: Scalars['String']['output'];
}

export interface PayPerPlacementNonEligibleResponse {
  reason: PayPerPlacementNonEligibleResponseReason;
}

export enum PayPerPlacementNonEligibleResponseReason {
  DisabledMarket = 'DISABLED_MARKET',
  JobHirerCountryMismatch = 'JOB_HIRER_COUNTRY_MISMATCH',
  NonEligibleApiResponse = 'NON_ELIGIBLE_API_RESPONSE',
  NonEligibleHirerAccount = 'NON_ELIGIBLE_HIRER_ACCOUNT',
  NonEligibleOrigin = 'NON_ELIGIBLE_ORIGIN',
  NotLowPerformanceAd = 'NOT_LOW_PERFORMANCE_AD',
  OverrideResponse = 'OVERRIDE_RESPONSE',
}

export enum PayPerPlacementProduct {
  RecruiterNetwork = 'RECRUITER_NETWORK',
  SmartHire = 'SMART_HIRE',
}

export interface Payment2c2pInquiry {
  amount?: Maybe<Scalars['SafeInt']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  /** Status of payment using 2c2p gateway */
  status?: Maybe<Payment2c2pInquiryStatus>;
}

/** Error for 2c2p payment inquiry */
export interface Payment2c2pInquiryError extends Error {
  message: Scalars['String']['output'];
}

export type Payment2c2pInquiryPayload =
  | Payment2c2pInquiry
  | Payment2c2pInquiryError;

export enum Payment2c2pInquiryStatus {
  Cancelled = 'cancelled',
  Pending = 'pending',
  Processing = 'processing',
  Succeeded = 'succeeded',
}

export interface PaymentConfigPayload {
  features?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface PaymentIntent2C2PPayload {
  expiryTimer?: Maybe<Scalars['String']['output']>;
  paymentLink?: Maybe<Scalars['String']['output']>;
  paymentToken?: Maybe<Scalars['String']['output']>;
}

/** Error for payment intent failed to generate */
export interface PaymentIntentChargeError extends Error {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
}

export interface PaymentLinkInvoicePaid {
  /** Indicates whether the invoice has been paid. */
  paid: Scalars['Boolean']['output'];
}

export interface PaymentLinkInvoicePaidInput {
  reference: Scalars['String']['input'];
}

export type PaymentLinkInvoicePaidResponse =
  | PaymentLinkInvoicePaid
  | PaymentLinkInvoiceValidationError;

export interface PaymentLinkInvoiceValidationError extends Error {
  message: Scalars['String']['output'];
}

export interface PaymentMediumType {
  disablePayment: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
}

export enum PaymentMethod {
  Contract = 'CONTRACT',
  CreditCard = 'CREDIT_CARD',
  Invoice = 'INVOICE',
}

/** Error for payment method not supported for the currency */
export interface PaymentMethodError extends Error {
  message: Scalars['String']['output'];
}

export interface PaymentMethodPayload {
  maintenance: Maintenance;
  paymentMethods?: Maybe<Array<Maybe<Maintenance>>>;
}

export enum PaymentMethodType2C2P {
  Bnpl = 'BNPL',
  Dpay = 'DPAY',
  Qr = 'QR',
  Webpay = 'WEBPAY',
}

export interface PendingIntegrationRelationship {
  hirerAccountId: Scalars['ID']['output'];
  hirerOid: Scalars['String']['output'];
  relationship: IntegrationRelationshipType;
}

/** PerApplicantBilling feature to indicate CPQA product */
export interface PerApplicantBillingFeature extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

/** PerUnitBilling feature to indicate CPQA product */
export interface PerUnitBillingFeature extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unit: Scalars['String']['output'];
}

export interface PerformancePredictionChartEvent {
  comparisonValue: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  day: Scalars['Int']['output'];
  editMade: Scalars['Boolean']['output'];
  value: Scalars['Int']['output'];
}

export interface PerformancePredictionResponse {
  events?: Maybe<Array<Maybe<PerformancePredictionChartEvent>>>;
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  predictionDate?: Maybe<Scalars['Date']['output']>;
  predictionDayOptions: Array<PredictionDayOptions>;
  valueLabel?: Maybe<CandidateLabel>;
  xAxisLabels?: Maybe<Array<DateAxisLabel>>;
  xRange?: Maybe<Array<Scalars['Date']['output']>>;
  yRange?: Maybe<Array<Scalars['Int']['output']>>;
}

export interface PerformancePredictionWidgetInput {
  jobId: Scalars['String']['input'];
}

export enum Permission {
  CreateJobs = 'createJobs',
  ManageBrand = 'manageBrand',
  ManageUsers = 'manageUsers',
  PayInvoices = 'payInvoices',
  ViewInvoiceHistory = 'viewInvoiceHistory',
  ViewManagerReports = 'viewManagerReports',
}

export interface PermissionOptions {
  canClaimCompanyProfile: Scalars['Boolean']['output'];
  canCreateJobs: Scalars['Boolean']['output'];
  canManageBrand: Scalars['Boolean']['output'];
  canManageCompanyProfile: Scalars['Boolean']['output'];
  canManageUsers: Scalars['Boolean']['output'];
  canPayInvoices: Scalars['Boolean']['output'];
  canViewInsightsRoleReport: Scalars['Boolean']['output'];
  canViewInvoiceHistory: Scalars['Boolean']['output'];
  canViewManagerReports: Scalars['Boolean']['output'];
}

export interface PhysicalAddress {
  /** Address line 1 */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Address line 2 */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** All address lines */
  addressLines?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Name of the Country */
  country?: Maybe<Scalars['String']['output']>;
  /** Post code */
  postCode?: Maybe<Scalars['String']['output']>;
  /** State */
  state?: Maybe<Scalars['String']['output']>;
  /** Suburb */
  suburb?: Maybe<Scalars['String']['output']>;
}

export type PositionIdResponse = PositionIdResponseSuccess | ResponseError;

export interface PositionIdResponseSuccess {
  result: Scalars['ID']['output'];
}

export enum PostChannel {
  /** The job is posted through the adcentre CAJA flow. */
  Direct = 'DIRECT',
  /** The job is an aggregated job from an external website. */
  Import = 'IMPORT',
  /** The job is posted through the indirect-managed SEEK API by a third party. */
  Indirect = 'INDIRECT',
}

export enum PostType {
  /** The job is posted through the adcentre CAJA flow. */
  Direct = 'DIRECT',
  /** The job is an aggregated job from an external website. */
  Import = 'IMPORT',
  /** The job is posted through the indirect-managed SEEK API by a third party. */
  Indirect = 'INDIRECT',
  /** The job is a paid aggregation job, where the customer has requested and paid for their job to be imported to SEEK. */
  PaidAggregation = 'PAID_AGGREGATION',
}

export interface PredictionDayOptions {
  prediction: Scalars['Int']['output'];
  text: Scalars['String']['output'];
}

export interface PreviousApplicationsInput {
  /** The selected application's GUID to be filtered out of the previous-apps results */
  applicationCorrelationId: Scalars['ID']['input'];
  /** The selected application's candidate email to search the previous applications */
  candidateEmail: Scalars['String']['input'];
  /** The selected application's job id */
  jobId: Scalars['ID']['input'];
}

export type PreviousApplicationsResponse =
  | PreviousApplicationsResponseSuccess
  | ResponseError;

export interface PreviousApplicationsResponseSuccess {
  applications: Array<Application>;
}

export interface Price {
  /** Cost per applicant price for CPQA product. */
  costPerApplicant?: Maybe<Money>;
  discount?: Maybe<Discount>;
  isVariable?: Maybe<Scalars['Boolean']['output']>;
  netPrice: Money;
  retailPrice: Money;
  /** Tax data for the retail price. */
  retailTax?: Maybe<Tax>;
  /** Tax data for the net price. */
  tax?: Maybe<Tax>;
}

export interface PriceAttributes {
  locationId?: InputMaybe<Scalars['Float']['input']>;
  roleTitle?: InputMaybe<Scalars['String']['input']>;
  subclassificationId?: InputMaybe<Scalars['Float']['input']>;
}

export interface PriceType {
  type: Scalars['String']['output'];
}

export enum PriceVisibility {
  HidePrice = 'HIDE_PRICE',
  ShowDiscountedPrice = 'SHOW_DISCOUNTED_PRICE',
  ShowRetailPrice = 'SHOW_RETAIL_PRICE',
}

export type Pricing = CappedUnitPrice | PriceType;

export enum PricingModel {
  Fixed = 'FIXED',
  Horizon = 'HORIZON',
}

export interface PrimaryContact {
  /** Email address of the user */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the user */
  firstName?: Maybe<Scalars['String']['output']>;
  /** ID of the user assigned as the primary contact */
  hirerUserId?: Maybe<Scalars['String']['output']>;
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Phone number of the advertiser collected on registration form */
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export enum PrivacyTypes {
  Limited = 'limited',
  Standard = 'standard',
}

export interface ProcessUploadedSupportDocInput {
  applicationCorrelationId: Scalars['ID']['input'];
  blobId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export type ProcessUploadedSupportDocResponse =
  | ProcessUploadedSupportDocResponseSuccess
  | ResponseError;

export interface ProcessUploadedSupportDocResponseSuccess {
  result: ProcessUploadedSupportDocResult;
}

export interface ProcessUploadedSupportDocResult {
  blobId: Scalars['ID']['output'];
}

export interface Product {
  name: Scalars['String']['output'];
  productType: ProductType;
}

export interface ProductCap {
  addons?: Maybe<Scalars['Int']['output']>;
  budgets?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Scalars['Int']['output']>;
}

export interface ProductDisplay {
  banner?: Maybe<DisplayBanner>;
  comparatives?: Maybe<DisplayComparatives>;
  cta?: Maybe<DisplayCallToAction>;
  custom?: Maybe<DisplayCustom>;
  expectations?: Maybe<DisplayExpectations>;
  highlights?: Maybe<DisplayHighlights>;
  image?: Maybe<DisplayImage>;
  inclusions?: Maybe<DisplayInclusions>;
  panel?: Maybe<DisplayPanel>;
  price?: Maybe<DisplayPrice>;
  rating?: Maybe<DisplayRating>;
  tagline?: Maybe<DisplayTagline>;
  title?: Maybe<DisplayTitle>;
}

export interface ProductDisplayNameAverageCost {
  cost: Money;
  type: Scalars['String']['output'];
}

export interface ProductDisplayNameOption {
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
}

export interface ProductDisplayNamePostCount {
  adsCount: Scalars['Int']['output'];
  type: Scalars['String']['output'];
}

export interface ProductExpectation {
  correlationId: Scalars['String']['output'];
  key: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
}

export interface ProductFeature {
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface ProductList {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** A list of all catalogue products in the product set */
  items: Array<CatalogueProduct>;
  /** A list of catalogue products in the product set which can be presented to the customer (eg. in an ad ladder) */
  products: Array<CatalogueProduct>;
}

export interface ProductReference {
  /** Unique ID of the product reference that references to an addon/upgrade */
  id: Scalars['ID']['output'];
}

export interface ProductSet {
  /** Unique ID of the product set */
  id: Scalars['ID']['output'];
  /** A list of all catalogue products in the product set */
  items: Array<Maybe<CatalogueProduct>>;
}

export interface ProductSetExpiredError extends Error {
  message: Scalars['String']['output'];
}

export interface ProductSetNotFoundError extends Error {
  message: Scalars['String']['output'];
}

export enum ProductType {
  AdBudget = 'AD_BUDGET',
  CatalogueProduct = 'CATALOGUE_PRODUCT',
  JobAd = 'JOB_AD',
  JobAdEdit = 'JOB_AD_EDIT',
  JobAdExtend = 'JOB_AD_EXTEND',
  JobAdUpgrade = 'JOB_AD_UPGRADE',
}

export enum ProductTypeInput {
  AdBudget = 'AD_BUDGET',
  CatalogueProduct = 'CATALOGUE_PRODUCT',
  JobAd = 'JOB_AD',
  JobAdEdit = 'JOB_AD_EDIT',
  JobAdExtend = 'JOB_AD_EXTEND',
}

export interface ProductUpgradeList {
  /** Display data which recommends certain layouts to the front-end */
  display: LayoutDisplay;
  /** A list of catalogue products in the product set which can be presented to the customer (eg. in an ad ladder) */
  products: Array<CatalogueProduct>;
}

export interface ProfileAndCvUnavailable {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface ProfileEducation {
  completionMonth: Scalars['Int']['output'];
  completionYear: Scalars['Int']['output'];
  institutionName: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  qualificationName: Scalars['String']['output'];
  verifiedFields?: Maybe<Array<VerifiedField>>;
}

export interface ProfileLicence {
  isVerified: Scalars['Boolean']['output'];
  issuingOrganisationName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  verifiedFields?: Maybe<Array<VerifiedField>>;
}

export interface ProfileProperties {
  ExpressCajaOptOut?: Maybe<Scalars['String']['output']>;
  HasLoggedInBefore?: Maybe<Scalars['String']['output']>;
  HasOnlyUsedExpressCaja?: Maybe<Scalars['String']['output']>;
  HasSeenGuidedCallOut?: Maybe<Scalars['String']['output']>;
  HasSeenJobDetailsTour?: Maybe<Scalars['String']['output']>;
  UserStatusBeforeDeactivation?: Maybe<Scalars['String']['output']>;
}

export interface ProfileResultHighlight {
  resumeSnippet?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Scalars['String']['output']>>;
}

export interface ProfileSalaryV2 {
  expected?: Maybe<ExpectedSalaryV2>;
}

export enum ProfileSource {
  Combined = 'combined',
  Seek = 'seek',
}

export enum ProfileTypes {
  Combined = 'combined',
  Partner = 'partner',
  Seek = 'seek',
  Shared = 'shared',
}

export interface ProfilesByEmailInput {
  /** @deprecated This is not required because we rely on advertiserId from the header */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
}

export interface PromoCode {
  /** The campaign that the promo code belongs to */
  campaignId: Scalars['ID']['output'];
  /** The promo code value that a hirer can use on a product in order to get a discount */
  code: Scalars['String']['output'];
  /** The date and time when the promo code was created */
  createdAt: Scalars['DateTime']['output'];
  /** The discount amount or rate on a product after the promo code has been applied. Currently only applicable to discount rate */
  discount: PromoCodeDiscount;
  /** The date and time when the promo code was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The date and time when the promo code is valid from */
  validFrom: Scalars['DateTime']['output'];
  /** The products that the promo code can be applied to */
  validProducts: Array<PromoCodeProduct>;
  /** The date and time when the promo code is valid to */
  validTo: Scalars['DateTime']['output'];
}

export type PromoCodeDiscount = PromoCodeDiscountRate;

export interface PromoCodeDiscountRate {
  /** The promo code discount rate in percentage up to two decimal places eg. 99.99 is equivalent to 99.99% */
  discountRate: Scalars['Float']['output'];
}

export interface PromoCodeProduct {
  /** The product display name */
  displayName: Scalars['String']['output'];
  /** The product id */
  id: Scalars['ID']['output'];
}

export interface Promotion {
  code?: Maybe<Scalars['String']['output']>;
}

/** Error for invalid, expired, or not found promotion code. */
export interface PromotionInvalidError extends Error {
  message: Scalars['String']['output'];
}

export interface PublishCpqaEventInput {
  /** Advertiser name */
  accountName: Scalars['String']['input'];
  /** The job title */
  roleTitle: Scalars['String']['input'];
}

export interface PublishCpqaEventPayload {
  published?: Maybe<Scalars['Boolean']['output']>;
}

export interface PublishJobAdBookingEventInput {
  /**
   * The Seek advertiser/account id
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the company Hirer recruiting for */
  companyName: Scalars['String']['input'];
  /** The contact by time in UTC */
  contactBy: Scalars['String']['input'];
  /** The contact name for this particular request */
  contactName: Scalars['String']['input'];
  /** The Job Category ID */
  jobCategorySubClassificationId: Scalars['String']['input'];
  /** Two digit ISO country for location of job ad. */
  jobCountryCode: JobAdBookingCountryCode;
  /** The job location */
  jobLocation?: InputMaybe<Scalars['String']['input']>;
  /** The Job Location ID */
  jobLocationId: Scalars['String']['input'];
  /** The job title */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The phone number where CS can contact Hirer on */
  phoneNumber: Scalars['String']['input'];
  /** The product ID */
  productId?: InputMaybe<Scalars['String']['input']>;
  /** The user email */
  userEmail: Scalars['String']['input'];
  /** Country code for the website where customer made the request. */
  websiteCountryCode: JobAdBookingCountryCode;
}

export interface PublishJobAdBookingEventPayload {
  published?: Maybe<Scalars['Boolean']['output']>;
}

export enum PublishJobAdProductCode {
  /** The product we offer in AU, GUARANTEED_HIRE */
  Auskghrb = 'AUSKGHRB',
  /** The product we offer in NZ, CONCIERGE */
  Concierge = 'CONCIERGE',
}

export interface PublishJobPostedEventInput {
  /**
   * The ID of the draft in Adcentre
   * @deprecated Use `draftId` instead
   */
  adcentreDraftId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the listing in Adcentre */
  adcentreListingId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the user in Adcentre */
  adcentreUserId: Scalars['Int']['input'];
  /**
   * The ID of the advertiser
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The type of device used by the hirer to post their job */
  device: Scalars['String']['input'];
  /**
   * TODO: Make this below mandatory once frontend updated to post it
   * and remove the above adcentreDraftId
   */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The experiment being run while this job was posted */
  experimentId?: InputMaybe<Scalars['String']['input']>;
  /** The experiment variant being run while this job was posted */
  experimentVariantId?: InputMaybe<Scalars['String']['input']>;
  /** Flow used to create/update the job, e.g. refresh, edit, create */
  flowType?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the job */
  jobId: Scalars['String']['input'];
  /** The experience the hirer used to post their job */
  jobPostingExperience: Scalars['String']['input'];
  /** The job writing flow that hirer has followed (guided or standard) */
  jobWritingFlow: Scalars['String']['input'];
}

export interface PublishJobPostedEventPayload {
  jobId: Scalars['String']['output'];
}

export type PurchasableProducts =
  | PurchasableProductsList
  | PurchasableProductsString;

export interface PurchasableProductsList {
  products?: Maybe<Array<Scalars['String']['output']>>;
}

export interface PurchasableProductsString {
  product: Scalars['String']['output'];
}

export enum PurchaseContext {
  DirectAdProductEdit = 'DIRECT_AD_PRODUCT_EDIT',
  DirectAdProductUpgradeEdit = 'DIRECT_AD_PRODUCT_UPGRADE_EDIT',
}

export enum PurchaseContextUseCase {
  AddonsOnly = 'AddonsOnly',
  BrandingAddonsOnly = 'BrandingAddonsOnly',
  BudgetsOnly = 'BudgetsOnly',
}

export interface Query {
  /** Retrieves a list of credit cards for a particular user against an advertiser account either in 'AU' or 'NZ' country codes. */
  SavedStripeCreditCards?: Maybe<Array<Maybe<CreditCard>>>;
  _empty?: Maybe<Scalars['String']['output']>;
  _rateLimitingTestQuery?: Maybe<Scalars['String']['output']>;
  accountSelectionV3: AccountSelectionV3Response;
  /** Query to get ad budget products for upselling given list of product ids */
  adBudgetProductsForUpsell: AdBudgetProductsForUpsellResponse;
  /** Request to get adProducts for Ad Editing */
  adProductsForAdEditing: AdProductSetResponse;
  /** Request to get adProducts for Ad Posting */
  adProductsForAdPosting: AdProductSetResponse;
  /** Request to get upgrade products for Upgrade flow */
  adUpgradeProductsForAdUpgradePurchase: AdUpgradeProductSetResponse;
  /** Ad Performance Report query */
  analyticsAdPerformanceReport?: Maybe<AdPerformanceReportResponse>;
  /** Ad Rating query */
  analyticsAdRating?: Maybe<AdRatingResponse>;
  /** Ad Usage Trend ad type daily and monthly widget */
  analyticsAdTypeTrend?: Maybe<AdUsageAdTypeTrendResponse>;
  /** Ad Usage Conversion query */
  analyticsAdUsageConversion?: Maybe<AdUsageConversionResponse>;
  /** Ad Usage Filters query */
  analyticsAdUsageFilters?: Maybe<AdUsageFiltersResponse>;
  /** Ad Usage Last Updated query */
  analyticsAdUsageLastUpdated?: Maybe<LastUpdatedResponse>;
  /** Ad Usage Spend query */
  analyticsAdUsageSpend?: Maybe<AdUsageSpendResponse>;
  /** Ad Usage Tips widget */
  analyticsAdUsageTipsToImproveAds?: Maybe<TipsToImproveAdsResponse>;
  /** Application Performance query */
  analyticsApplicationPerformance?: Maybe<ApplicationPerformanceResponse>;
  /** Candidate Demand query */
  analyticsCandidateDemand?: Maybe<CandidateDemandResponse>;
  /** Candidate Supply query */
  analyticsCandidateSupply?: Maybe<CandidateSupplyResponse>;
  /** Ad Usage Child Account Banner query */
  analyticsChildAccountBanner?: Maybe<ChildAccountBannerResponse>;
  /** Ad Usage Trend classification and user widget */
  analyticsClassificationsAndUsersTrend?: Maybe<AdUsageTrendClassificationsAndUsersResponse>;
  /** Clicks on your ad query */
  analyticsClicksOnYourAd?: Maybe<ClicksOnYourAdResponse>;
  analyticsInsightsRoleAdInsightsTrends: AnalyticsInsightsRoleAdInsightsTrendsResponse;
  analyticsInsightsRoleAdPerformance: AnalyticsInsightsRoleAdPerformanceResponse;
  analyticsInsightsRoleAdsPosted: AnalyticsInsightsRoleAdsPostedResponse;
  analyticsInsightsRoleCandidateProfiles: AnalyticsInsightsRoleCandidateProfiles;
  analyticsInsightsRoleCompaniesPosting: AnalyticsInsightsRoleCompaniesPostingResponse;
  analyticsInsightsRoleCompetitionForTalent: AnalyticsInsightsRoleCompetitionForTalentResponse;
  analyticsInsightsRoleLastUpdated: AnalyticsInsightsRoleLastUpdatedResponse;
  analyticsInsightsRoleLocations: Array<AnalyticsInsightsRoleLocation>;
  analyticsInsightsRoleNormalisedRoles: AnalyticsInsightsRoleNormalisedRolesResponse;
  analyticsInsightsRoleRelatedRoles: AnalyticsInsightsRoleRelatedRolesResponse;
  analyticsInsightsRoleSalaryDistribution: AnalyticsInsightsRoleSalaryDistributionResponse;
  analyticsInsightsRoleSalaryInsights: AnalyticsInsightsRoleSalaryInsightsResponse;
  analyticsInsightsRoleSalaryPrivacyCheck: AnalyticsInsightsRoleSalaryPrivacyCheckResponse;
  analyticsInsightsRoleSalaryRange: AnalyticsInsightsRoleSalaryRangeResponse;
  analyticsInsightsRoleUniqueApplicants: AnalyticsInsightsRoleUniqueApplicantsResponse;
  /** Job Ad List query */
  analyticsJobAdList?: Maybe<JobAdListResponse>;
  /** Market insights title query */
  analyticsMarketInsightsTitle?: Maybe<MarketInsightsTitleResponse>;
  /** Market Performance query */
  analyticsMarketPerformance?: Maybe<MarketPerformanceResponse>;
  /** Ad Usage Most Ads By Performance query */
  analyticsMostAdsByPerformance?: Maybe<MostAdsByPerformanceResponse>;
  /** Ad Details query */
  analyticsMyJobInformation?: Maybe<MyJobInformationResponse>;
  /** Page Title Actions query */
  analyticsPageTitleActions?: Maybe<PageTitleActionsResponse>;
  /** Performance Prediction query */
  analyticsPerformancePrediction?: Maybe<PerformancePredictionResponse>;
  /** Recommendations query */
  analyticsRecommendations?: Maybe<RecommendationsResponse>;
  /** Similar Ads query */
  analyticsSimilarAdsDetails?: Maybe<SimilarAdsResponse>;
  analyticsTalentSearchUsageConversion: AnalyticsTalentSearchUsageConversionResponse;
  analyticsTalentSearchUsageFilters?: Maybe<AnalyticsTalentSearchUsageFiltersResponse>;
  analyticsTalentSearchUsageLastUpdated?: Maybe<AnalyticsTalentSearchUsageLastUpdatedResponse>;
  analyticsTalentSearchUsageOverview: AnalyticsTalentSearchUsageOverviewResponse;
  analyticsTalentSearchUsageTrendsByAccount: AnalyticsTalentSearchUsageTrendsByAccountResponse;
  analyticsTalentSearchUsageTrendsByTime: AnalyticsTalentSearchUsageTrendsByTimeResponse;
  analyticsTalentSearchUsageUsersList?: Maybe<AnalyticsTalentSearchUsageUsersListResponse>;
  /** Views in Job Search query */
  analyticsViewsInJobSearch?: Maybe<ViewsInJobSearchResponse>;
  application: ApplicationResponse;
  applicationAttachmentsV2: ApplicationAttachmentsV2Response;
  applicationByAdcentreProspectId: ApplicationResponse;
  applicationEmails: ApplicationEmails;
  applicationNotesV2: ApplicationNotesResponse;
  applications: ApplicationsResponse;
  applicationsFacets: ApplicationsFacetsResponse;
  brand?: Maybe<Brand>;
  brandingAssets?: Maybe<Array<Maybe<BrandingAsset>>>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  cart?: Maybe<Cart>;
  companyProfile?: Maybe<CompanyProfile>;
  companyProfileAction?: Maybe<CompanyProfileAction>;
  companyProfileIds?: Maybe<CompanyProfileIds>;
  contractBalanceGroups: Array<ContractBalanceGroup>;
  contractBalances: Array<ContractBalance>;
  countrySelectionOptions: CountrySelectionOptions;
  currentAdvertiser: CurrentAdvertiserResponse;
  currentUser: CurrentUserResponse;
  dashboardJobs: DashboardJobsResponse;
  dashboardJobsCounts: DashboardJobsCountsResponse;
  defaultBrand?: Maybe<Brand>;
  draftJob?: Maybe<DraftJobPayload>;
  draftJobs: JobsResponse;
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** Email verification status of the user. */
  emailVerified: Scalars['Boolean']['output'];
  /** Fetches all feature flags values for keys provided */
  featureFlags: Array<Maybe<Feature>>;
  /** @deprecated Use job query */
  getJobDetails?: Maybe<Job>;
  getPaymentConfig?: Maybe<PaymentConfigPayload>;
  getPaymentMethodForMaintenance?: Maybe<PaymentMethodPayload>;
  /** Request to get product set */
  getProductSet: GetProductSetResponse;
  /** Get the surcharge amount for amex payment method */
  getSurchargeAmount: GetSurchargeAmountPayload;
  headerFooterContext?: Maybe<HeaderFooterContext>;
  /** Summary of account details from hirer accounts API */
  hirerAccount?: Maybe<HirerAccount>;
  /**
   * Find the user associated with given User ID and AdCentre Advertiser ID
   * @deprecated Use hirerUser instead.
   */
  hirerAccountUser: UserDetails;
  /** List all active and pending users tied to the Hirer account with their access permission information to the job. */
  hirerAccountUsersWithJobPermissions: Array<UserWithJobPermission>;
  /** List of hirer accounts associated with the user */
  hirerAccounts: HirerAccountsResult;
  /** Get hirer activities in pagination for a specific job order by most recent activity. Currently only add/delete notes on candidate */
  hirerActivitiesByJob: HirerActivityConnection;
  hirerDashboardBudgetUpsell: HirerDashboardBudgetUpsellResponse;
  hirerDashboardHiringAdviceList?: Maybe<HirerDashboardHiringAdviceList>;
  hirerDashboardTalentSearch: HirerDashboardTalentSearch;
  hirerNotificationPreferences: Array<HirerNotificationPreferenceCategory>;
  hirerOriginZone?: Maybe<Scalars['String']['output']>;
  /** Finds the hirer user associated with the given id */
  hirerUser: HirerUserDetails;
  /** Finds the user associated with the given email */
  hirerUserEmailV2: HirerUserEmailPayloadV2;
  /**
   * Identity of the currently authenticated user.
   * @deprecated Use email and emailVerified queries
   */
  identity: Identity;
  /**
   * Fetches a list of invoices based on filters. Handles "load more" functionality.
   * Pagination is not currently supported.
   */
  invoices: InvoiceData;
  isImagePublished?: Maybe<Scalars['Boolean']['output']>;
  /** Is the user enroled for multi-factor authentication. */
  isMfaEnroled?: Maybe<Scalars['Boolean']['output']>;
  isPaymentLinkInvoicePaid: PaymentLinkInvoicePaidResponse;
  job: JobResponse;
  jobs: JobsResponse;
  localisedContactInfoV2: LocalisedContactInfo;
  location?: Maybe<Location>;
  locations: Array<Maybe<LocationSearchResult>>;
  locationsByIds: Array<Maybe<Location>>;
  /** Get notification preference for current logged in user for a specific job */
  notificationPreference: NotificationPreference;
  order?: Maybe<Order>;
  orderSummary?: Maybe<OrderSummary>;
  payment2c2pInquiry?: Maybe<Payment2c2pInquiryPayload>;
  payment2c2pInquiryForInvoices?: Maybe<Payment2c2pInquiryPayload>;
  previousApplications: PreviousApplicationsResponse;
  /**
   * Retrieves a list of promo codes. If a productSetId is provided, the valid product display names
   * in the promo codes will be updated based on the specified product set.
   *
   * - productSetId (optional): The ID of the product set to use for updating valid product display names.
   */
  promoCodes: Array<PromoCode>;
  questionnaire: Questionnaire;
  registrationOptions: RegistrationOptions;
  registrationStatus: RegistrationStatusInfo;
  retrievePaymentIntent?: Maybe<RetrievePaymentIntentPayload>;
  roleReqsQuestionsByKeyword: RoleReqsQuestionsByKeywordResponse;
  /**
   * Searches for integration partners.
   *
   * The search can be filtered by a partners name, status, and release notes status.
   *
   * Valid values for status and releaseNotesStatus inputs are 'Published' and 'Draft'.
   */
  searchIntegrationPartners: Array<IntegrationPartner>;
  /** Query to get state information based on country code */
  stateByCountry: Array<State>;
  suggestedCategories: Array<CategorySuggestion>;
  suggestedSalary?: Maybe<SuggestedSalary>;
  talentSearchATSJobs?: Maybe<TalentSearchAtsJobsConnection>;
  talentSearchAccounts?: Maybe<TalentSearchAccounts>;
  talentSearchAssignedPools: Array<TalentSearchAssignedPools>;
  /** Fetches available jobs that has not been sent to the candidate (Version 2). */
  talentSearchAvailableJobsV2: Array<TalentSearchAvailableJobV2>;
  /** Fetches all jobs for an advertiser id. */
  talentSearchBudgetBalance: TalentSearchBudgetBalance;
  talentSearchConnectionOptions: ConnectionOptions;
  talentSearchConnectionStatus: ConnectionStatusPayload;
  talentSearchCvUrl: Scalars['String']['output'];
  talentSearchDownloadProfile: DownloadProfileResponse;
  talentSearchInteractions?: Maybe<TalentSearchInteractionsConnection>;
  /** Fetches job by Id for an advertiser (Version 2) */
  talentSearchJobByIdV2?: Maybe<TalentSearchJobV2>;
  /** Fetches all jobs for an advertiser id (Version 2). */
  talentSearchJobsV2?: Maybe<TalentSearchJobsV2>;
  talentSearchLatestInteractions: Array<TalentSearchLatestInteraction>;
  talentSearchMessageDetails: TalentSearchMessageDetails;
  talentSearchMetadataCompanySuggest: Array<Scalars['String']['output']>;
  talentSearchMetadataIndustries: Array<TalentSearchMetadataIndustry>;
  talentSearchMetadataMarkets: Array<TalentSearchMarket>;
  talentSearchMetadataQueryValidations?: Maybe<TalentSearchMetadataQueryValidations>;
  /** Get all SEEK, Combined or Partner profiles added to a pool */
  talentSearchPoolCandidatesV2: TalentSearchProfileResultsV2;
  talentSearchPools: Array<TalentSearchPool>;
  /** Get profile resume for SEEK, Combined or Partner profile */
  talentSearchProfileResume: TalentSearchProfileResumeResult;
  /** Get details for SEEK, Combined or Partner profile V2 */
  talentSearchProfileV2: TalentSearchProfileResultV2;
  /** Get details for SEEK profile by email */
  talentSearchProfilesByEmail: TalentSearchProfileResultsV2;
  /** Get recommended SEEK candidates powered by CQs recommendation algorithm for a job */
  talentSearchRecommendedCandidatesV2?: Maybe<TalentSearchProfileResultsV2>;
  talentSearchSavedSearches: Array<TalentSearchSavedSearch>;
  talentSearchSendJobStatus: Array<TalentSearchProfileSendJobStatus>;
  /** Get matching similar candidates for SEEK profile */
  talentSearchSimilarCandidatesByIdV2?: Maybe<TalentSearchSimilarCandidatesResultV2>;
  /** Get profiles count for uncoupled search query V2 */
  talentSearchUncoupledCountForQueryV2: Scalars['Int']['output'];
  /** Get V2 search results facets for uncoupled search query */
  talentSearchUncoupledCountsV2: TalentSearchCountsResults;
  /** Get search results for uncoupled search query with unified requirement */
  talentSearchUncoupledSearchV2: TalentSearchProfileResultsV2;
  /** Resolves user context data and relies on advertiserId in request headers */
  talentSearchUserContext: TalentSearchUserContext;
  /**
   * Resolves user context data for first available account or a specific account based on input parameters
   * @deprecated Use talentSearchUserContext
   */
  talentSearchUserContextV2: TalentSearchUserContext;
  /** Fetches all feature flags for the current user */
  talentSearchUserFeatures: Array<Maybe<TalentSearchFeature>>;
  uploadPresignedUrlForCandidateSupportDoc: UploadPresignedUrlForCandidateSupportDocResponse;
  validatePromoCode: ValidatePromoCodeResponse;
  yourCandidates: YourCandidatesResponse;
}

export interface Query_SavedStripeCreditCardsArgs {
  countryCode: Scalars['String']['input'];
}

export interface Query_AdBudgetProductsForUpsellArgs {
  input: AdBudgetProductsForUpsellInput;
}

export interface Query_AdProductsForAdEditingArgs {
  input: AdProductsForAdEditingInput;
}

export interface Query_AdProductsForAdPostingArgs {
  input: AdProductsForAdPostingInput;
}

export interface Query_AdUpgradeProductsForAdUpgradePurchaseArgs {
  input: AdUpgradeProductsForUpgradePurchaseInput;
}

export interface Query_AnalyticsAdPerformanceReportArgs {
  input: AdPerformanceReportWidgetInput;
}

export interface Query_AnalyticsAdRatingArgs {
  input: AdRatingWidgetInput;
}

export interface Query_AnalyticsAdTypeTrendArgs {
  input: AdUsageAdTypeTrendInput;
}

export interface Query_AnalyticsAdUsageConversionArgs {
  input: AdUsageConversionWidgetInput;
}

export interface Query_AnalyticsAdUsageFiltersArgs {
  input: AdUsageFiltersWidgetInput;
}

export interface Query_AnalyticsAdUsageSpendArgs {
  input: AdUsageSpendWidgetInput;
}

export interface Query_AnalyticsAdUsageTipsToImproveAdsArgs {
  input: TipsToImproveAdsWidgetInput;
}

export interface Query_AnalyticsApplicationPerformanceArgs {
  input: ApplicationPerformanceWidgetInput;
}

export interface Query_AnalyticsCandidateDemandArgs {
  input: CandidateDemandWidgetInput;
}

export interface Query_AnalyticsCandidateSupplyArgs {
  input: CandidateSupplyWidgetInput;
}

export interface Query_AnalyticsClassificationsAndUsersTrendArgs {
  input: AdUsageTrendClassificationsAndUsersInput;
}

export interface Query_AnalyticsClicksOnYourAdArgs {
  input: ClicksOnYourAdWidgetInput;
}

export interface Query_AnalyticsInsightsRoleAdInsightsTrendsArgs {
  input: AnalyticsInsightsRoleReportInput;
}

export interface Query_AnalyticsInsightsRoleAdPerformanceArgs {
  input: AnalyticsInsightsRoleReportInput;
}

export interface Query_AnalyticsInsightsRoleAdsPostedArgs {
  input: AnalyticsInsightsRoleReportInput;
}

export interface Query_AnalyticsInsightsRoleCandidateProfilesArgs {
  input: AnalyticsInsightsRoleCandidateProfilesInput;
}

export interface Query_AnalyticsInsightsRoleCompaniesPostingArgs {
  input: AnalyticsInsightsRoleReportInput;
}

export interface Query_AnalyticsInsightsRoleCompetitionForTalentArgs {
  input: AnalyticsInsightsRoleReportInput;
}

export interface Query_AnalyticsInsightsRoleLocationsArgs {
  input: AnalyticsInsightsRoleLocationsInput;
}

export interface Query_AnalyticsInsightsRoleNormalisedRolesArgs {
  input: AnalyticsInsightsRoleNormalisedRolesInput;
}

export interface Query_AnalyticsInsightsRoleRelatedRolesArgs {
  input: AnalyticsInsightsRoleRelatedRolesInput;
}

export interface Query_AnalyticsInsightsRoleSalaryDistributionArgs {
  reportInput: AnalyticsInsightsRoleReportInput;
  salaryInsightsInput: AnalyticsInsightsRoleSalaryInsightsInput;
}

export interface Query_AnalyticsInsightsRoleSalaryInsightsArgs {
  reportInput: AnalyticsInsightsRoleReportInput;
  salaryInsightsInput: AnalyticsInsightsRoleSalaryInsightsInput;
}

export interface Query_AnalyticsInsightsRoleSalaryPrivacyCheckArgs {
  reportInput: AnalyticsInsightsRoleReportInput;
  salaryInsightsInput: AnalyticsInsightsRoleSalaryInsightsInput;
}

export interface Query_AnalyticsInsightsRoleSalaryRangeArgs {
  reportInput: AnalyticsInsightsRoleReportInput;
  salaryInsightsInput: AnalyticsInsightsRoleSalaryInsightsInput;
}

export interface Query_AnalyticsInsightsRoleUniqueApplicantsArgs {
  input: AnalyticsInsightsRoleReportInput;
}

export interface Query_AnalyticsJobAdListArgs {
  input: JobAdListWidgetInput;
}

export interface Query_AnalyticsMarketInsightsTitleArgs {
  input: MarketInsightsTitleWidgetInput;
}

export interface Query_AnalyticsMarketPerformanceArgs {
  input: MarketPerformanceWidgetInput;
}

export interface Query_AnalyticsMostAdsByPerformanceArgs {
  input: MostAdsByPerformanceWidgetInput;
}

export interface Query_AnalyticsMyJobInformationArgs {
  input: MyJobInformationWidgetInput;
}

export interface Query_AnalyticsPageTitleActionsArgs {
  input: PageTitleActionsWidgetInput;
}

export interface Query_AnalyticsPerformancePredictionArgs {
  input: PerformancePredictionWidgetInput;
}

export interface Query_AnalyticsRecommendationsArgs {
  input: RecommendationsWidgetInput;
}

export interface Query_AnalyticsSimilarAdsDetailsArgs {
  input: SimilarAdsWidgetInput;
}

export interface Query_AnalyticsTalentSearchUsageConversionArgs {
  input: AnalyticsTalentSearchUsageConversionInput;
}

export interface Query_AnalyticsTalentSearchUsageFiltersArgs {
  input: AnalyticsTalentSearchUsageFiltersInput;
}

export interface Query_AnalyticsTalentSearchUsageOverviewArgs {
  input: AnalyticsTalentSearchUsageOverviewInput;
}

export interface Query_AnalyticsTalentSearchUsageTrendsByAccountArgs {
  input: AnalyticsTalentSearchUsageTrendsByAccountInput;
}

export interface Query_AnalyticsTalentSearchUsageTrendsByTimeArgs {
  input: AnalyticsTalentSearchUsageTrendsByTimeInput;
}

export interface Query_AnalyticsTalentSearchUsageUsersListArgs {
  input: AnalyticsTalentSearchUsageUsersListInput;
}

export interface Query_AnalyticsViewsInJobSearchArgs {
  input: ViewsInJobSearchWidgetInput;
}

export interface Query_ApplicationArgs {
  input: ApplicationInput;
}

export interface Query_ApplicationAttachmentsV2Args {
  input: ApplicationAttachmentsV2Input;
}

export interface Query_ApplicationByAdcentreProspectIdArgs {
  input: ApplicationByAdcentreProspectIdInput;
}

export interface Query_ApplicationEmailsArgs {
  input: ApplicationEmailsInput;
}

export interface Query_ApplicationNotesV2Args {
  input: ApplicationNotesInput;
}

export interface Query_ApplicationsArgs {
  input: ApplicationsInput;
}

export interface Query_ApplicationsFacetsArgs {
  input: ApplicationsFacetsInput;
}

export interface Query_BrandArgs {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface Query_BrandingAssetsArgs {
  input: GetBrandingAssetsInput;
}

export interface Query_BrandsArgs {
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface Query_CartArgs {
  cartId: Scalars['ID']['input'];
}

export interface Query_CompanyProfileArgs {
  id: Scalars['ID']['input'];
}

export interface Query_ContractBalancesArgs {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
}

export interface Query_DashboardJobsArgs {
  input?: InputMaybe<DashboardJobsInput>;
}

export interface Query_DefaultBrandArgs {
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface Query_DraftJobArgs {
  id: Scalars['ID']['input'];
}

export interface Query_DraftJobsArgs {
  input: DraftJobsInput;
}

export interface Query_FeatureFlagsArgs {
  keys: Array<Scalars['String']['input']>;
}

export interface Query_GetJobDetailsArgs {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['String']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface Query_GetPaymentConfigArgs {
  country: Scalars['String']['input'];
  site: Scalars['String']['input'];
  useCase?: InputMaybe<UseCase>;
}

export interface Query_GetPaymentMethodForMaintenanceArgs {
  country: Scalars['String']['input'];
  site: Scalars['String']['input'];
}

export interface Query_GetProductSetArgs {
  input: GetProductSetInput;
}

export interface Query_GetSurchargeAmountArgs {
  input: GetSurchargeAmountInput;
}

export interface Query_HeaderFooterContextArgs {
  input?: InputMaybe<HeaderFooterContextInput>;
}

export interface Query_HirerAccountArgs {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  usersParams?: InputMaybe<UsersParamsInput>;
}

export interface Query_HirerAccountUserArgs {
  input: HirerAccountUserQueryInput;
}

export interface Query_HirerAccountUsersWithJobPermissionsArgs {
  input: UsersWithJobPermissionInput;
}

export interface Query_HirerActivitiesByJobArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input: HirerActivitiesByJobInput;
}

export interface Query_HirerDashboardBudgetUpsellArgs {
  input: HirerDashboardBudgetUpsellInput;
}

export interface Query_HirerNotificationPreferencesArgs {
  locale: Scalars['String']['input'];
  zone: Scalars['String']['input'];
}

export interface Query_HirerUserArgs {
  input: HirerUserQueryInput;
}

export interface Query_HirerUserEmailV2Args {
  input: HirerUserEmailInputV2;
}

export interface Query_InvoicesArgs {
  filters?: InputMaybe<InvoiceFiltersInput>;
}

export interface Query_IsImagePublishedArgs {
  id: Scalars['String']['input'];
}

export interface Query_IsPaymentLinkInvoicePaidArgs {
  input: PaymentLinkInvoicePaidInput;
}

export interface Query_JobArgs {
  input: JobInput;
}

export interface Query_JobsArgs {
  input: JobsInput;
}

export interface Query_LocalisedContactInfoV2Args {
  input: LocalisedContactInfoInput;
}

export interface Query_LocationArgs {
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface Query_LocationsArgs {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<LocationContext>;
  countries?: InputMaybe<CountriesInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  excludeLocations?: InputMaybe<Array<Scalars['ID']['input']>>;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  queryText?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
}

export interface Query_LocationsByIdsArgs {
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface Query_NotificationPreferenceArgs {
  advertiserId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export interface Query_OrderArgs {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface Query_OrderSummaryArgs {
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
}

export interface Query_Payment2c2pInquiryArgs {
  currency: Scalars['String']['input'];
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
}

export interface Query_Payment2c2pInquiryForInvoicesArgs {
  currency: Scalars['String']['input'];
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
}

export interface Query_PreviousApplicationsArgs {
  input: PreviousApplicationsInput;
}

export interface Query_PromoCodesArgs {
  productSetId?: InputMaybe<Scalars['ID']['input']>;
}

export interface Query_QuestionnaireArgs {
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
}

export interface Query_RegistrationStatusArgs {
  input?: InputMaybe<RegistrationStatusInput>;
}

export interface Query_RetrievePaymentIntentArgs {
  country: Scalars['String']['input'];
  id: Scalars['String']['input'];
  paymentMethodType?: InputMaybe<StripePaymentMethod>;
}

export interface Query_RoleReqsQuestionsByKeywordArgs {
  input: RoleReqsQuestionsByKeywordInput;
}

export interface Query_SearchIntegrationPartnersArgs {
  input?: InputMaybe<SearchIntegrationPartnersInput>;
}

export interface Query_StateByCountryArgs {
  countryCode: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
}

export interface Query_SuggestedCategoriesArgs {
  country: Scalars['String']['input'];
  jobOrDraftId?: InputMaybe<Scalars['Int']['input']>;
  jobTitle: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  maxResults?: InputMaybe<Scalars['Int']['input']>;
}

export interface Query_SuggestedSalaryArgs {
  countryCode: Scalars['String']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  roleTitle: Scalars['String']['input'];
  salaryType?: InputMaybe<SalaryType>;
  workType?: InputMaybe<WorkType>;
}

export interface Query_TalentSearchAtsJobsArgs {
  input: TalentSearchAtsJobsInput;
}

export interface Query_TalentSearchAccountsArgs {
  input?: InputMaybe<TalentSearchAccountsInput>;
}

export interface Query_TalentSearchAssignedPoolsArgs {
  input?: InputMaybe<TalentSearchAssignedPoolsInput>;
}

export interface Query_TalentSearchAvailableJobsV2Args {
  input: TalentSearchAvailableJobsInput;
}

export interface Query_TalentSearchConnectionOptionsArgs {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  profileId: Scalars['Int']['input'];
}

export interface Query_TalentSearchConnectionStatusArgs {
  connectionId: Scalars['String']['input'];
}

export interface Query_TalentSearchCvUrlArgs {
  profileId: Scalars['Int']['input'];
}

export interface Query_TalentSearchDownloadProfileArgs {
  profileId: Scalars['Int']['input'];
}

export interface Query_TalentSearchInteractionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  profileId: Scalars['Int']['input'];
}

export interface Query_TalentSearchJobByIdV2Args {
  id: Scalars['Int']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface Query_TalentSearchJobsV2Args {
  input?: InputMaybe<TalentSearchJobsInput>;
}

export interface Query_TalentSearchLatestInteractionsArgs {
  profileIds: Array<Scalars['Int']['input']>;
}

export interface Query_TalentSearchMessageDetailsArgs {
  connectionId: Scalars['String']['input'];
}

export interface Query_TalentSearchMetadataCompanySuggestArgs {
  input?: InputMaybe<TalentSearchMetadataCompanySuggestInput>;
}

export interface Query_TalentSearchMetadataIndustriesArgs {
  input?: InputMaybe<TalentSearchMetadataIndustriesInput>;
}

export interface Query_TalentSearchMetadataMarketsArgs {
  input?: InputMaybe<TalentSearchMetadataMarketsInput>;
}

export interface Query_TalentSearchMetadataQueryValidationsArgs {
  input?: InputMaybe<TalentSearchMetadataQueryValidationsInput>;
}

export interface Query_TalentSearchPoolCandidatesV2Args {
  input: TalentSearchPoolCandidatesInputV2;
}

export interface Query_TalentSearchProfileResumeArgs {
  input: TalentSearchProfileResumeInput;
}

export interface Query_TalentSearchProfileV2Args {
  input: TalentSearchProfileInput;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface Query_TalentSearchProfilesByEmailArgs {
  input: ProfilesByEmailInput;
}

export interface Query_TalentSearchRecommendedCandidatesV2Args {
  input: TalentSearchRecommendedCandidatesInputV2;
}

export interface Query_TalentSearchSendJobStatusArgs {
  profileIds: Array<Scalars['Int']['input']>;
}

export interface Query_TalentSearchSimilarCandidatesByIdV2Args {
  input: TalentSearchSimilarCandidatesByIdInputV2;
}

export interface Query_TalentSearchUncoupledCountForQueryV2Args {
  input: TalentSearchUncoupledCountForQueryInputV2;
}

export interface Query_TalentSearchUncoupledCountsV2Args {
  input: TalentSearchUncoupledCountForQueryInputV2;
}

export interface Query_TalentSearchUncoupledSearchV2Args {
  input: TalentSearchUncoupledSearchInputV2;
}

export interface Query_TalentSearchUserContextArgs {
  input?: InputMaybe<TalentSearchUserContextV1Input>;
}

export interface Query_TalentSearchUserContextV2Args {
  input?: InputMaybe<TalentSearchUserContextInput>;
}

export interface Query_UploadPresignedUrlForCandidateSupportDocArgs {
  input: UploadPresignedUrlForCandidateSupportDocInput;
}

export interface Query_ValidatePromoCodeArgs {
  input: ValidatePromoCodeInput;
}

export interface Query_YourCandidatesArgs {
  input: YourCandidatesInput;
}

export interface Questionnaire {
  id: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  questions?: Maybe<Array<QuestionnaireQuestion>>;
}

export interface Questionnaire_QuestionsArgs {
  input?: InputMaybe<QuestionnaireQuestionFilter>;
}

export interface QuestionnaireAnswer {
  /** RR Answer Global ID */
  globalId?: Maybe<Scalars['String']['output']>;
  /**
   * RR Answer Local ID
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs
   */
  id?: Maybe<Scalars['String']['output']>;
  /** If true, represents that this answer is the inverse or 'negative' option to its siblings */
  isNegative: Scalars['Boolean']['output'];
  /** If true, represents that this answer is a preferred answer for the hirer */
  isPreferred: Scalars['Boolean']['output'];
  /** The display order of this answer in relation to that of its siblings */
  order: Scalars['Int']['output'];
  /** A statement that represents this answer */
  text: Scalars['String']['output'];
}

export interface QuestionnaireFilter {
  answerIds: Array<Scalars['ID']['input']>;
  operator: QuestionnaireFilterOperator;
  questionId: Scalars['ID']['input'];
}

export enum QuestionnaireFilterOperator {
  And = 'AND',
  Or = 'OR',
}

export interface QuestionnaireQuestion {
  /** The answer's type. e.g MultiSelect, SingleSelect, FreeText, UrlSingleSelect */
  answerType: Scalars['String']['output'];
  /** The answers to a question */
  answers?: Maybe<Array<QuestionnaireAnswer>>;
  /** If true represents that this question is a must-have requirement */
  essential?: Maybe<Scalars['Boolean']['output']>;
  /** RR Question Global ID */
  globalId?: Maybe<Scalars['String']['output']>;
  hirerExperience?: Maybe<HirerExperience>;
  /**
   * RR Question Local ID
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs
   */
  id?: Maybe<Scalars['String']['output']>;
  /** The display order of this question in relation to that of its siblings */
  order: Scalars['Int']['output'];
  /** Whether a question is library or custom */
  source: Scalars['String']['output'];
  /** A shorter, summarised version of the question being asked */
  statement?: Maybe<Scalars['String']['output']>;
  /** The question being asked */
  text: Scalars['String']['output'];
}

export enum QuestionnaireQuestionCategory {
  Salary = 'SALARY',
}

export interface QuestionnaireQuestionFilter {
  category?: InputMaybe<QuestionnaireQuestionCategory>;
}

export interface Range {
  maximum: Scalars['Int']['output'];
  minimum: Scalars['Int']['output'];
}

export interface RatingItem {
  description: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
}

export interface Reason {
  description: Scalars['String']['output'];
  flag: Scalars['String']['output'];
}

export type Recommendation =
  | BrandedAd
  | GranularLocation
  | LowApply
  | LowClick
  | LowSalary
  | RevealSalary
  | RoleRequirements
  | TalentSearch;

export enum RecommendationType {
  BrandedAd = 'BRANDED_AD',
  GranularLocation = 'GRANULAR_LOCATION',
  LowApply = 'LOW_APPLY',
  LowClick = 'LOW_CLICK',
  LowSalary = 'LOW_SALARY',
  RevealSalary = 'REVEAL_SALARY',
  RoleRequirements = 'ROLE_REQUIREMENTS',
  TalentSearch = 'TALENT_SEARCH',
}

export interface RecommendationsResponse {
  isExpired: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  postTypeCategory: AnalyticsPostTypeCategory;
  recommendations: Array<Recommendation>;
}

export interface RecommendationsWidgetInput {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface RedirectUrlInput {
  /** Language param to apply to the URL. */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Return URI to redirect to after selecting an account. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
}

export interface RefreshCartProductSetErrorPayload {
  message: Scalars['String']['output'];
}

export type RefreshCartProductSetResultPayload =
  | CartNotFoundError
  | ProductSetNotFoundError
  | RefreshCartProductSetSuccessPayload;

export interface RefreshCartProductSetSuccessPayload {
  cart: Cart;
  hasCartItemsBeenRemoved: Scalars['Boolean']['output'];
  hasPriceChanged: Scalars['Boolean']['output'];
}

export interface RefreshCartPromoCodeInput {
  cartId: Scalars['ID']['input'];
  promoCode: Scalars['String']['input'];
}

export interface RefreshJobInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export interface RefreshJobResponse {
  /** The key used to refresh the job */
  idempotencyKey: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  /** The id of the revision that was created for the job */
  revisionId: Scalars['String']['output'];
}

export interface RegisterAdditionalAdvertiserInput {
  /** New advertiser record to persist */
  advertiser: NewAdvertiser;
  /** Redirection uri for after signing into new account. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
  /** TEMP: Flag to indiciate user is primary contact on the account */
  setHirerUserToPrimaryContact?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface RegisterNewUserInput {
  /** New advertiser record to persist */
  advertiser: NewAdvertiser;
  /** Language param to apply additional localisation to auth related pages. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Redirection uri for after signing into new account. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
  /** TEMP: Flag to indiciate user is primary contact on the account */
  setHirerUserToPrimaryContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** New user record to persist */
  user: NewUser;
}

export interface RegisterPayload {
  /** Adcentre advertiser ID of new advertiser */
  advertiserId: Scalars['ID']['output'];
  /** Unified URL to sign into the advertiser account. Undefined for additional advertiser registrations. */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** SEEK ID of new advertiser */
  seekId: Scalars['ID']['output'];
}

export interface RegistrationOptions {
  /** Allows customer service to register new businesses on clients behalf */
  allowAgencyRegistration: Scalars['Boolean']['output'];
}

export interface RegistrationStatusInfo {
  /** Indicates whether the user exists in Adcentre DB. */
  userExists: Scalars['Boolean']['output'];
}

export interface RegistrationStatusInput {
  /** Filter to restrict returned results on user status */
  filter?: InputMaybe<Scalars['String']['input']>;
}

export interface ReinstateInput {
  /** The ID of the user to be reinstated */
  userId: Scalars['Int']['input'];
}

export interface ReinstateResponse {
  /** Response message we receive from requesting to reinstate a user status */
  message: Scalars['String']['output'];
}

export enum RelationshipStatus {
  Pending = 'pending',
  Rejected = 'rejected',
}

export interface RemoveApplicationNoteInput {
  noteId: Scalars['ID']['input'];
  prospectId: Scalars['ID']['input'];
}

export interface RemoveApplicationNotePayload {
  id: Scalars['ID']['output'];
}

export interface RemoveCartItemInput {
  cartId: Scalars['ID']['input'];
  referenceId: Scalars['String']['input'];
}

export type RemoveCartItemResultPayload =
  | CartNotFoundError
  | RemoveCartItemSuccessPayload;

export interface RemoveCartItemSuccessPayload {
  cart: Cart;
}

export interface RemoveCartPromoCodeInput {
  cartId: Scalars['ID']['input'];
}

export type RemoveCartPromoCodeResultPayload =
  | CartNotFoundError
  | RemoveCartPromoCodeSuccessPayload;

export interface RemoveCartPromoCodeSuccessPayload {
  cart: Cart;
}

export interface RemoveUserFromAccountInput {
  /** The ID of the user to be removed */
  userId: Scalars['Int']['input'];
}

export interface RepostOption {
  count: Scalars['Int']['output'];
  key: AdRepost;
}

export interface RequestIntegrationRelationshipsFailure {
  error: Scalars['String']['output'];
}

export interface RequestIntegrationRelationshipsFollowUpQuestionInput {
  answer: Scalars['String']['input'];
  followUpAnswer?: InputMaybe<Scalars['String']['input']>;
}

export interface RequestIntegrationRelationshipsInput {
  contactName: Scalars['String']['input'];
  futureApplications?: InputMaybe<RequestIntegrationRelationshipsFollowUpQuestionInput>;
  futurePosting?: InputMaybe<RequestIntegrationRelationshipsFollowUpQuestionInput>;
  goLiveDate: Scalars['String']['input'];
  hirerAccountIds: Array<Scalars['ID']['input']>;
  partnerId: Scalars['ID']['input'];
  relationshipTypeCodes: Array<IntegrationRelationshipTypeEnum>;
}

export type RequestIntegrationRelationshipsPayload =
  | RequestIntegrationRelationshipsFailure
  | RequestIntegrationRelationshipsSuccess;

export interface RequestIntegrationRelationshipsSuccess {
  partnerId: Scalars['ID']['output'];
  relationships: Array<RequestedIntegrationRelationship>;
}

export type RequestedIntegrationRelationship =
  | ApprovedIntegrationRelationship
  | PendingIntegrationRelationship;

export interface ResendAccountActivationEmailInput {
  /** Hirer Account to be serviced by the activation */
  hirerAccountId: Scalars['String']['input'];
}

export type ResendAccountActivationEmailResponse =
  | ResendAccountActivationEmailResponseSuccess
  | ResponseError;

export interface ResendAccountActivationEmailResponseSuccess {
  hirerAccountId: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  resent: Scalars['Boolean']['output'];
}

export interface ResponseError {
  error: ResponseErrorReason;
}

export enum ResponseErrorReason {
  BadInput = 'BAD_INPUT',
  Forbidden = 'FORBIDDEN',
  NotFound = 'NOT_FOUND',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export interface ResumeEducation {
  displayDate?: Maybe<Scalars['String']['output']>;
  institutionName?: Maybe<Scalars['String']['output']>;
  qualificationName?: Maybe<Scalars['String']['output']>;
}

export interface ResumeWorkHistory {
  companyName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayDescription: Array<ResumeWorkHistoryDisplayDescription>;
  duration?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  foundInCV: Scalars['Boolean']['output'];
  highlight?: Maybe<WorkHistoryHighlight>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
}

export interface ResumeWorkHistoryDisplayDescription {
  description: Scalars['String']['output'];
  isBullet: Scalars['Boolean']['output'];
}

export interface RetrievePaymentIntentPayload {
  amount?: Maybe<Scalars['SafeInt']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  virtualAccount?: Maybe<VirtualAccount>;
}

export interface RevealAdSalary extends TipToImproveAds {
  adCount: Scalars['Int']['output'];
  type: TipsToImproveAdsType;
}

export interface RevealSalary {
  currency: Scalars['String']['output'];
  type: RecommendationType;
}

export interface Review {
  companyId: Scalars['String']['output'];
  cons: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  overallRating: Scalars['Float']['output'];
  pros: Scalars['String']['output'];
  ratings: Array<RatingItem>;
  recommended: ReviewRecommended;
  reply?: Maybe<ReviewReply>;
  salarySummary: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  upvotes?: Maybe<Upvotes>;
  workLocation?: Maybe<Scalars['String']['output']>;
}

export interface ReviewAdDetails extends TipToImproveAds {
  adCount: Scalars['Int']['output'];
  benchmarkConversionRate?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  type: TipsToImproveAdsType;
}

export interface ReviewAdSummary extends TipToImproveAds {
  adCount: Scalars['Int']['output'];
  benchmarkConversionRate?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  type: TipsToImproveAdsType;
}

export interface ReviewRecommended {
  description: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
}

export interface ReviewReply {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  user?: Maybe<ReviewReplyUser>;
}

export interface ReviewReplyUser {
  userId?: Maybe<Scalars['String']['output']>;
}

export interface Reviews {
  data?: Maybe<Array<Review>>;
  paging?: Maybe<ReviewsPaging>;
}

export interface ReviewsPaging {
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
}

export interface RightToWork {
  id?: Maybe<Scalars['String']['output']>;
  isVerified: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  verifiedFields?: Maybe<Array<VerifiedField>>;
}

export interface RightToWorkInSavedSearch {
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
}

export interface RightToWorkInSavedSearch_LabelArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface RightToWorkInput {
  ids: Array<Scalars['String']['input']>;
  includeUnspecified: Scalars['Boolean']['input'];
}

export enum Role {
  /** An administrator. */
  Administrator = 'ADMINISTRATOR',
  /** A standard user. */
  User = 'USER',
}

export enum RoleReqsAnswerSelectionType {
  Checkbox = 'CHECKBOX',
  FreeText = 'FREE_TEXT',
  Radio = 'RADIO',
  Range = 'RANGE',
}

export enum RoleReqsAnswerSelectionTypeView {
  Answers = 'ANSWERS',
  SimplifiedAnswers = 'SIMPLIFIED_ANSWERS',
}

export enum RoleReqsAnswerType {
  FreeText = 'FREE_TEXT',
  MultiSelect = 'MULTI_SELECT',
  SingleSelect = 'SINGLE_SELECT',
  UrlSingleSelect = 'URL_SINGLE_SELECT',
}

export interface RoleReqsAvailableAnswer {
  /** The global ID of the answer */
  id: Scalars['String']['output'];
  /** Answer order */
  order: Scalars['Int']['output'];
  /** Flag if answer is preferred by hirer */
  preferred: Scalars['Boolean']['output'];
  /** Answer text */
  text: Scalars['String']['output'];
}

export interface RoleReqsMinimumRequiredAnswer {
  /** Acceptable answer global ID for mandatory question */
  id: Scalars['String']['output'];
  /** Acceptable answer text for mandatory question */
  text: Scalars['String']['output'];
}

export enum RoleReqsQuestionDescription {
  FreeText = 'FREE_TEXT',
  MultiSelect = 'MULTI_SELECT',
  Range = 'RANGE',
  RangeAtLeast = 'RANGE_AT_LEAST',
  RangeUpTo = 'RANGE_UP_TO',
  SingleSelectAny = 'SINGLE_SELECT_ANY',
  SingleSelectOnly = 'SINGLE_SELECT_ONLY',
}

export enum RoleReqsQuestionSource {
  Custom = 'CUSTOM',
  Library = 'LIBRARY',
}

export interface RoleReqsQuestionsByKeywordInput {
  /** Country code */
  country: Scalars['String']['input'];
  /** Keywords to search for */
  keywords: Scalars['String']['input'];
  /** Language code */
  languageId: Scalars['String']['input'];
  /** Questionnaire Id */
  questionnaireId: Scalars['String']['input'];
  /** List of selected questions */
  selectedQuestionIds?: InputMaybe<Array<RoleReqsSelectedQuestionId>>;
}

export type RoleReqsQuestionsByKeywordResponse =
  | ResponseError
  | RoleReqsQuestionsByKeywordSuccessResponse;

export interface RoleReqsQuestionsByKeywordSuccessResponse {
  /** Question hits */
  results: Array<RoleReqsRecommendedQuestion>;
  /** Total number of question hits */
  totalCount: Scalars['Int']['output'];
}

export interface RoleReqsRecommendedQuestion {
  /** How the question is to be answered */
  answerType: RoleReqsAnswerType;
  /** The complete collection of answers available for this question */
  availableAnswers?: Maybe<Array<RoleReqsAvailableAnswer>>;
  /** Flag to indicate if the question is certsy verifiable */
  certsyVerifiable: Scalars['Boolean']['output'];
  /** Description detailing how the hirer can select preferred answers */
  description: RoleReqsQuestionDescription;
  /** If true, enable selection type toggle if data allows */
  enableSelectionTypeToggle: Scalars['Boolean']['output'];
  /** Indicates if the question is required for the job */
  essential?: Maybe<Scalars['Boolean']['output']>;
  /** The unique ID of the question */
  id: Scalars['String']['output'];
  /** Indicates if the question is a bundling question */
  isBundlingQuestion?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the question is essential for filtering in Candidate Management */
  isEssentialFilters?: Maybe<Scalars['Boolean']['output']>;
  /** To show salary filter warning message if salary question is not selected */
  isSalaryFilters?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the question is a mandatory question */
  mandatory: Scalars['Boolean']['output'];
  /** Acceptable answers for mandatory question */
  minimumRequiredAnswers?: Maybe<Array<RoleReqsMinimumRequiredAnswer>>;
  /** The display order of this question in relation to that of its siblings */
  order: Scalars['Int']['output'];
  /** If true, this question has been selected */
  selected: Scalars['Boolean']['output'];
  /** The selection control that determines how the hirer will select preferred answers */
  selectionType: RoleReqsAnswerSelectionType;
  /** Answers or SimplifiedAnswers */
  selectionTypeView: RoleReqsAnswerSelectionTypeView;
  /** The complete collection of simplified answers available for this question */
  simplifiedAnswers?: Maybe<Array<RoleReqsSimplifiedAnswer>>;
  /** The question's origin (Library or Custom) */
  source: RoleReqsQuestionSource;
  /** The question statement */
  statement?: Maybe<Scalars['String']['output']>;
  /** The question text */
  text: Scalars['String']['output'];
}

export interface RoleReqsSelectedQuestionId {
  /** Question globalId */
  id: Scalars['String']['input'];
}

export interface RoleReqsSimplifiedAnswer {
  /** The global ID of the simplified answer */
  id: Scalars['String']['output'];
  /** Simplified answer order */
  order: Scalars['Int']['output'];
  /** Flag if simplified answer is preferred by hirer */
  preferred: Scalars['Boolean']['output'];
  /** Simplified answer text */
  text: Scalars['String']['output'];
}

export interface RoleRequirementFilterCount {
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
}

export interface RoleRequirements {
  roleReqsQuestions?: Maybe<Array<Scalars['String']['output']>>;
  type: RecommendationType;
}

/** RoleRequirementsMandatoryQuestions feature to indicate CPQA product */
export interface RoleRequirementsMandatoryQuestionsFeature
  extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questionAnswerSet: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface Rule {
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
}

export interface Salary {
  frequency: SalaryFrequency;
  includeUnspecified: Scalars['Boolean']['input'];
  range?: InputMaybe<SalaryRange>;
}

export interface SalaryAmountV2 {
  frequency: SalaryFrequency;
  value: Scalars['SafeInt']['output'];
}

export enum SalaryFrequency {
  Annual = 'ANNUAL',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
}

export interface SalaryInput {
  currency: JobSalaryCurrency;
  displayValue: Scalars['String']['input'];
  maximum: Scalars['SafeInt']['input'];
  minimum: Scalars['SafeInt']['input'];
  type: Scalars['String']['input'];
}

export interface SalaryRange {
  maximum?: InputMaybe<Scalars['SafeInt']['input']>;
  minimum?: InputMaybe<Scalars['SafeInt']['input']>;
}

export enum SalaryType {
  AnnualCommission = 'ANNUAL_COMMISSION',
  AnnualPackage = 'ANNUAL_PACKAGE',
  CommissionOnly = 'COMMISSION_ONLY',
  HourlyRate = 'HOURLY_RATE',
  Monthly = 'MONTHLY',
}

export interface SavedCreditCardPaymentError extends Error {
  /** For card errors resulting from a card issuer decline, a short string indicating the card issuer’s reason for the decline if they provide one. */
  declineCode?: Maybe<Scalars['String']['output']>;
  /** Short string indicating the error code reported. */
  errorCode?: Maybe<Scalars['String']['output']>;
  /** A human-readable message providing more details about the error. */
  message: Scalars['String']['output'];
  /** If the error is parameter-specific, the parameter related to the error. For example, you can use this to display a message near the correct form field. */
  param?: Maybe<Scalars['String']['output']>;
  /** Id of the failed payment intent. */
  paymentIntentId: Scalars['String']['output'];
  /** The status of the payment intent. Examples: "requires_payment_method" "requires_confirmation" "requires_action" "processing" "requires_capture" "canceled". */
  paymentIntentStatus: Scalars['String']['output'];
}

export interface SavedCreditCardPaymentSuccessPayload {
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String']['output'];
  /** Id of the successful payment intent. */
  paymentIntentId: Scalars['String']['output'];
}

export interface SearchIntegrationPartnersInput {
  releaseNotesStatus?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface SecureLinkExpiredError extends Error {
  message: Scalars['String']['output'];
}

export interface SecureLinkInput {
  /** Temporary link GUID of SecurityLink entities. */
  link: Scalars['String']['input'];
}

export interface SecureLinkInvalidError extends Error {
  message: Scalars['String']['output'];
}

export type SecureLinkResponse =
  | SecureLinkExpiredError
  | SecureLinkInvalidError
  | SecureLinkSuccessResponse
  | SecureLinkUsedError;

export interface SecureLinkSuccessResponse {
  redirectUri: Scalars['String']['output'];
}

export interface SecureLinkUsedError extends Error {
  message: Scalars['String']['output'];
}

export interface SellingPoint {
  /** Additional information about a product */
  helpTip?: Maybe<HelpTip>;
  /** To determine whether to highlight the displayed selling point or not */
  isHighlighted: Scalars['Boolean']['output'];
  /** Description of a selling point */
  name: Scalars['String']['output'];
}

export interface SendAccessEventPayload {
  success: Scalars['Boolean']['output'];
}

export interface SendApplicationEmailInput {
  enableReplies: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  prospectId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
}

export interface SendBulkApplicationEmailInput {
  enableReplies: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  prospectIds: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
}

export interface SendBulkApplicationEmailPayload {
  success: Scalars['Boolean']['output'];
}

export interface SendEmailResponse {
  /** Response message we receive from requesting the password and registration emails to be sent. */
  message?: Maybe<Scalars['String']['output']>;
}

export interface SendEmailVerificationEmailPayload {
  /** Indicates whether a user has already verified their email address. */
  emailVerified: Scalars['Boolean']['output'];
  /** Message of resource api response. */
  msg: Scalars['String']['output'];
  /** Success status of sending email. */
  success: Scalars['Boolean']['output'];
}

export interface SendEnrolmentEmailPayload {
  /** success of sending Enrolment Email */
  isEnrolmentEmailSent: Scalars['Boolean']['output'];
}

export interface SendHirerAccountUsersJobAccessEmailInput {
  /** The job advertiser id */
  advertiserId: Scalars['ID']['input'];
  /** Custom email message written by the sender */
  emailMessage?: InputMaybe<Scalars['String']['input']>;
  /** If set to true, sender will be included in the email cc list */
  includeSender?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the job that users are to be notified that they have access to */
  jobId: Scalars['ID']['input'];
  /** A list of team members who are invited to access the job */
  receivers: Array<AccessEmailReceiverInput>;
}

export interface SendHirerAccountUsersJobAccessEmailPayload {
  /** Success status of sending email. Returns true if send email operation is accepted. */
  success: Scalars['Boolean']['output'];
}

export interface SendJob {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export type SendJobState =
  | AllJobsSent
  | ConnectionPending
  | InsufficientCredits
  | JobSent
  | NoJobs
  | SendJob;

export interface SendLoginCallbackEventPayload {
  /** Success status of processing callback event. */
  success: Scalars['Boolean']['output'];
}

export interface SendMessage {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export type SendMessageState =
  | ConnectionPending
  | InsufficientCredits
  | SendMessage
  | ViewSentMessage;

export interface SendPasswordResetEmailInput {
  /** Email address to send password reset email to. */
  email: Scalars['String']['input'];
}

export interface SendRegistrationEmailInput {
  /** Is the user in Customer Service view. */
  isInCSView: Scalars['Boolean']['input'];
  /** ID of user to send registration email to. */
  userId: Scalars['ID']['input'];
}

export interface SetDefaultNotificationPreferenceInput {
  /** Id of the job to set notification preferences. */
  jobId: Scalars['ID']['input'];
}

export interface SetHirerAccountUsersJobAccessInput {
  /** List of user job access settings */
  accessList: Array<UserJobAccessInput>;
  /** The advertiser id that users tied to the account. */
  advertiserId: Scalars['ID']['input'];
  /**
   * If set true, delay updating to wait position is synchronized in adCentre.
   * If set false, update job access immediately
   */
  delayUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  /** The job id to set users access to */
  jobId: Scalars['ID']['input'];
}

export interface SetHirerAccountUsersJobAccessPayload {
  /** Success status of set user job access operation. Returns true if set job access operation is accepted. */
  success: Scalars['Boolean']['output'];
}

export interface SetNotificationPreferencesInput {
  /** The job advertiser id */
  advertiserId: Scalars['ID']['input'];
  /**
   * If set true, delay updating to wait position is synchronized in adCentre.
   * If set false, update notification preferences immediately
   */
  delayUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the job to set notification preferences. */
  jobId: Scalars['ID']['input'];
  /** Notification preferences to be set for account users. */
  preferences: Array<NotificationPreferenceInput>;
}

export interface SetNotificationPreferencesPayload {
  /** Success status of setting notification preferences operation. Returns true if set notification preferences operation is accepted. */
  success: Scalars['Boolean']['output'];
}

export interface SimilarAdsDetails {
  advertiserName: Scalars['String']['output'];
  analyticsProductDisplayName: Scalars['String']['output'];
  hasKeySellingPoints: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  isSalaryHidden: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobId: Scalars['String']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  serpLogoUrl?: Maybe<Scalars['String']['output']>;
  showProductBadge: Scalars['Boolean']['output'];
}

export interface SimilarAdsResponse {
  ads: Array<SimilarAdsDetails>;
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
}

export interface SimilarAdsWidgetInput {
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface SimplifiedAnswers {
  /** The global ids of the granular answers that the given simplified answer maps to */
  answerGlobalIds?: Maybe<Array<Scalars['String']['output']>>;
  /** The local ids of the granular answers that the given simplified answer maps to */
  answerIds?: Maybe<Array<Scalars['String']['output']>>;
  /** The simplified answer global id */
  globalId?: Maybe<Scalars['String']['output']>;
  /**
   * The simplified answer local id
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs.
   */
  id?: Maybe<Scalars['String']['output']>;
  /** True if the hirer selected the answer during CAJA */
  isPreferred: Scalars['Boolean']['output'];
  /** The display order of the answer, low to high */
  order: Scalars['Int']['output'];
  /** The simplified answer text */
  text: Scalars['String']['output'];
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Product feature that indicates Sponsored Slot feature properties available for ad product */
export interface SponsoredSlotFeature extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface State {
  /** Country of the state */
  country?: Maybe<CountryType>;
  /** Full name of the state */
  defaultName: Scalars['String']['output'];
  /** Whether the state information is deprecated or not */
  deprecated: Scalars['Boolean']['output'];
  /** Type of the location, eg. "State" / "Country" */
  kind: Scalars['String']['output'];
}

export interface StripePaymentIntentPayload {
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String']['output'];
  /** Unique identifier for payment intent object */
  id: Scalars['String']['output'];
  /** A url that the customer can use to complete payment. If a url has not been set up for that market, this field will be omitted. */
  paymentLink?: Maybe<Scalars['String']['output']>;
  /** The base64 image data for the QR code */
  qrCodeUrl?: Maybe<Scalars['String']['output']>;
  /** The payment reference for the payment intent. To be used only on invoices. */
  reference?: Maybe<Scalars['String']['output']>;
}

export enum StripePaymentMethod {
  Card = 'card',
  Fpx = 'fpx',
  IdBankTransfer = 'id_bank_transfer',
  Paynow = 'paynow',
  Promptpay = 'promptpay',
}

/** Product feature that indicates border available for ad product */
export interface StyleEnhancementFeature extends CatalogueProductFeature {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  style: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface SubTitle {
  style: Scalars['String']['output'];
  text: Scalars['String']['output'];
}

export interface Subject {
  /**
   * Email address of the subject.
   * @deprecated Use email query
   */
  email: Scalars['String']['output'];
  /**
   * Indicates whether a subject has verified their email address.
   * @deprecated Use emailVerified query
   */
  emailVerified: Scalars['Boolean']['output'];
  /** The Auth0 user id of the subject, eg 'auth0|12eb841a02c489d1'. */
  id: Scalars['String']['output'];
  /**
   * The associated adcentre user id. This may be null if a corresponding adcentre user has not yet been
   * created, or accounts are not associated correctly.
   */
  userId?: Maybe<Scalars['String']['output']>;
}

export interface SuggestedSalary {
  averageRange?: Maybe<SuggestedSalaryAverageRange>;
  countryCode?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['Int']['output']>;
  range?: Maybe<SuggestedSalaryRange>;
  roleTitle?: Maybe<Scalars['String']['output']>;
  salaryType?: Maybe<SalaryType>;
  workType?: Maybe<WorkType>;
}

export interface SuggestedSalaryAverageRange {
  max?: Maybe<Scalars['SafeInt']['output']>;
  median?: Maybe<Scalars['SafeInt']['output']>;
  min?: Maybe<Scalars['SafeInt']['output']>;
}

export interface SuggestedSalaryRange {
  max?: Maybe<Scalars['SafeInt']['output']>;
  min?: Maybe<Scalars['SafeInt']['output']>;
}

export interface Surcharge {
  /** The surcharge amount. */
  amount: Money;
  /** The type of surcharge. Ex, AmexSurcharge */
  surchargeType: Scalars['String']['output'];
}

export interface TalentSearch {
  hirerId: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  type: RecommendationType;
}

export interface TalentSearchAtsJob {
  externalReferenceId: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  jobLocations?: Maybe<Array<Scalars['String']['output']>>;
  jobOwners?: Maybe<Array<TalentSearchJobOwner>>;
  jobTitle: Scalars['String']['output'];
  positionProfileName?: Maybe<Scalars['String']['output']>;
  seekHirerJobReference?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchAtsJobEdge {
  cursor?: Maybe<Scalars['String']['output']>;
  node: TalentSearchAtsJob;
}

export interface TalentSearchAtsJobs {
  count: Scalars['Int']['output'];
  items?: Maybe<Array<TalentSearchAtsJob>>;
}

export interface TalentSearchAtsJobsConnection {
  edges: Array<TalentSearchAtsJobEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface TalentSearchAtsJobsInput {
  after?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  jobOwnerName?: InputMaybe<Scalars['String']['input']>;
  jobTitle: Scalars['String']['input'];
}

export enum TalentSearchAccess {
  Disabled = 'DISABLED',
  Premium = 'PREMIUM',
  Standard = 'STANDARD',
  SystemDown = 'SYSTEM_DOWN',
}

export interface TalentSearchAccount {
  advertiserId: Scalars['Int']['output'];
  advertiserName: Scalars['String']['output'];
  companyAddress?: Maybe<CompanyAddress>;
  hasConnectedTalentSearchAccess: Scalars['Boolean']['output'];
  hasPremiumTalentSearchAccess: Scalars['Boolean']['output'];
  parentAdvertiserId?: Maybe<Scalars['Int']['output']>;
  /** The URL where the user will be redirected to after selecting the account. */
  redirectUrl: Scalars['String']['output'];
  seekBillingAccountId: Scalars['String']['output'];
}

export interface TalentSearchAccount_RedirectUrlArgs {
  input: ConnectedTalentSearchRedirectUrlInput;
}

export interface TalentSearchAccounts {
  count: Scalars['Int']['output'];
  items: Array<TalentSearchAccount>;
}

export interface TalentSearchAccountsInput {
  orderBy?: InputMaybe<TalentSearchAccountsOrderBy>;
  query?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}

export interface TalentSearchAccountsOrderBy {
  direction: TalentSearchAccountsOrderByDirection;
  field: TalentSearchAccountsOrderByField;
}

export enum TalentSearchAccountsOrderByDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum TalentSearchAccountsOrderByField {
  AdvertiserId = 'advertiserId',
  AdvertiserName = 'advertiserName',
}

export interface TalentSearchAddOrRemoveProfileTalentPoolsInput {
  profileId: Scalars['String']['input'];
  values: Array<
    InputMaybe<TalentSearchAddOrRemoveProfileTalentPoolsValuesInput>
  >;
}

export interface TalentSearchAddOrRemoveProfileTalentPoolsValuesInput {
  addToPool: Scalars['Boolean']['input'];
  poolId: Scalars['String']['input'];
  searchId?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchAdvertiser {
  accountNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isApproved: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentAdvertiserId?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<TalentSearchHirerAccountTags>;
}

export interface TalentSearchAssignedPool {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface TalentSearchAssignedPools {
  assignedPools: Array<TalentSearchAssignedPool>;
  profileId: Scalars['ID']['output'];
}

export interface TalentSearchAssignedPoolsInput {
  profileIds: Array<Scalars['Int']['input']>;
}

export interface TalentSearchAtsHirerRelationship {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface TalentSearchAvailableJobV2 {
  expiry: Scalars['String']['output'];
  id: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface TalentSearchAvailableJobsInput {
  /** Limits the number of available jobs in the response. Default is set to 10. */
  maxJobs?: InputMaybe<Scalars['Int']['input']>;
  /** The profile id to retrieve sent jobs with. */
  profileId: Scalars['Int']['input'];
  /** Filters jobs based on the title and reference fields. */
  query?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchBudgetBalance {
  /** Current budget balance for an advertiser or job. */
  currentBalance: Scalars['Int']['output'];
  /** Initial budget balance for an advertiser or job. */
  initialBalance: Scalars['Int']['output'];
}

export interface TalentSearchCandidateJobInvitationsBalance {
  /** Current balance of candidate invitations for a job. */
  currentBalance: Scalars['Int']['output'];
  /** Initial balance of candidate invitations for a job. */
  initialBalance: Scalars['Int']['output'];
}

export enum TalentSearchClaim {
  ConnectedTalentSearch = 'CONNECTED_TALENT_SEARCH',
  PremiumTalentSearch = 'PREMIUM_TALENT_SEARCH',
  StandardTalentSearch = 'STANDARD_TALENT_SEARCH',
}

export interface TalentSearchConnectionAvailability {
  connectionId?: Maybe<Scalars['String']['output']>;
  connectionType: Scalars['Int']['output'];
  connectionTypeName: Scalars['String']['output'];
  createdDateUtc?: Maybe<Scalars['String']['output']>;
  isUsed: Scalars['Boolean']['output'];
  nextAvailableDateUtc?: Maybe<Scalars['String']['output']>;
}

export enum TalentSearchConnectionErrorReason {
  BadRequestConnectionAlreadyAdded = 'BAD_REQUEST_CONNECTION_ALREADY_ADDED',
  BadRequestExceededThrottlingLimit = 'BAD_REQUEST_EXCEEDED_THROTTLING_LIMIT',
  BadRequestInsufficientCredits = 'BAD_REQUEST_INSUFFICIENT_CREDITS',
  BadRequestInvalidProfilePrivacy = 'BAD_REQUEST_INVALID_PROFILE_PRIVACY',
  BadRequestNoConnection = 'BAD_REQUEST_NO_CONNECTION',
  BadRequestNoCredits = 'BAD_REQUEST_NO_CREDITS',
  BadRequestNoPhoneNumber = 'BAD_REQUEST_NO_PHONE_NUMBER',
  BadRequestNoProfile = 'BAD_REQUEST_NO_PROFILE',
  BadRequestNoResume = 'BAD_REQUEST_NO_RESUME',
  BadRequestResumeFound = 'BAD_REQUEST_RESUME_FOUND',
}

export enum TalentSearchConnectionOrigin {
  CandimanCoupledSearch = 'CANDIMAN_COUPLED_SEARCH',
  SimilarCandidates = 'SIMILAR_CANDIDATES',
  SingleProfile = 'SINGLE_PROFILE',
  TalentsearchCoupledSearch = 'TALENTSEARCH_COUPLED_SEARCH',
  TalentPool = 'TALENT_POOL',
  UncoupledSearch = 'UNCOUPLED_SEARCH',
  Unknown = 'UNKNOWN',
}

export interface TalentSearchConnectionResponseError {
  reason: TalentSearchConnectionErrorReason;
}

export enum TalentSearchConnectionStatus {
  Pending = 'pending',
  Redeemed = 'redeemed',
  Rejected = 'rejected',
}

export interface TalentSearchContract {
  endDate: Scalars['String']['output'];
  productType: TalentSearchContractType;
  startDate: Scalars['String']['output'];
}

export interface TalentSearchContractBalance {
  /** Current balance for a contract. */
  currentBalance: Scalars['Int']['output'];
  /** Initial balance for a contract. */
  initialBalance: Scalars['Int']['output'];
}

export type TalentSearchContractBalanceResponse =
  | ResponseError
  | TalentSearchContractBalance
  | TalentSearchUnavailableContractBalance;

export enum TalentSearchContractType {
  ConnectedTalentSearch = 'CONNECTED_TALENT_SEARCH',
  PremiumTalentSearch = 'PREMIUM_TALENT_SEARCH',
  PremiumTalentSearchTrial = 'PREMIUM_TALENT_SEARCH_TRIAL',
}

export interface TalentSearchCountResult {
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
}

export interface TalentSearchCountResultWithLabel {
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchCountsResults {
  approachabilitysignal?: Maybe<Array<TalentSearchCountResult>>;
  company?: Maybe<Array<TalentSearchCountResult>>;
  currentLocation?: Maybe<Array<TalentSearchCountResult>>;
  industry?: Maybe<Array<TalentSearchCountResult>>;
  rightToWork?: Maybe<Array<TalentSearchCountResultWithLabel>>;
  salary?: Maybe<Array<TalentSearchCountResult>>;
}

export interface TalentSearchCountsResults_RightToWorkArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchCreateTalentPoolInput {
  name: Scalars['String']['input'];
}

export interface TalentSearchCriteria {
  approachable?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<Array<Scalars['String']['output']>>;
  countryCode?: Maybe<Scalars['String']['output']>;
  cv?: Maybe<Scalars['Boolean']['output']>;
  industry?: Maybe<Array<Scalars['String']['output']>>;
  keywords?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  locationDisplayTexts: Array<Scalars['String']['output']>;
  locationList: Array<Scalars['Int']['output']>;
  maxSalary?: Maybe<Scalars['Int']['output']>;
  minSalary?: Maybe<Scalars['Int']['output']>;
  nation?: Maybe<Scalars['Int']['output']>;
  profileTypes?: Maybe<Array<ProfileSource>>;
  rightToWorkList?: Maybe<Array<RightToWorkInSavedSearch>>;
  rightToWorkUnspecified?: Maybe<Scalars['Boolean']['output']>;
  salaryNation?: Maybe<Scalars['Int']['output']>;
  salaryType?: Maybe<Scalars['String']['output']>;
  salaryUnspecified?: Maybe<Scalars['Boolean']['output']>;
  uncoupledFreeText: Scalars['String']['output'];
  /** @deprecated Will be removed once granular right to work is implemented */
  visa?: Maybe<Array<Scalars['String']['output']>>;
  willingToRelocate?: Maybe<Scalars['Boolean']['output']>;
  workType?: Maybe<Array<Scalars['String']['output']>>;
}

export interface TalentSearchCriteria_LocationDisplayTextsArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchCriteriaInput {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Array<Scalars['String']['input']>>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['String']['input']>;
  locationList: Array<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  nation?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<Array<ProfileSource>>;
  rightToWorkIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWorkUnspecified?: InputMaybe<Scalars['Boolean']['input']>;
  salaryNation?: InputMaybe<Scalars['Int']['input']>;
  salaryType?: InputMaybe<Scalars['String']['input']>;
  salaryUnspecified?: InputMaybe<Scalars['Boolean']['input']>;
  uncoupledFreeText: Scalars['String']['input'];
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface TalentSearchDeleteTalentPoolInput {
  poolId: Scalars['String']['input'];
}

export interface TalentSearchFeature {
  /** Whether or not the feature flag is active */
  active: Scalars['Boolean']['output'];
  /** Unique identifier of feature flag */
  key: Scalars['String']['output'];
  /** Name of feature flag */
  name: Scalars['String']['output'];
}

export interface TalentSearchHirerAccountTags {
  recordExpiry?: Maybe<Scalars['String']['output']>;
  testBehaviours?: Maybe<Scalars['String']['output']>;
  testRecord: Scalars['String']['output'];
  testScope?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchHirerContactDetails {
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
}

export interface TalentSearchInteraction {
  associatedAction: AssociatedAction;
  associatedParties?: Maybe<Array<Maybe<AssociatedParty>>>;
  id: Scalars['String']['output'];
  interactionType: Scalars['String']['output'];
  positionProfileId?: Maybe<Scalars['String']['output']>;
  seekSourceName?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchInteractionEdge {
  cursor?: Maybe<Scalars['String']['output']>;
  node: TalentSearchInteraction;
}

export interface TalentSearchInteractionsConnection {
  edges: Array<TalentSearchInteractionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export type TalentSearchJobBalance =
  | ResponseError
  | TalentSearchCandidateJobInvitationsBalance
  | TalentSearchJobCreditsBalance
  | TalentSearchUnavailableJobBalance;

export enum TalentSearchJobBudgetUnavailableReason {
  /** An error was encountered fetching the budget. */
  BudgetError = 'BUDGET_ERROR',
  /** Job budget does not apply, likely it's a pre-tilt Ad for non-STS hirer. */
  BudgetNotApplicable = 'BUDGET_NOT_APPLICABLE',
  /** The job has no budget, likely it's a lite ad. */
  BudgetNotFound = 'BUDGET_NOT_FOUND',
}

export interface TalentSearchJobCreditsBalance {
  /** Current balance of credits for a job. */
  currentBalance: Scalars['Int']['output'];
  /** Initial balance of credits for a job. */
  initialBalance: Scalars['Int']['output'];
}

export interface TalentSearchJobLocationV2 {
  countryCode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
}

export interface TalentSearchJobOwner {
  firstName?: Maybe<Scalars['String']['output']>;
  formattedName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchJobSalaryV2 {
  frequency: SalaryFrequency;
}

export interface TalentSearchJobV2 {
  /** The associated advertiser id for the job. */
  advertiserId: Scalars['String']['output'];
  /**
   * Current balance for this job retrieved from budgets API
   * @deprecated Use balanceV2 instead
   */
  balance: TalentSearchBudgetBalance;
  /** Current balance for this job in connections or credits. */
  balanceV2: TalentSearchJobBalance;
  /** Date in ISO format when job was originally posted */
  createdDate: Scalars['String']['output'];
  /** Date in ISO format when job will expire */
  expiryDate: Scalars['String']['output'];
  /** The projected job id. */
  id: Scalars['String']['output'];
  /** Whether job is upgradable */
  isUpgradable?: Maybe<Scalars['Boolean']['output']>;
  /** Location of the job */
  location: TalentSearchJobLocationV2;
  /** An optional reference provided for the job. */
  reference?: Maybe<Scalars['String']['output']>;
  /** Salary details of the job */
  salary: TalentSearchJobSalaryV2;
  /** Whether the job is Active or Inactive. */
  status: Scalars['String']['output'];
  /** The title of the job. */
  title: Scalars['String']['output'];
}

export interface TalentSearchJobV2_LocationArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchJobsInput {
  /** Returns jobs with both "Active" and "Expired" status. */
  includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specify langugage for translated location */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** Limits the number of jobs in the response. */
  maxJobs?: InputMaybe<Scalars['Int']['input']>;
  /** Order jobs by field and direction (asc, desc) */
  orderBy?: InputMaybe<TalentSearchJobsOrderBy>;
  /** Filters jobs based on the title and reference fields. */
  query?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchJobsOrderBy {
  direction: TalentSearchJobsOrderByDirection;
  field: TalentSearchJobsOrderByField;
}

export enum TalentSearchJobsOrderByDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum TalentSearchJobsOrderByField {
  Listed = 'listed',
}

export interface TalentSearchJobsV2 {
  jobs: Array<TalentSearchJobV2>;
  totalJobs: Scalars['Int']['output'];
}

export interface TalentSearchLatestInteraction {
  count: Scalars['Int']['output'];
  interaction?: Maybe<TalentSearchInteraction>;
  profileId: Scalars['Int']['output'];
}

export interface TalentSearchMarket {
  countryCode: Scalars['String']['output'];
  countryName: Scalars['String']['output'];
}

export interface TalentSearchMessageDetails {
  body: Scalars['String']['output'];
  hirer: TalentSearchHirerContactDetails;
  nextAvailableDate: Scalars['String']['output'];
  sentDate: Scalars['String']['output'];
  subject: Scalars['String']['output'];
}

export interface TalentSearchMetadataCompanySuggestInput {
  query: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
}

export enum TalentSearchMetadataCulture {
  Au = 'au',
  Nz = 'nz',
}

export interface TalentSearchMetadataIndustriesInput {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchMetadataIndustry {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subIndustries?: Maybe<Array<TalentSearchMetadataSubIndustry>>;
}

export interface TalentSearchMetadataMarketsInput {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchMetadataQueryValidations {
  stopwords: Array<Scalars['String']['output']>;
  whitelistRegexPattern: Scalars['String']['output'];
}

export interface TalentSearchMetadataQueryValidationsInput {
  region?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchMetadataSubIndustry {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface TalentSearchNationality {
  displayLabel: Scalars['String']['output'];
}

export interface TalentSearchNationality_DisplayLabelArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchPool {
  id: Scalars['String']['output'];
  lastUpdated: Scalars['String']['output'];
  name: Scalars['String']['output'];
  policy?: Maybe<Scalars['String']['output']>;
  profileCount: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
}

export interface TalentSearchPoolCandidatesInputV2 {
  countryCode: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  poolId: Scalars['String']['input'];
}

export interface TalentSearchProfileInput {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
}

export interface TalentSearchProfileResultV2 {
  actionLinks?: Maybe<ActionLink>;
  approachabilitySignal?: Maybe<Scalars['Int']['output']>;
  atsLastUpdatedDateUtc?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentIndustry?: Maybe<Scalars['String']['output']>;
  currentIndustryId?: Maybe<Scalars['String']['output']>;
  currentInferredIndustry?: Maybe<Scalars['String']['output']>;
  currentJobTitle?: Maybe<Scalars['String']['output']>;
  currentLocation?: Maybe<Scalars['String']['output']>;
  currentSubindustry?: Maybe<Scalars['String']['output']>;
  currentSubindustryId?: Maybe<Scalars['String']['output']>;
  digitalIdentity?: Maybe<DigitalIdentity>;
  firstName: Scalars['String']['output'];
  hasResume: Scalars['Boolean']['output'];
  hasVerifiedCredentials: Scalars['Boolean']['output'];
  highlight?: Maybe<ProfileResultHighlight>;
  homeLocationId?: Maybe<Scalars['String']['output']>;
  jobSeekerId: Scalars['Int']['output'];
  languages?: Maybe<Array<Scalars['String']['output']>>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  licences?: Maybe<Array<ProfileLicence>>;
  nationalities: Array<TalentSearchNationality>;
  noticePeriodDays?: Maybe<Scalars['Int']['output']>;
  partnerDoNotContact: Scalars['Boolean']['output'];
  pastJobTitles?: Maybe<Array<Scalars['String']['output']>>;
  profileEducation?: Maybe<Array<ProfileEducation>>;
  profileId: Scalars['Int']['output'];
  profilePrivacy: PrivacyTypes;
  profileType: ProfileTypes;
  resumeCoreDataAvailable?: Maybe<Scalars['Boolean']['output']>;
  resumeSnippet?: Maybe<Scalars['String']['output']>;
  rightToWork?: Maybe<RightToWork>;
  salary?: Maybe<ProfileSalaryV2>;
  sensitiveSearchTermsInResumeSnippet: Scalars['Boolean']['output'];
  sensitiveWordsFoundInResumeSnippet: Scalars['Boolean']['output'];
  skills?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  workHistories: Array<WorkHistory>;
  workTypes: Array<Scalars['String']['output']>;
}

export interface TalentSearchProfileResultV2_CurrentLocationArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchProfileResultV2_RightToWorkArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchProfileResultsV2 {
  count: Scalars['Int']['output'];
  items: Array<TalentSearchProfileResultV2>;
  searchIdentifier?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchProfileResumeInput {
  keywords?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
}

export interface TalentSearchProfileResumeResult {
  resumeEducation: Array<ResumeEducation>;
  resumeSkills: Array<Scalars['String']['output']>;
  resumeWorkHistories: Array<ResumeWorkHistory>;
}

export interface TalentSearchProfileSendJobStatus {
  profileId: Scalars['Int']['output'];
  status: TalentSearchSendJobStatus;
}

export enum TalentSearchQuerySalaryType {
  Annual = 'ANNUAL',
  HourlyRate = 'HOURLY_RATE',
  Monthly = 'MONTHLY',
}

export enum TalentSearchQuerySortBy {
  CreatedDate = 'CREATED_DATE',
  Freshness = 'FRESHNESS',
  ModifiedDate = 'MODIFIED_DATE',
  Relevance = 'RELEVANCE',
}

export interface TalentSearchRecommendedCandidatesInputV2 {
  countryCode: Scalars['String']['input'];
  jobId: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  userSessionId?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchSavedSearch {
  createdDate: Scalars['String']['output'];
  criteria: TalentSearchCriteria;
  criteriaHash: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export enum TalentSearchSendJobStatus {
  AllJobsSent = 'ALL_JOBS_SENT',
  HasAvailableJobs = 'HAS_AVAILABLE_JOBS',
  NoActiveJobs = 'NO_ACTIVE_JOBS',
}

export interface TalentSearchShareTalentPoolInput {
  policy: Scalars['String']['input'];
  poolId: Scalars['String']['input'];
}

export interface TalentSearchSimilarCandidatesByIdInputV2 {
  countryCode: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isSeedProfileLocationOnly?: InputMaybe<Scalars['Boolean']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchSimilarCandidatesResultV2 {
  profiles: TalentSearchProfileResultsV2;
  seedProfile?: Maybe<TalentSearchProfileResultV2>;
}

export interface TalentSearchUnavailableContractBalance {
  _?: Maybe<Scalars['Boolean']['output']>;
}

export interface TalentSearchUnavailableJobBalance {
  /** Reason that budget is unavailable. */
  reason: TalentSearchJobBudgetUnavailableReason;
}

export interface TalentSearchUncoupledCountForQueryInputV2 {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  locationList?: InputMaybe<Array<Scalars['String']['input']>>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWork?: InputMaybe<RightToWorkInput>;
  salary?: InputMaybe<Salary>;
  sortBy?: InputMaybe<TalentSearchQuerySortBy>;
  uncoupledFreeText: Scalars['String']['input'];
  /** @deprecated use rightToWork instead */
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface TalentSearchUncoupledSearchInputV2 {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['Int']['input']>;
  locationList?: InputMaybe<Array<Scalars['String']['input']>>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWork?: InputMaybe<RightToWorkInput>;
  salary?: InputMaybe<Salary>;
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<TalentSearchQuerySortBy>;
  uncoupledFreeText: Scalars['String']['input'];
  userSessionId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated use rightToWork instead */
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface TalentSearchUpdateTalentPoolInput {
  name: Scalars['String']['input'];
  poolId: Scalars['String']['input'];
}

export interface TalentSearchUser {
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
}

export interface TalentSearchUserContext {
  advertiser: TalentSearchAdvertiser;
  ats?: Maybe<TalentSearchAtsHirerRelationship>;
  claims: Array<TalentSearchClaim>;
  /** The contract balance for a PTS/TSC advertiser. Will be unavailable for STS hirers. */
  contractBalance: TalentSearchContractBalanceResponse;
  contracts: Array<TalentSearchContract>;
  /** The user ID of the user making the request. Helps with caching. */
  id: Scalars['ID']['output'];
  productType?: Maybe<TalentSearchClaim>;
  user: TalentSearchUser;
}

export interface TalentSearchUserContextInput {
  /** @deprecated Use value from token */
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use value from token */
  preferredAdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Not in use */
  productType?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchUserContextV1Input {
  jobId?: InputMaybe<Scalars['ID']['input']>;
}

export interface Tax {
  amount?: Maybe<Money>;
  code: TaxCode;
  description?: Maybe<Scalars['String']['output']>;
  displayRecommendation?: Maybe<DisplayRecommendation>;
  rate?: Maybe<Scalars['Int']['output']>;
}

export enum TaxCode {
  Gst = 'GST',
  Ppn = 'PPN',
  Sst = 'SST',
  Vat = 'VAT',
}

export interface TipToImproveAds {
  adCount: Scalars['Int']['output'];
  type: TipsToImproveAdsType;
}

export interface TipsToImproveAdsResponse {
  numOfAds: Scalars['Int']['output'];
  tips?: Maybe<Array<Maybe<TipToImproveAds>>>;
}

export enum TipsToImproveAdsType {
  AddQuestions = 'ADD_QUESTIONS',
  AddSpecificLocation = 'ADD_SPECIFIC_LOCATION',
  MaximiseBranding = 'MAXIMISE_BRANDING',
  RevealSalary = 'REVEAL_SALARY',
  ReviewAdDetails = 'REVIEW_AD_DETAILS',
  ReviewAdSummary = 'REVIEW_AD_SUMMARY',
}

export interface TipsToImproveAdsWidgetInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  nonSpecificLocationShortId: Scalars['Int']['input'];
  specificLocationShortId: Scalars['Int']['input'];
}

export interface TrackLocationSuggestionInput {
  /** Location ID (ie, "18579") */
  id?: InputMaybe<Scalars['ID']['input']>;
  queryText?: InputMaybe<Scalars['String']['input']>;
  /** Same Session ID as the one used during 'locations()' Query */
  sessionId: Scalars['ID']['input'];
}

export interface TrackLocationSuggestionInvalidInputPayload {
  reason: Scalars['String']['output'];
}

export type TrackLocationSuggestionPayload =
  | TrackLocationSuggestionInvalidInputPayload
  | TrackLocationSuggestionSuccessPayload;

export interface TrackLocationSuggestionSuccessPayload {
  responseStatus: Scalars['String']['output'];
}

export interface TriggerActivationPayload {
  success: Scalars['Boolean']['output'];
}

export interface UpdateAccountPrimaryContactV2Input {
  /** The ID of the hirer user to be set as primary account */
  hirerUserId: Scalars['String']['input'];
  /** The phone number for the primary contact */
  phoneNumber: Scalars['String']['input'];
}

export interface UpdateApplicationRatingInput {
  prospectId: Scalars['ID']['input'];
  rating: Scalars['Int']['input'];
}

export interface UpdateApplicationsStatusInput {
  bucket: ApplicationBucket;
  prospectData: Array<ApplicationData>;
  /** The location the status was updated. Should be either CandidateList or CandidateDetail or null */
  statusChangeLocation?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateApplicationsStatusPayload {
  success: Scalars['Boolean']['output'];
}

export interface UpdateBrandInput {
  assets?: InputMaybe<BrandAssetsInput>;
  id: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
}

export type UpdateBrandPayload =
  | InvalidInputPayload
  | UpdateBrandSuccessPayload;

export interface UpdateBrandSuccessPayload {
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  state?: Maybe<BrandState>;
}

export interface UpdateCartProductSetErrorPayload {
  message: Scalars['String']['output'];
}

export interface UpdateCartProductSetInput {
  cartId: Scalars['ID']['input'];
  productSetId: Scalars['ID']['input'];
}

export type UpdateCartProductSetResultPayload =
  | UpdateCartProductSetErrorPayload
  | UpdateCartProductSetSuccessPayload;

export interface UpdateCartProductSetSuccessPayload {
  cart: Cart;
}

export interface UpdateCartPromoCodeErrorPayload {
  message: Scalars['String']['output'];
}

export interface UpdateCartPromoCodeInput {
  cartId: Scalars['ID']['input'];
  promoCode: Scalars['String']['input'];
}

export type UpdateCartPromoCodeResultPayload =
  | UpdateCartPromoCodeErrorPayload
  | UpdateCartPromoCodeSuccessPayload;

export interface UpdateCartPromoCodeSuccessPayload {
  cart: Cart;
}

export interface UpdateDraftJobInput {
  /**
   * Adcentre advertiserId
   * @deprecated No longer required. Will be retired once the front-end is updated.
   */
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Selected branding info */
  branding?: InputMaybe<DraftJobBrandingInput>;
  /** Selected category and sub-category */
  categories?: InputMaybe<Array<DraftJobCategoryInput>>;
  /** The Job Description, aka "Write my own" */
  details?: InputMaybe<Scalars['String']['input']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   */
  draftProgress?: InputMaybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType: DraftJobFlowType;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: InputMaybe<Array<DraftJobGuidedDetailsInput>>;
  /** Flag to indicate if AI assistance has been used to generate job description and summary */
  hasAIAssistanceBeenUsed?: InputMaybe<Scalars['Boolean']['input']>;
  /** the id of the draft to be update */
  id: Scalars['String']['input'];
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: InputMaybe<Scalars['String']['input']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: InputMaybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The job location info */
  locations?: InputMaybe<Array<DraftJobLocationInput>>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: InputMaybe<DraftJobPaymentOption>;
  /** Hirer's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Selected products info */
  products?: InputMaybe<DraftJobProductsInput>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: InputMaybe<Scalars['String']['input']>;
  /** Min/Max salaries info */
  salary?: InputMaybe<DraftJobSalaryDetailsInput>;
  /** The status of the draft */
  status: DraftStatus;
  /** The job summary to appear in search */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** The job title(s) */
  titles?: InputMaybe<Array<DraftJobRoleTitleInput>>;
  /** The video info, e.g. video url */
  video?: InputMaybe<DraftJobVideoInput>;
  /** Work arrangements, ie Remote, Onsite, Hybrid */
  workArrangements?: InputMaybe<Array<WorkArrangement>>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: InputMaybe<Array<WorkType>>;
}

export interface UpdateDraftJobPayload {
  /** The updated draft job id from the new drafts api */
  draftId: Scalars['String']['output'];
}

export type UpdateDraftPayload = UpdateDraftJobPayload;

export interface UpdateJobDetailsInput {
  /** Key used to update the job */
  idempotencyKey: Scalars['String']['input'];
  /** An object containing values to update the job with */
  jobDetails: JobDetailsInput;
  /** The id of the job to update the details of */
  jobId: Scalars['String']['input'];
  /** An id used to identify the lifecycle of the job update */
  opportunityId: Scalars['String']['input'];
  /** The context in which the job is being updated (eg. updating or editing the job)) */
  purchaseContext: PurchaseContext;
}

export type UpdateJobDetailsResponse = UpdateJobDetailsSuccessResponse;

export interface UpdateJobDetailsSuccessResponse {
  revisionId: Scalars['String']['output'];
}

export interface UpdateJobForCartItemByReferenceInput {
  cartId: Scalars['ID']['input'];
  job: CartItemJobReferenceInput;
  referenceId: Scalars['String']['input'];
}

export type UpdateJobForCartItemByReferenceResultPayload =
  | CartNotFoundError
  | UpdateJobForCartItemByReferenceSuccessPayload;

export interface UpdateJobForCartItemByReferenceSuccessPayload {
  cart: Cart;
}

export interface UpdateJobPlacementInput {
  advertiserId: Scalars['Int']['input'];
  isPlaced?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['Int']['input'];
  postType: JobPlacementPostType;
  submissionDateUtc: Scalars['String']['input'];
  submitted: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
}

export type UpdateJobPlacementResponse =
  | ResponseError
  | UpdateJobPlacementResponseSuccess;

export interface UpdateJobPlacementResponseSuccess {
  result: Scalars['Boolean']['output'];
}

export interface UpdateUserDetailsResponse {
  message?: Maybe<Scalars['String']['output']>;
}

export interface UpdateUserPermissionsInput {
  /** The info of the user in AdCentre */
  userInfo: UserInfoInput;
}

export interface UpdateUserPermissionsResponse {
  permissions: UserPermissions;
  userId: Scalars['String']['output'];
}

export interface UpgradePath {
  /** Target product selected when upgrade product is selected */
  targetProduct2: CatalogueProduct;
  /** Type of upgrade path */
  type: UpgradePathType;
  /** Upgrade product available for AdProduct to which upgrade path is associated */
  upgradeProduct: AdProductUpgrade;
}

export enum UpgradePathType {
  UpgradeOnly = 'UpgradeOnly',
}

export interface UploadPresignedUrl {
  link: Scalars['String']['output'];
}

export interface UploadPresignedUrlForCandidateSupportDocInput {
  applicationCorrelationId: Scalars['ID']['input'];
  /** The uploading attachment blobId - generated by cm-ui */
  blobId: Scalars['ID']['input'];
  /** The uploading attachment content length */
  contentLength: Scalars['Int']['input'];
  /** The uploading attachment content type */
  contentType: Scalars['String']['input'];
  /** The uploading attachment file name */
  fileName: Scalars['String']['input'];
  jobId: Scalars['ID']['input'];
}

export type UploadPresignedUrlForCandidateSupportDocResponse =
  | ResponseError
  | UploadPresignedUrlForCandidateSupportDocResponseSuccess;

export interface UploadPresignedUrlForCandidateSupportDocResponseSuccess {
  result: UploadPresignedUrl;
}

export interface UpsertReviewReplyInput {
  companyId: Scalars['String']['input'];
  from: Scalars['String']['input'];
  reviewId: Scalars['String']['input'];
  text: Scalars['String']['input'];
}

export interface UpsertReviewReplyResponse {
  success: Scalars['Boolean']['output'];
}

export interface Upvotes {
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
}

export enum UseCase {
  Adbudget = 'ADBUDGET',
  Caja = 'CAJA',
  Generic = 'GENERIC',
  Invoice = 'INVOICE',
  Order = 'ORDER',
  Upgrade = 'UPGRADE',
}

export interface User {
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  permissions: UserPermissions;
  status: Scalars['String']['output'];
}

export interface UserDetails {
  /** Email address of the user */
  email: Scalars['String']['output'];
  /** First name of the user */
  firstName: Scalars['String']['output'];
  /** User ID of the user */
  id: Scalars['String']['output'];
  /** Last name of the user */
  lastName: Scalars['String']['output'];
  /** Permissions of the user on the advertiser account */
  permissions?: Maybe<UserPermissions>;
  /** Status of the user */
  status: UserStatus;
}

export interface UserDetailsInput {
  /**
   * Advertiser ID which the user is currently signed in as
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName: Scalars['String']['input'];
  /** Last name of the user */
  lastName: Scalars['String']['input'];
  /** AdCentre user ID of the user that is currently signed in */
  userId: Scalars['ID']['input'];
}

export interface UserHeaderContext {
  /** First name of user. Used for header dropdown text when advertiser context unavailable. */
  firstName: Scalars['String']['output'];
  /** Adcentre User ID */
  id: Scalars['ID']['output'];
  /** Full name of user. */
  name: Scalars['String']['output'];
}

export interface UserInfoInput {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  permissions: UserPermissionsInput;
}

export enum UserIntentType {
  Preview = 'PREVIEW',
  Purchase = 'PURCHASE',
}

export interface UserJobAccessInput {
  /** User access to job */
  jobAccess: Scalars['Boolean']['input'];
  /** Id of the user */
  userId: Scalars['ID']['input'];
}

export interface UserListResponse {
  users: Array<User>;
}

export interface UserOption {
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface UserPermissions {
  canCreatePosition: Scalars['Boolean']['output'];
  hasAllPositionAccess: Scalars['Boolean']['output'];
  hasAnalytics: Scalars['Boolean']['output'];
  hasPremiumTalentSearch: Scalars['Boolean']['output'];
  role: Scalars['String']['output'];
}

export interface UserPermissionsInput {
  canCreatePosition: Scalars['Boolean']['input'];
  hasAllPositionAccess: Scalars['Boolean']['input'];
  hasAnalytics: Scalars['Boolean']['input'];
  hasPremiumTalentSearch: Scalars['Boolean']['input'];
  role: Role;
}

export enum UserStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Fraudulent = 'FRAUDULENT',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
  Unverified = 'UNVERIFIED',
}

export interface UserWithJobPermission {
  /** The users email address. */
  email: Scalars['String']['output'];
  /** The users first name. */
  firstName: Scalars['String']['output'];
  /** A flag denoting whether the user has access to all jobs created within the advertiser account. */
  hasAccessToAllJobs: Scalars['Boolean']['output'];
  /** A flag denoting whether the user has access to querying job. */
  hasAccessToCurrentJob: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is an Administrator. */
  isAdmin: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is job creator. */
  isJobCreator: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is still required to activate their user account. */
  isPending: Scalars['Boolean']['output'];
  /** The users last name. */
  lastName: Scalars['String']['output'];
  /** Id of the user on the advertiser account. */
  userId: Scalars['ID']['output'];
}

export enum UsersParamsFilter {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING',
  Unverified = 'UNVERIFIED',
}

export enum UsersParamsInclude {
  AccessDetails = 'ACCESS_DETAILS',
}

export interface UsersParamsInput {
  /** Filter array to narrow down the returned list of users based on access right. Can be include [ACTIVE | PENDING | DEACTIVATED | ADMIN_ROLE | USER_ROLE] */
  accessRightFilter?: InputMaybe<Array<InputMaybe<AccessRightFilter>>>;
  /** Add on properties to returned users. Can be "ACCESS_DETAILS" */
  include?: InputMaybe<UsersParamsInclude>;
  /** Filter array to narrow down the returned list of users based on user status. Can be include [ACTIVE | PENDING | DEACTIVATED] */
  usersFilter?: InputMaybe<Array<InputMaybe<UsersParamsFilter>>>;
}

export interface UsersWithJobPermissionInput {
  /** The advertiser id to fetch users tied to the account. */
  advertiserId: Scalars['ID']['input'];
  /** The job id to check users access permission */
  jobId: Scalars['ID']['input'];
}

export interface ValidatePromoCodeInput {
  /** The locale of the user. The locale is used to determine the currency and language of the user */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** The product id to verify the campaign is applicable to. ProductId must be a valid product id from a product set */
  productId: Scalars['String']['input'];
  /** The promo code to be verified */
  promoCode: Scalars['String']['input'];
}

export interface ValidatePromoCodeResponse {
  /** Promo code is valid or is not valid */
  isValid: Scalars['Boolean']['output'];
  /** The message with detail as to why the promo code is not valid e.g. The promo code has expired */
  message: Scalars['String']['output'];
}

export interface Verification {
  title: Scalars['String']['output'];
  viewFields: Array<VerificationViewField>;
}

export interface VerificationViewField {
  label: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
}

export interface VerifiedField {
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface ViewProfile {
  atsProfileUrl: Scalars['String']['output'];
}

export interface ViewSentMessage {
  connectionId: Scalars['String']['output'];
}

export interface ViewsInJobSearchResponse {
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  clickConversionInPercentage?: Maybe<Scalars['Float']['output']>;
  hasAdRating: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileViewsInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
  views: Scalars['Int']['output'];
}

export interface ViewsInJobSearchWidgetInput {
  jobId: Scalars['String']['input'];
}

export interface VirtualAccount {
  accountNo?: Maybe<Scalars['String']['output']>;
  bank?: Maybe<Scalars['String']['output']>;
  bankCode?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['Int']['output']>;
}

export interface WindowLocation {
  /** The current URLs path */
  pathname: Scalars['String']['input'];
  /** The current URLs query (includes leading “?” if non-empty) */
  search: Scalars['String']['input'];
}

export enum WorkArrangement {
  Hybrid = 'HYBRID',
  Onsite = 'ONSITE',
  Remote = 'REMOTE',
}

export interface WorkHistory {
  companyName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  foundInCV: Scalars['Boolean']['output'];
  highlight?: Maybe<WorkHistoryHighlight>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
}

export interface WorkHistoryHighlight {
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
}

export enum WorkType {
  Casual = 'CASUAL',
  ContractTemp = 'CONTRACT_TEMP',
  Fulltime = 'FULLTIME',
  Parttime = 'PARTTIME',
}

export interface YourCandidate {
  adcentreCandidateId: Scalars['Int']['output'];
  applicationId?: Maybe<Scalars['ID']['output']>;
  applicationTime: Scalars['String']['output'];
  candidateId: Scalars['Int']['output'];
  channel: YourCandidateChannel;
  emailAddress: Scalars['String']['output'];
  hasCoverLetter: Scalars['Boolean']['output'];
  hasResume: Scalars['Boolean']['output'];
  hasSelectionCriteria: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  jobId: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  lastRoleCompanyName?: Maybe<Scalars['String']['output']>;
  lastRoleJobTitle?: Maybe<Scalars['String']['output']>;
  lastUpdated: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  prospectId: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  status: YourCandidateStatus;
  statusText: Scalars['String']['output'];
  totalApplications: Scalars['Int']['output'];
}

export enum YourCandidateChannel {
  Native = 'NATIVE',
  Uploaded = 'UPLOADED',
}

export interface YourCandidateEdge {
  node: YourCandidate;
}

export enum YourCandidateStatus {
  Accept = 'ACCEPT',
  Interview = 'INTERVIEW',
  New = 'NEW',
  Offer = 'OFFER',
  Prescreen = 'PRESCREEN',
  Rejected = 'REJECTED',
  Shortlist = 'SHORTLIST',
  Viewed = 'VIEWED',
}

export interface YourCandidatesInput {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
}

export interface YourCandidatesPayloadError {
  error: YourCandidatesPayloadErrorType;
}

export enum YourCandidatesPayloadErrorType {
  ForbiddenError = 'FORBIDDEN_ERROR',
  InputValidationError = 'INPUT_VALIDATION_ERROR',
  NotFound = 'NOT_FOUND',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export type YourCandidatesResponse =
  | YourCandidatesPayloadError
  | YourCandidatesResults;

export interface YourCandidatesResults {
  edges: Array<YourCandidateEdge>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
}
