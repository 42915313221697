import { UtagActions, MetricsLinkEvent, LinkEvent } from 'src/types/metrics';

export const loginButtonImpressionLinkEvent: MetricsLinkEvent<
  Record<string, unknown>
> = {
  action: UtagActions.Link,
  getValue: ({ context }) => ({
    ...context,
    eventName: LinkEvent.TsWidgetBtnLoginImpression,
  }),
};

export const loginButtonInteractedLinkEvent: MetricsLinkEvent<
  Record<string, unknown>
> = {
  action: UtagActions.Link,
  getValue: ({ context }) => ({
    ...context,
    eventName: LinkEvent.TsWidgetBtnLoginInteracted,
  }),
};
