import React from 'react';
import { Box, Stack, Text, IconChevron } from 'braid-design-system';

import Branded from '../../../../shared/components/Branded/Branded';

import { LayoutSize } from '../../types';

interface Props {
  onLinkClicked: () => void;
  layoutSize: LayoutSize;
}

function ViewAllLinkTextOnly({ onLinkClicked, layoutSize }: Props) {
  return (
    <Box
      id="viewAllLinkTextOnly"
      height="full"
      display="flex"
      alignItems="flexEnd"
      textAlign="right"
      justifyContent="flexEnd"
      marginTop={layoutSize === LayoutSize.XSmall ? 'xsmall' : 'none'}
      padding={layoutSize === LayoutSize.XSmall ? 'xsmall' : 'none'}
      cursor="pointer"
      onClick={onLinkClicked}
    >
      <Stack space="large" align="right">
        <Text size="small">
          <Branded>
            View all on SEEK <IconChevron tone="critical" direction="right" />
          </Branded>
        </Text>
      </Stack>
    </Box>
  );
}

export default ViewAllLinkTextOnly;
