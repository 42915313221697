import { Box, Button, ContentBlock, Loader, Stack } from 'braid-design-system';
import React, { useEffect } from 'react';
import SeekLogo from 'src/shared/components/SeekLogo/SeekLogo';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';
import { getAccountSelectionUrl } from 'src/shared/hooks/useTscAuth/utils/getAccountSelectionUrl';

import * as metrics from './metrics';
import { useMetrics } from 'src/shared/hooks/useMetrics';

export const Splash = () => {
  const { dispatchEvent } = useMetrics();
  const { login, logout, authenticated, loading, identity } = useTscAuth();

  const advertiserId = identity?.advertiserId;
  useEffect(() => {
    if (loading) {
      return;
    }
    if (authenticated && !advertiserId) {
      const url = getAccountSelectionUrl({
        canonicalUrl:
          new URLSearchParams(window.location.search).get('returnUrl') || '/',
      });

      window.location.assign(url);
    } else {
      dispatchEvent(metrics.loginButtonImpressionLinkEvent);
    }
  }, [authenticated, dispatchEvent, loading, advertiserId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box marginY="gutter">
      <ContentBlock width="large">
        <Stack space="medium" align="center">
          <Box>
            {authenticated ? (
              <Button
                tone="brandAccent"
                onClick={() => {
                  logout();
                }}
              >
                Sign out
              </Button>
            ) : (
              <Button
                tone="brandAccent"
                onClick={() => {
                  dispatchEvent(metrics.loginButtonInteractedLinkEvent);
                  login();
                }}
              >
                Log in to SEEK Talent Search
              </Button>
            )}
          </Box>

          <SeekLogo />
        </Stack>
      </ContentBlock>
    </Box>
  );
};
