import { Country } from '@seek/melways-sites';
import { Globals } from '../../../types';
import { GetLocationsQuery } from '../data/GetLocations.generated';

function extractLocationList(
  locationsData: GetLocationsQuery | undefined,
  country: Country = 'au',
): string[] {
  const locations = locationsData?.locations || [];
  if (!locations?.length || !locations?.[0]?.parents?.length) {
    return [
      (country === 'au'
        ? Globals.AllAULocationId
        : Globals.AllNZLocationId) as string,
    ];
  }
  return locations[0].parents.map((location) => String(location?.id));
}

export default extractLocationList;
