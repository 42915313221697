import React from 'react';
import { Box, Button, Column, Columns, Stack } from 'braid-design-system';

import Logo from './Logo';

import getCustomButtonSize from '../utils/getCustomButtonSize';

import { LayoutOrientation, LayoutSize } from '../types';

interface Props {
  layoutSize: LayoutSize;
  layoutOrientation: LayoutOrientation;
  onClick: () => void;
}

function NoResults({ layoutSize, layoutOrientation, onClick }: Props) {
  const customButtonSize = getCustomButtonSize(layoutSize);

  const renderButton = () => (
    <Button tone="brandAccent" size={customButtonSize} onClick={onClick}>
      Visit SEEK Talent Search
    </Button>
  );

  return (
    <Box id="no-results">
      {layoutOrientation === LayoutOrientation.Horizontal ? (
        <Columns space="small" alignY="center">
          <Column width="content">{renderButton()}</Column>
          <Column>
            <Logo layoutSize={layoutSize} />
          </Column>
        </Columns>
      ) : (
        <Stack space="medium" align="center">
          <Box>{renderButton()}</Box>
          <Logo layoutSize={layoutSize} />
        </Stack>
      )}
    </Box>
  );
}

export default NoResults;
