import { Card, Heading, Text, Stack } from 'braid-design-system';
import React, { useEffect, useState } from 'react';
import { getTscConfig } from 'src/shared/hooks/useTscAuth';

const Config = () => {
  const [config, setConfig] = useState('');

  useEffect(() => {
    setConfig(JSON.stringify(getTscConfig(window.location.host), undefined, 2));
  }, []);

  return (
    <Card>
      <Stack space="small">
        <Heading level="3">Configuration</Heading>
        <Text>This is the configuration that is supplied for your domain.</Text>
        <Text size="small">
          <pre>{config}</pre>
        </Text>
      </Stack>
    </Card>
  );
};

export default Config;
