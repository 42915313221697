import { AuthN } from '@seek/online-identity';
import { getTscConfig } from './utils/getTscConfig';

let authClientSingleton: AuthN | null = null;

const isBrowser = () => typeof window !== 'undefined';

export const useAuthN = () => {
  if (!isBrowser()) {
    return authClientSingleton!;
  }
  if (!authClientSingleton) {
    const config = getTscConfig(window.location.host);
    authClientSingleton = new AuthN(config);
  }
  return authClientSingleton;
};
