import qs from 'query-string';

import { Globals, ProfileTypes } from '../../../types';

export interface Params {
  jobTitle: string;
  approachableOnly: boolean;
  profileTypes: ProfileTypes[];
  locationList: string[];
}

const getTalentSearchUrl = ({
  jobTitle,
  approachableOnly,
  profileTypes,
  locationList,
}: Params) => {
  const search = qs.stringify({
    uncoupledFreeText: jobTitle,
    approachable: approachableOnly,
    profileTypes,
    locationList: locationList.join(','),
  });
  return `${Globals.UncoupledSearchResultsPathname}?${search}`;
};

export default getTalentSearchUrl;
