import qs from 'query-string';

import { Globals, ProfileTypes } from '../../../types';
import { SeedProfileFieldsFragment } from '../data/SeedProfileFields.fragment.generated';

const getProfileExtract = ({
  currentJobTitle,
  firstName,
  lastName,
}: SeedProfileFieldsFragment) => ({
  ...(currentJobTitle && { currentJobTitle }),
  firstName,
  lastName,
  seedProfileType: 'seek',
});

function getViewAllUrl(
  seedProfile: SeedProfileFieldsFragment,
  { seekProfilesOnly }: { seekProfilesOnly: boolean },
) {
  const seedProfileId = seedProfile.profileId;

  const searchParams = {
    ...getProfileExtract(seedProfile),
    profileTypes: seekProfilesOnly
      ? [ProfileTypes.Seek]
      : [ProfileTypes.Combined],
  };

  return `${Globals.SimilarCandidatesPathName}/${seedProfileId}?${qs.stringify(
    searchParams,
  )}`;
}

export default getViewAllUrl;
