import { ProfileTypes } from '../../../types';

export interface Params {
  count: number;
  locationList: string[];
  jobTitle: string;
  approachableOnly: boolean;
  profileTypes: ProfileTypes[];
}

export interface Payload {
  searchResultsTotal: number;
  jobLocationId: string;
  jobSearchPhrase: string;
  searchIsApproachable: boolean;
  profileTypes: string[];
}

const getLinkEventPayload = ({
  count,
  locationList,
  jobTitle,
  approachableOnly,
  profileTypes,
}: Params): Payload => ({
  searchResultsTotal: count,
  jobLocationId: locationList[0],
  jobSearchPhrase: jobTitle,
  searchIsApproachable: approachableOnly,
  profileTypes,
});

export default getLinkEventPayload;
