import React from 'react';
import { Text, Stack, Strong } from 'braid-design-system';

import { LayoutSize } from '../../types';

interface Props {
  count: number | undefined;
  layoutSize: LayoutSize;
  foundInText: string;
}

function Header({ count, layoutSize, foundInText }: Props) {
  const candidateCount = `${count || '0'} similar candidates `;

  if (layoutSize === LayoutSize.XSmall) {
    return (
      <Stack space="small">
        <Text weight="strong">{candidateCount}</Text>
        <Text>{foundInText}</Text>
      </Stack>
    );
  }

  return (
    <Text>
      <Strong>{candidateCount}</Strong>
      {foundInText}
    </Text>
  );
}

export default Header;
