import { createContext } from 'react';

import { initialTscAuthState } from './tsc-auth-state';
import type { TscAuthContextInterface } from './types';

const stub = (): never => {
  throw new Error('useTscAuth must be wrapped within a TscAuthProvider.');
};

export const initialContext: TscAuthContextInterface = {
  ...initialTscAuthState,
  getToken: stub,
  login: stub,
  logout: stub,
  authenticate: stub,
};

const context = createContext<TscAuthContextInterface>(initialContext);

export default context;
