import { useEffect } from 'react';

import { useAuthN } from '../../shared/hooks/useTscAuth';

function Integration() {
  const authN = useAuthN();

  useEffect(() => {
    (async () => {
      await authN.init();
    })();
  }, [authN]);

  return null;
}

export default Integration;
