import React from 'react';
import { useLocation } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';

interface Props {
  children: React.ReactNode;
}

function ErrorBoundaryHandler({ children }: Props) {
  const { pathname, search } = useLocation();

  const handleUnauthorised = () => {
    const returnTo = `${pathname}${search}`;
    // eslint-disable-next-line no-console
    console.error('Unauthorised', { returnTo });
  };

  return (
    <ErrorBoundary onUnauthorised={handleUnauthorised}>
      {children}
    </ErrorBoundary>
  );
}

export default ErrorBoundaryHandler;
