import React from 'react';
import { Box, Stack, Text, IconChevron } from 'braid-design-system';

import Branded from '../../../../shared/components/Branded/Branded';
import SeekLogo from '../../../../shared/components/SeekLogo/SeekLogo';

import { LayoutSize } from '../../types';

interface Props {
  onLinkClicked: () => void;
  layoutSize: LayoutSize;
}

function ViewAllLink({ onLinkClicked, layoutSize }: Props) {
  return (
    <Box
      id="viewAllLink"
      height="full"
      display="flex"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      marginTop={layoutSize === LayoutSize.XSmall ? 'xsmall' : 'none'}
      padding={layoutSize === LayoutSize.XSmall ? 'xsmall' : 'none'}
      cursor="pointer"
      onClick={onLinkClicked}
    >
      <Stack space="large" align="center">
        <Text size="small">
          <Branded>
            View all on SEEK Talent Search{' '}
            <IconChevron tone="critical" direction="right" />
          </Branded>
        </Text>
        <SeekLogo />
      </Stack>
    </Box>
  );
}

export default ViewAllLink;
