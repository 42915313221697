import { Metadata } from '@seek/talentsearch-entity-types';
import { CountryCodes } from '../../types';
import { Currency, SalaryFrequency } from 'src/types/generated';

export const isSalaryFilterSpecified = (
  value: number | undefined | null,
): value is number => Boolean(value);

export const DEFAULT_SALARY_TYPE_FROM_COUNTRY_CODES: Record<
  CountryCodes,
  SalaryFrequency
> = {
  [CountryCodes.AU]: SalaryFrequency.Annual,
  [CountryCodes.NZ]: SalaryFrequency.Annual,
  [CountryCodes.HK]: SalaryFrequency.Monthly,
  [CountryCodes.TH]: SalaryFrequency.Monthly,
  [CountryCodes.ID]: SalaryFrequency.Monthly,
  [CountryCodes.MY]: SalaryFrequency.Monthly,
  [CountryCodes.SG]: SalaryFrequency.Monthly,
  [CountryCodes.PH]: SalaryFrequency.Monthly,
};

export const COUNTRY_CODE_TO_CURRENCY: Record<CountryCodes, Currency> = {
  [CountryCodes.AU]: Currency.Aud,
  [CountryCodes.NZ]: Currency.Nzd,
  [CountryCodes.HK]: Currency.Hkd,
  [CountryCodes.TH]: Currency.Thb,
  [CountryCodes.ID]: Currency.Idr,
  [CountryCodes.MY]: Currency.Myr,
  [CountryCodes.SG]: Currency.Sgd,
  [CountryCodes.PH]: Currency.Php,
};

export const SALARY_NATION_TO_COUNTRY_CODES: Record<string, CountryCodes> = {
  [`${Metadata.CountryId.AU}`]: CountryCodes.AU,
  [`${Metadata.CountryId.NZ}`]: CountryCodes.NZ,
  [`${Metadata.CountryId.HK}`]: CountryCodes.HK,
  [`${Metadata.CountryId.ID}`]: CountryCodes.ID,
  [`${Metadata.CountryId.TH}`]: CountryCodes.TH,
  [`${Metadata.CountryId.MY}`]: CountryCodes.MY,
  [`${Metadata.CountryId.SG}`]: CountryCodes.SG,
  [`${Metadata.CountryId.PH}`]: CountryCodes.PH,
};

export const COUNTRY_CODES_SALARY_NATION: Record<CountryCodes, string> = {
  [CountryCodes.AU]: `${Metadata.CountryId.AU}`,
  [CountryCodes.NZ]: `${Metadata.CountryId.NZ}`,
  [CountryCodes.HK]: `${Metadata.CountryId.HK}`,
  [CountryCodes.ID]: `${Metadata.CountryId.ID}`,
  [CountryCodes.TH]: `${Metadata.CountryId.TH}`,
  [CountryCodes.MY]: `${Metadata.CountryId.MY}`,
  [CountryCodes.SG]: `${Metadata.CountryId.SG}`,
  [CountryCodes.PH]: `${Metadata.CountryId.PH}`,
};

export const getSalaryTypeFromStringV2 = (
  salaryType: string | null | undefined,
): SalaryFrequency => {
  switch (salaryType?.toLowerCase()) {
    case 'hourly':
    case 'hourly_rate':
    case 'hourlyrate':
      return SalaryFrequency.Hourly;
    case 'monthly':
      return SalaryFrequency.Monthly;
    case 'annual':
    default:
      return SalaryFrequency.Annual;
  }
};

/**
 * A function that will convert from salary nation to currency.
 * It will default to Currency.AUD if somehow the salary nation
 * is unknown.
 * @param salaryNation
 * @returns
 */
export const convertFromSalaryNationToCurrency = (
  salaryNation: string | undefined,
): Currency =>
  COUNTRY_CODE_TO_CURRENCY[
    SALARY_NATION_TO_COUNTRY_CODES[salaryNation ?? '3000'] ?? CountryCodes.AU
  ];

export const convertFromSalaryNationToDefaultSalaryType = (
  salaryNation: string,
): SalaryFrequency =>
  DEFAULT_SALARY_TYPE_FROM_COUNTRY_CODES[
    SALARY_NATION_TO_COUNTRY_CODES[salaryNation]
  ] ?? SalaryFrequency.Annual;
