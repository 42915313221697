import React from 'react';
import { Box, Heading, Text } from 'braid-design-system';

import FriendlyCount from '../../../shared/components/FriendlyCount/FriendlyCount';
import Branded from '../../../shared/components/Branded/Branded';

import { LayoutOrientation, LayoutSize } from '../types';

import * as styles from './Styles.css';

interface Props {
  seekProfilesOnly: boolean;
  count: number;
  layoutOrientation: LayoutOrientation;
  layoutSize: LayoutSize;
  atsName: string | null;
  onResultsClick: () => void;
}

function Results({
  seekProfilesOnly,
  count,
  layoutOrientation,
  layoutSize,
  atsName,
  onResultsClick,
}: Props) {
  const isHorizontalOrientation =
    layoutOrientation === LayoutOrientation.Horizontal;
  const isMediumLayoutSize = layoutSize === LayoutSize.Medium;
  const textSize = isMediumLayoutSize ? 'small' : 'standard';
  const countTextSize = isMediumLayoutSize ? '3' : '2';

  const profileSource = seekProfilesOnly
    ? 'only on SEEK'
    : `on both ${atsName || 'your database'} and SEEK`;

  const renderResults = () => (
    <Box
      id="results"
      className={styles.results[layoutSize]}
      onClick={onResultsClick}
    >
      <Box className={styles.text[layoutSize]}>
        <Text size={textSize} truncate>
          <Branded truncate>View candidates {profileSource}</Branded>
        </Text>
      </Box>
      <Box className={styles.count[layoutSize]}>
        <Heading level={countTextSize}>
          <Branded>
            <FriendlyCount count={count} />
          </Branded>
        </Heading>
      </Box>
    </Box>
  );

  return (
    <>
      {isHorizontalOrientation ? (
        <Box
          className={styles.resultsBoxHorizontalContainer[layoutSize]}
          component="div"
        >
          {renderResults()}
        </Box>
      ) : (
        <Box
          className={styles.resultsBoxVerticalContainer[layoutSize]}
          component="div"
        >
          {renderResults()}
        </Box>
      )}
    </>
  );
}

export default Results;
