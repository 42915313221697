import { createContext } from 'react';

import { GraphqlUri, AppConfig, Environment } from '../../../types';

export default createContext<AppConfig>({
  appVersion: '',
  branchName: '',
  environment: Environment.Development,
  graphqlUri: GraphqlUri.Development,
  tealiumUrl: '',
  metricsForwarderUrl: '',
});
