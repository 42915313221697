import React from 'react';
import { TscApolloProvider } from 'src/shared/components/TscApolloProvider/TscApolloProvider';
import { TscAuthProvider } from 'src/shared/hooks/useTscAuth';
import { AccountSelect } from 'src/App/components/AccountSelect/AccountSelect';
import { ImpersonationBanner } from 'src/shared/components/ImpersonationBanner/ImpersonationBanner';

export const AccountSelectScene = () => (
  <TscAuthProvider authenticationLevel="User">
    <TscApolloProvider>
      <ImpersonationBanner />
      <AccountSelect />
    </TscApolloProvider>
  </TscAuthProvider>
);
