import React, { useMemo } from 'react';
import { Box, Stack, Loader, Columns, Column } from 'braid-design-system';

import { useSearchParams } from 'src/shared/hooks/useSearchParams';
import { useMetrics } from 'src/shared/hooks/useMetrics';
import { useWindow } from 'src/shared/hooks/useWindow';
import { usePreferences } from 'src/shared/hooks/usePreferences';
import { CountryCodes, Globals, ProfileTypes } from 'src/types';
import { getCountry } from 'src/shared/utils/generalUtils';
import { useGetTalentSearchUserContextQuery } from 'src/shared/hooks/useTalentSearchContext/gql/GetTalentSearchUserContext.generated';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';
import { useGetSeekOrCombinedProfiles } from '../data/useGetSeekOrCombinedProfiles';

import inferCountryFromLocation from '../utils/inferCountryFromLocation';
import getTalentSearchUrl from '../utils/getTalentSearchUrl';
import getLinkEventPayload from '../utils/getLinkEventPayload';
import getSanitizedJobTitle from '../utils/getSanitizedJobTitle';
import * as metrics from '../metrics';
import { querySchema } from '../config';
import { LayoutOrientation } from '../types';

import Title from './Title';
import Results from './Results';
import NoResults from './NoResults';
import Logo from './Logo';

import * as styles from './Styles.css';

function SeekOrCombined() {
  const { dispatchEvent } = useMetrics();
  const { openTalentSearchUrl } = useWindow();
  const { getRegion } = usePreferences();

  const { identity } = useTscAuth();
  const advertiserId = identity?.advertiserId;

  const { data: contextData, loading: contextLoading } =
    useGetTalentSearchUserContextQuery({
      skip: !advertiserId,
    });
  const atsName = contextData?.talentSearchUserContext.ats?.name ?? null;

  const {
    jobTitle: unSanitizedJobTitle,
    location: unSanitizedLocation,
    approachableOnly,
    seekProfilesOnly,
    layoutOrientation,
    layoutSize,
  } = useSearchParams(querySchema);

  const jobTitle = getSanitizedJobTitle(unSanitizedJobTitle);

  const { inferredLocation, inferredCountry } = inferCountryFromLocation(
    unSanitizedLocation,
    getRegion(),
  );
  const hirerCountry = useMemo(
    () => getCountry(getRegion()).toUpperCase() as CountryCodes,
    [getRegion],
  );

  const profileTypes = useMemo(
    () => (seekProfilesOnly ? [ProfileTypes.Seek] : [ProfileTypes.Combined]),
    [seekProfilesOnly],
  );

  const {
    loading: seekOrCombinedLoading,
    locationList,
    seekOrCombinedProfilesCount: count,
  } = useGetSeekOrCombinedProfiles({
    jobTitle,
    location: inferredLocation,
    country: inferredCountry,
    hirerCountryCode: hirerCountry,
    approachableOnly,
    profileTypes,
  });

  const handleResultsClick = () => {
    const interactedLinkEventPayload = getLinkEventPayload({
      count,
      locationList,
      jobTitle,
      approachableOnly,
      profileTypes,
    });
    dispatchEvent(metrics.interactedLinkEvent, interactedLinkEventPayload);

    const linkHref = getTalentSearchUrl({
      jobTitle,
      approachableOnly,
      profileTypes,
      locationList,
    });
    openTalentSearchUrl(linkHref);
  };

  const handleNoResultsClick = () => {
    openTalentSearchUrl(Globals.UncoupledSearchPathname);
  };

  const renderResults = () => (
    <Results
      count={count}
      seekProfilesOnly={seekProfilesOnly}
      layoutOrientation={layoutOrientation}
      layoutSize={layoutSize}
      atsName={atsName}
      onResultsClick={handleResultsClick}
    />
  );

  const isHorizontalLayout = layoutOrientation === LayoutOrientation.Horizontal;

  const loading = contextLoading || seekOrCombinedLoading;
  return (
    <Box
      data-widget-id="matchingCandidates"
      data-widget-variant="seekOrCombined"
      className={styles.containerSeekOrCombined[layoutOrientation]}
      paddingY="small"
      paddingX="medium"
    >
      {loading ? (
        <Stack space="none" align="center">
          <Loader />
        </Stack>
      ) : (
        <Box data-widget-result={seekProfilesOnly ? 'seekOnly' : 'combined'}>
          {count === 0 ? (
            <NoResults
              layoutOrientation={layoutOrientation}
              layoutSize={layoutSize}
              onClick={handleNoResultsClick}
            />
          ) : (
            <Stack space={isHorizontalLayout ? 'small' : 'medium'}>
              <Title
                count={count}
                jobTitle={jobTitle}
                approachableOnly={approachableOnly}
                layoutSize={layoutSize}
                layoutOrientation={layoutOrientation}
                splitLines={!isHorizontalLayout}
              />
              {isHorizontalLayout ? (
                <Columns alignY="center" space="small">
                  <Column>{renderResults()}</Column>
                  <Column width="content">
                    <Box className={styles.logoContainer}>
                      <Logo layoutSize={layoutSize} />
                    </Box>
                  </Column>
                </Columns>
              ) : (
                <>
                  <Stack space="medium">
                    {renderResults()}
                    <Box className={styles.logoContainer}>
                      <Logo layoutSize={layoutSize} />
                    </Box>
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
}

export default SeekOrCombined;
