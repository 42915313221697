import { CountryCodes } from 'src/types';

interface BaseVariables {
  jobTitle: string;
  location: string;
  country: string;
  approachableOnly: boolean;
  profileTypes: string[];
  hirerCountryCode: CountryCodes;
}

export type SeekAndCombinedProfilesVariables = Pick<
  BaseVariables,
  'jobTitle' | 'location' | 'country' | 'approachableOnly' | 'hirerCountryCode'
>;
export type SeekOrCombinedProfilesVariables = Pick<
  BaseVariables,
  | 'jobTitle'
  | 'location'
  | 'country'
  | 'approachableOnly'
  | 'profileTypes'
  | 'hirerCountryCode'
>;

export interface SeekOrCombinedProfilesResponse {
  seekOrCombinedProfilesCount: number;
  locationList: string[];
}

export interface SeekAndCombinedProfilesResponse {
  seekProfilesCount: number;
  combinedProfilesCount: number;
  locationList: string[];
}

export enum LayoutOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export enum LayoutSize {
  Medium = 'medium',
  Large = 'large',
}
