import { parsers } from '../../shared/hooks/useSearchParams';

import { LayoutDefinitions, LayoutSize } from './types';

const MIN_MEDIUM_WIDTH = 400;
const MIN_LARGE_WIDTH = 600;

export const layouts: LayoutDefinitions = {
  [LayoutSize.XSmall]: {
    columns: 1,
    maxCandidates: 1,
  },
  [LayoutSize.Small]: {
    columns: 2,
    maxCandidates: 1,
  },
  [LayoutSize.Medium]: [
    (width: number) =>
      width > 0 && {
        columns: 1,
        maxCandidates: 1,
      },
    (width: number) =>
      width >= MIN_MEDIUM_WIDTH && {
        columns: 2,
        maxCandidates: 3,
      },
  ],
  [LayoutSize.Large]: [
    (width: number) =>
      width > 0 && {
        columns: 1,
        maxCandidates: 2,
      },
    (width: number) =>
      width >= MIN_MEDIUM_WIDTH && {
        columns: 2,
        maxCandidates: 5,
      },
    (width: number) =>
      width >= MIN_LARGE_WIDTH && {
        columns: 3,
        maxCandidates: 8,
      },
  ],
  [LayoutSize.XLarge]: [
    (width: number) =>
      width > 0 && {
        columns: 1,
        maxCandidates: 2,
      },
    (width: number) =>
      width >= MIN_MEDIUM_WIDTH && {
        columns: 2,
        maxCandidates: 4,
      },
    (width: number) =>
      width >= MIN_LARGE_WIDTH && {
        columns: 3,
        maxCandidates: 6,
      },
  ],
};

export const querySchema = {
  email: {
    type: parsers.string,
    required: true,
  },
  seekProfilesOnly: {
    type: parsers.boolean,
  },
  isResponsive: {
    type: parsers.boolean,
  },
  layoutSize: {
    type: (value: string) => value as LayoutSize,
    defaultValue: LayoutSize.Large,
    oneOf: Object.values(LayoutSize),
  },
};
