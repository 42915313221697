import { parsers } from '../../shared/hooks/useSearchParams';

import { LayoutOrientation, LayoutSize } from './types';

export const querySchema = {
  jobTitle: {
    type: parsers.string,
    required: true,
    helpText: {
      required: `E.g. "developer" or "Associate Lawyer"`,
    },
  },
  location: {
    type: parsers.string,
    required: true,
    helpText: {
      required: 'E.g. "Northcote VIC Australia" or "Sunshine Coast QLD"',
    },
  },
  approachableOnly: {
    type: parsers.boolean,
  },
  seekProfilesOnly: {
    type: parsers.boolean,
  },
  layoutSize: {
    type: (value: string) => value as LayoutSize,
    defaultValue: LayoutSize.Large,
    oneOf: Object.values(LayoutSize),
  },

  layoutOrientation: {
    type: (value: string) => value as LayoutOrientation,
    defaultValue: LayoutOrientation.Vertical,
    oneOf: Object.values(LayoutOrientation),
  },
};
