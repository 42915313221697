import React from 'react';
import { Box } from 'braid-design-system';

import * as styles from '../Styles.css';

import { LayoutSize } from '../../types';

interface Props {
  children: React.ReactNode;
  layoutSize: LayoutSize;
}

function Container({ layoutSize, children }: Props) {
  return (
    <Box
      className={`${styles.layoutContainer} ${styles.layoutSizes[layoutSize]}`}
    >
      {children}
    </Box>
  );
}

export default Container;
