import { URL_ACCOUNT_SELECT } from '../constants';

interface Options {
  /** Optional string which represents the canonical return url for advertiser sign-in. This is not required, but may help prevent 403 errors in your application. */
  canonicalUrl?: string;
}

export const getAccountSelectionUrl = (options?: Options) => {
  const redirectUrl =
    options?.canonicalUrl ?? window.location.pathname + window.location.search;

  return `${URL_ACCOUNT_SELECT}?returnUrl=${encodeURIComponent(redirectUrl)}`;
};
