import React from 'react';
import { Box, Dropdown, Loader, Text } from 'braid-design-system';
import { useGetTalentSearchAccountsQuery } from './GetTalentSearchAccounts.generated';

export const AccountSelector = () => {
  const handleAccountSelection: React.FormEventHandler<HTMLSelectElement> = (
    e,
  ) => {
    window.location.assign(e.currentTarget.value);
  };

  const { loading, data, error } = useGetTalentSearchAccountsQuery({
    variables: {
      returnUri:
        new URLSearchParams(window.location.search).get('returnUrl') ?? '/',
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Text>Error fetching accounts</Text>;
  }

  const allAccounts = data?.talentSearchAccounts?.items ?? [];
  const filteredAccounts = allAccounts.filter(
    ({ hasConnectedTalentSearchAccess }) => hasConnectedTalentSearchAccess,
  );

  if (filteredAccounts.length === 1) {
    const account = filteredAccounts[0];
    window.location.assign(account.redirectUrl);

    return null;
  }

  return (
    <Box data-widget-id="accountSelector" display="flex">
      <Dropdown
        aria-label="Select an account"
        id="tsc-account-selector"
        placeholder="Select an account"
        value=""
        onChange={handleAccountSelection}
      >
        {filteredAccounts.map((tscAccount) => (
          <option key={tscAccount.advertiserId} value={tscAccount.redirectUrl}>
            {`${tscAccount.advertiserName} (${tscAccount.advertiserId})`}
          </option>
        ))}
      </Dropdown>
    </Box>
  );
};
