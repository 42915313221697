/* eslint-disable no-console */
import {
  Actions,
  Card,
  Heading,
  Stack,
  Text,
  Button,
  Checkbox,
  Column,
  Columns,
} from 'braid-design-system';
import React, { useEffect, useState } from 'react';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';

const ActiveSession = () => {
  const [ignoreCache, setIgnoreCache] = useState(false);
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [copyToggle, setCopyToggle] = useState(false);
  const { authenticated, getToken, identity, login, logout } = useTscAuth();

  const displayResult = (value: Record<string, any> | string) => {
    console.log('displayResult', { result: value });

    const s =
      typeof value === 'object' ? JSON.stringify(value, undefined, 2) : value;
    setResult(s);
  };

  const displayError = (err: Error) => {
    console.log('displayError', { err });

    setError(JSON.stringify(err, undefined, 2));
  };

  const parseJwt = (token: string | void | null): Record<string, any> => {
    if (typeof token !== 'string') {
      return {};
    }

    // not recommended. This reads the token payload but performs no validation whatsoever.
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  const signIn = async () => {
    await login();
  };

  const signOut = async () => {
    logout();
  };

  const copyToken2Clipboard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyToggle(true);
    setTimeout(() => {
      setCopyToggle(false);
    }, 5000);
  };

  const getAccessToken = async () => {
    try {
      const token = await getToken(ignoreCache);
      const payload = parseJwt(token);
      displayResult({ token, payload });
      copyToken2Clipboard(token as string);
    } catch (err: any) {
      displayError(err);
    }
  };

  const getIdentity = async () => {
    try {
      console.log(identity);
      displayResult({ identity });
    } catch (err: any) {
      displayError(err);
    }
  };

  const handleChangeIgnoreCache = (event: any) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setIgnoreCache(value);
  };

  useEffect(() => {
    const fn = async () => {
      const token = await getToken(ignoreCache);
      const payload = parseJwt(token);
      displayResult({ token, payload });
    };
    fn();
  }, [getToken, ignoreCache]);

  return (
    <Stack space="medium">
      <Columns space="gutter" collapseBelow="tablet">
        <Column width="content">
          <Actions>
            <Stack space="medium">
              <Card>
                {authenticated ? (
                  <Stack space="medium">
                    <Button onClick={signOut}>sign out</Button>
                    <Checkbox
                      id="ignoreCache"
                      name="ignoreCache"
                      checked={ignoreCache}
                      onChange={handleChangeIgnoreCache}
                      label="Ignore cache"
                    />
                    <Button variant="soft" onClick={getAccessToken}>
                      {copyToggle ? 'token copied! 😀' : 'get access token'}
                    </Button>
                    <Button variant="soft" onClick={getIdentity}>
                      get identity
                    </Button>
                  </Stack>
                ) : (
                  <Stack space="medium">
                    <Button onClick={signIn}>sign in</Button>
                  </Stack>
                )}
              </Card>
            </Stack>
          </Actions>
        </Column>
        <Column>
          <Stack space="large">
            {error ? (
              <Card>
                <Text size="small">
                  <Stack space="small">
                    <Heading level="3">Error</Heading>
                    <pre>{error}</pre>
                  </Stack>
                </Text>
              </Card>
            ) : null}
            <Card>
              <Text size="small">
                <Stack space="small">
                  <Heading level="3">Result</Heading>
                  <pre>{result}</pre>
                </Stack>
              </Text>
            </Card>
          </Stack>
        </Column>
      </Columns>
    </Stack>
  );
};

export default ActiveSession;
