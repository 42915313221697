import { useEffect } from 'react';

import { useAuthN } from '../../shared/hooks/useTscAuth';

export const LogOut = () => {
  const authN = useAuthN();

  useEffect(() => {
    (async () => {
      await authN.logout(window.location.origin);
    })();
  }, [authN]);

  return null;
};
