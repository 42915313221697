import React, { Fragment } from 'react';

import { Globals } from '../../../types';

interface Props {
  count: number;
  maxCountToDisplay: number;
}

function FriendlyCount({ count, maxCountToDisplay }: Props) {
  return (
    <Fragment>
      {count > maxCountToDisplay ? `${maxCountToDisplay}+` : `${count}`}
    </Fragment>
  );
}

FriendlyCount.defaultProps = {
  maxCountToDisplay: Globals.MaxCountToDisplay,
};

export default FriendlyCount;
