import { RouteInfo } from './types';

//  Shared route paths for UI and SKU.config
export const ROUTE_PATH = {
  ACCOUNT_SELECT: '/account/select',
  MATCHING_CANDIDATES: '/matching-candidates',
  MATCHING_CANDIDATES_VARIATION: '/matching-candidates/:variation',
  SIMILAR_CANDIDATES: '/similar-candidates',
  SIMILAR_CANDIDATES_VARIATION: '/similar-candidates/:variation',
  BUTTONS: '/buttons/:variation',
  CALLBACK: '/callback',
  INTEGRATION: '/oauth/integration',
  DEBUG: '/oauth/debug',
  LOG_OUT: '/oauth/logout',
  ROOT: '/',
} as const;

//  Scene keys, used to determine which scene component
//  should be rendered for each route
export const SCENE_KEY = {
  ACCOUNT_SELECT: 'accountSelect',
  CALLBACK: 'callback',
  DEBUG: 'debug',
  INTEGRATION: 'integration',
  LOG_OUT: 'logOut',
  MATCHING_CANDIDATES: 'matchingCandidates',
  SIMILAR_CANDIDATES: 'similarCandidates',
  BUTTONS: 'buttons',
  ROOT: 'root',
} as const;

export const AUTH_IGNORED_PATHS = [ROUTE_PATH.INTEGRATION, ROUTE_PATH.CALLBACK];

//  NODE: Order matters here! All routes will render within a <Switch />
//  so the first matching route will be used.
export const routes: RouteInfo[] = [
  {
    exact: true,
    path: ROUTE_PATH.ACCOUNT_SELECT,
    key: SCENE_KEY.ACCOUNT_SELECT,
  },
  {
    exact: true,
    path: ROUTE_PATH.CALLBACK,
    key: SCENE_KEY.CALLBACK,
  },
  {
    exact: true,
    path: ROUTE_PATH.INTEGRATION,
    key: SCENE_KEY.INTEGRATION,
  },
  {
    exact: true,
    path: ROUTE_PATH.DEBUG,
    key: SCENE_KEY.DEBUG,
  },
  {
    exact: true,
    path: ROUTE_PATH.LOG_OUT,
    key: SCENE_KEY.LOG_OUT,
  },
  {
    exact: true,
    path: ROUTE_PATH.MATCHING_CANDIDATES_VARIATION,
    key: SCENE_KEY.MATCHING_CANDIDATES,
  },
  {
    exact: true,
    path: ROUTE_PATH.MATCHING_CANDIDATES,
    key: SCENE_KEY.MATCHING_CANDIDATES,
  },
  {
    exact: true,
    path: ROUTE_PATH.SIMILAR_CANDIDATES_VARIATION,
    key: SCENE_KEY.SIMILAR_CANDIDATES,
  },
  {
    exact: true,
    path: ROUTE_PATH.SIMILAR_CANDIDATES,
    key: SCENE_KEY.SIMILAR_CANDIDATES,
  },
  {
    exact: true,
    path: ROUTE_PATH.BUTTONS,
    key: SCENE_KEY.BUTTONS,
  },
  {
    exact: true,
    path: ROUTE_PATH.ROOT,
    key: SCENE_KEY.ROOT,
  },
];
