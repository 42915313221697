import { UtagActions, MetricsLinkEvent, LinkEvent } from '../../types/metrics';
import { ProfileTypes } from '../../types';

export interface ImpressionEventParams {
  searchResultsTotal: number;
}

export interface InteractionEventParams {
  profileId: number;
  profilePositionOnResults: number;
  profileType: ProfileTypes;
}

export const impressionLinkEvent: MetricsLinkEvent<ImpressionEventParams> = {
  action: UtagActions.Link,
  getValue: ({ params, context }) => ({
    ...context,
    eventName: LinkEvent.TsWidgetBtnSeekSimilarCandidateImpression,
    ...params,
  }),
};

export const interactedLinkEvent: MetricsLinkEvent<InteractionEventParams> = {
  action: UtagActions.Link,
  getValue: ({ params, context }) => ({
    ...context,
    eventName: LinkEvent.TsWidgetBtnSeekSimilarCandidateProfileInteracted,
    ...params,
  }),
};
