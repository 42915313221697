import { Country } from '@seek/melways-sites';

export const getCountry = (region: Country | null) => {
  let country = region;
  if (country === null || country === undefined) {
    // eslint-disable-next-line no-console
    console.warn(`No valid country found under advertiser: ${country ?? null}`);
    // Fallback to AU when no country or country's invalid
    country = 'au';
  }

  return country;
};
