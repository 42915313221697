import React from 'react';
import { Stack, Strong, Text } from 'braid-design-system';

import FriendlyCount from '../../../shared/components/FriendlyCount/FriendlyCount';

import { LayoutOrientation, LayoutSize } from '../types';

interface Props {
  count: number;
  approachableOnly: boolean;
  jobTitle: string;
  layoutSize: LayoutSize;
  layoutOrientation: LayoutOrientation;
  splitLines?: boolean;
}

const pluralise = (text: string, count: number) =>
  text.slice(-1) !== 's' && count !== 1 ? `${text}s` : text;

function Title({
  count,
  approachableOnly,
  jobTitle,
  layoutSize,
  layoutOrientation,
  splitLines,
}: Props) {
  const approachable = approachableOnly ? 'approachable' : '';
  const pluralisedJobTitle = pluralise(jobTitle, count);

  const isLarge = layoutSize === LayoutSize.Large;
  const isHorizontal = layoutOrientation === LayoutOrientation.Horizontal;

  const renderResultsText = () => (
    <Strong>
      <FriendlyCount count={count} /> {approachable} {pluralisedJobTitle}{' '}
    </Strong>
  );

  const renderTalentSearchText = () => <>in SEEK Talent Search</>;

  const renderText = (children: React.ReactNode) => (
    <Text
      truncate
      size={isLarge ? 'standard' : 'small'}
      align={isHorizontal ? 'left' : 'center'}
    >
      {children}
    </Text>
  );

  if (splitLines) {
    return (
      <Stack space="small">
        {renderText(<>{renderResultsText()}</>)}
        {renderText(<>{renderTalentSearchText()}</>)}
      </Stack>
    );
  }

  return renderText(
    <>
      {renderResultsText()} {renderTalentSearchText()}
    </>,
  );
}

export default Title;
