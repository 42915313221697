import React, { useMemo } from 'react';
import { Box, Tiles, Stack } from 'braid-design-system';

import { useSearchParams } from 'src/shared/hooks/useSearchParams';
import { useMetrics } from 'src/shared/hooks/useMetrics';
import { useWindow } from 'src/shared/hooks/useWindow';
import { useWindowSize } from 'src/shared/hooks/useWindowSize';

import CandidateCard from './CandidateCard/CandidateCard';
import Header from './Header/Header';
import ViewAllLinkTextOnly from './ViewAllLink/ViewAllLinkTextOnly';
import SeekLogo from 'src/shared/components/SeekLogo/SeekLogo';
import NoResults from './NoResults/NoResults';
import Container from './Container/Container';
import Loading from './Loading/Loading';

import getViewAllUrl from '../utils/getViewAllUrl';

import { querySchema, layouts } from '../config';

import { LayoutParams } from '../types';
import { CountryCodes, Globals } from 'src/types';

import * as metrics from '../metrics';
import { getCountry } from 'src/shared/utils/generalUtils';
import { usePreferences } from 'src/shared/hooks/usePreferences';
import { useGetTalentSearchUserContextQuery } from 'src/shared/hooks/useTalentSearchContext/gql/GetTalentSearchUserContext.generated';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';
import { useGetSimilarCandidatesSeekAndCombinedQuery } from '../data/GetSimilarCandidatesSeekAndCombined.generated';

function SeekAndCombined() {
  const { dispatchEvent } = useMetrics();
  const { openTalentSearchUrl } = useWindow();
  const { getRegion } = usePreferences();
  const { email, layoutSize } = useSearchParams(querySchema);
  const { identity } = useTscAuth();
  const advertiserId = identity?.advertiserId;

  const layout = useWindowSize<LayoutParams>(layouts[layoutSize]);
  const hirerCountry = useMemo(
    () => getCountry(getRegion()).toUpperCase() as CountryCodes,
    [getRegion],
  );

  // The language is being hardcoded now.
  // This should have been retrieved from search params above when
  // we are ready to accept language from client.
  const language = 'en';

  const {
    data: contextData,
    error: contextError,
    loading: contextLoading,
  } = useGetTalentSearchUserContextQuery({
    skip: !advertiserId,
  });
  const atsName = contextData?.talentSearchUserContext.ats?.name ?? null;

  const {
    loading: similarCandidatesLoading,
    data: similarCandidatesData,
    error: similarCandidatesError,
  } = useGetSimilarCandidatesSeekAndCombinedQuery({
    variables: {
      email,
      isSeedProfileLocationOnly: true,
      first: layout.maxCandidates,
      countryCode: hirerCountry,
      language,
    },
    skip: !advertiserId,
    onCompleted: (data) => {
      dispatchEvent(metrics.impressionLinkEvent, {
        seekAndCombined: true,
        searchResultsTotal: data.combinedProfiles?.profiles.count,
        profileType: 'combined',
      });
      dispatchEvent(metrics.impressionLinkEvent, {
        seekAndCombined: true,
        searchResultsTotal: data?.seekProfiles?.profiles.count,
        profileType: 'seek',
      });
    },
  });

  const loading = !advertiserId || similarCandidatesLoading || contextLoading;

  const combinedCount =
    similarCandidatesData?.combinedProfiles?.profiles.count || 0;
  const combinedProfiles =
    similarCandidatesData?.combinedProfiles?.profiles.items || [];
  const combinedSeedProfile =
    similarCandidatesData?.combinedProfiles?.seedProfile;

  const seekCount = similarCandidatesData?.seekProfiles?.profiles.count || 0;
  const seekProfiles =
    similarCandidatesData?.seekProfiles?.profiles.items || [];
  const seekSeedProfile = similarCandidatesData?.seekProfiles?.seedProfile;

  const handleCandidateClicked = (
    eventParams: metrics.InteractionEventParams,
  ) => {
    const { profileId, profilePositionOnResults, profileType } = eventParams;
    const profilePath = `${Globals.ProfilePathname}/${profileId}?profileType=${profileType}`;

    dispatchEvent(metrics.interactedLinkEvent, {
      searchResultsTotal: seekCount,
      profileId,
      profilePositionOnResults,
      profileType,
    });
    openTalentSearchUrl(profilePath);
  };

  const error = similarCandidatesError || contextError;
  if (error || (!loading && seekCount === 0 && combinedCount === 0)) {
    return <NoResults />;
  }

  return (
    <Box
      data-widget-id="similarCandidates"
      data-widget-variant="seekAndCombined"
      padding="medium"
    >
      <Container layoutSize={layoutSize}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Stack space="large">
              {combinedCount > 0 && (
                <Box data-widget-result="combined">
                  <Stack space="medium">
                    <Header
                      layoutSize={layoutSize}
                      count={combinedCount}
                      foundInText={`found in both ${
                        atsName ?? 'your database'
                      } and SEEK Talent Search`}
                    />
                    <Tiles
                      columns={layout.columns}
                      dividers={false}
                      space="medium"
                    >
                      {combinedProfiles
                        .slice(0, layout.maxCandidates)
                        .map((profile, index) => (
                          <CandidateCard
                            key={profile.profileId}
                            profile={profile}
                            index={index}
                            onCandidateCardClicked={handleCandidateClicked}
                            hirerCountry={hirerCountry}
                            atsName={atsName}
                          />
                        ))}
                    </Tiles>
                    {combinedSeedProfile && (
                      <ViewAllLinkTextOnly
                        layoutSize={layoutSize}
                        onLinkClicked={() => {
                          const url = getViewAllUrl(combinedSeedProfile, {
                            seekProfilesOnly: false,
                          });
                          openTalentSearchUrl(url);
                        }}
                      />
                    )}
                  </Stack>
                </Box>
              )}
              {seekCount > 0 && (
                <Box data-widget-result="seekOnly">
                  <Stack space="medium">
                    <Header
                      layoutSize={layoutSize}
                      count={seekCount}
                      foundInText="found in only SEEK Talent Search"
                    />
                    <Tiles
                      columns={layout.columns}
                      dividers={false}
                      space="medium"
                    >
                      {seekProfiles
                        .slice(0, layout.maxCandidates)
                        .map((profile, index) => (
                          <CandidateCard
                            key={profile.profileId}
                            profile={profile}
                            index={index}
                            onCandidateCardClicked={handleCandidateClicked}
                            hirerCountry={hirerCountry}
                            atsName={atsName}
                          />
                        ))}
                    </Tiles>
                    <Container layoutSize={layoutSize}>
                      {seekSeedProfile && (
                        <ViewAllLinkTextOnly
                          layoutSize={layoutSize}
                          onLinkClicked={() => {
                            const url = getViewAllUrl(seekSeedProfile, {
                              seekProfilesOnly: true,
                            });
                            openTalentSearchUrl(url);
                          }}
                        />
                      )}
                      <SeekLogo />
                    </Container>
                  </Stack>
                </Box>
              )}
            </Stack>
          </>
        )}
      </Container>
    </Box>
  );
}

export default SeekAndCombined;
