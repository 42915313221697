import { ComponentProps } from 'react';
import { Tiles } from 'braid-design-system';

import {
  TalentSearchSimilarCandidatesResultV2,
  TalentSearchSimilarCandidatesByIdInputV2,
} from '../../types';

type TilesProps = ComponentProps<typeof Tiles>;

export enum LayoutSize {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

export interface LayoutParams {
  columns: TilesProps['columns'];
  maxCandidates: number;
}

export type LayoutDefinitions = Record<
  LayoutSize,
  | Array<(width: number, height: number) => LayoutParams | boolean>
  | LayoutParams
>;

export type SeekAndCombinedProfilesVariables = Pick<
  TalentSearchSimilarCandidatesByIdInputV2,
  'email' | 'isSeedProfileLocationOnly' | 'first' | 'countryCode'
> & { language?: string };
export type SeekOrCombinedProfilesVariables = Pick<
  TalentSearchSimilarCandidatesByIdInputV2,
  | 'email'
  | 'profileTypes'
  | 'isSeedProfileLocationOnly'
  | 'first'
  | 'countryCode'
> & { language?: string };

export interface SeekAndCombinedProfilesResponse {
  seekProfiles: TalentSearchSimilarCandidatesResultV2;
  combinedProfiles: TalentSearchSimilarCandidatesResultV2;
}

export interface SeekOrCombinedProfilesResponse {
  seekOrCombinedProfiles: TalentSearchSimilarCandidatesResultV2;
}
