import { ProfileSalaryV2, SalaryFrequency } from 'src/types/generated';
import { CountryCodes } from '../../../types';
import { DEFAULT_SALARY_TYPE_FROM_COUNTRY_CODES } from 'src/shared/utils/SalaryUtils';
import { format } from '@seek/currency';
import { ProfileFieldsFragment } from '../data/ProfileFields.fragment.generated';

export function formatJobTitle({
  workHistories,
  currentJobTitle,
}: ProfileFieldsFragment) {
  const companyName = (workHistories || [])[0]?.companyName || '-';
  const jobTitle = currentJobTitle || '-';

  return `${jobTitle} at ${companyName}`;
}

function getTodayAsDate() {
  return new Date(Math.floor(Date.now() / 1000) * 1000);
}

function addDays(date: Date, daysToAdd: number) {
  const result: Date = new Date(date);
  result.setDate(date.getDate() + daysToAdd);
  return result;
}

function addMonths(date: Date, monthsToAdd: number) {
  const result: Date = new Date(date);
  result.setMonth(date.getMonth() + monthsToAdd);
  return result;
}

export function formatLastUpdated({ lastModifiedDate }: ProfileFieldsFragment) {
  if (!lastModifiedDate) {
    return '-';
  }

  const date: Date = new Date(lastModifiedDate);
  const now: Date = getTodayAsDate();

  if (addDays(date, 1) >= now) {
    return 'today';
  }
  if (addDays(date, 2) >= now) {
    return '1 day ago';
  }
  if (addDays(date, 3) >= now) {
    return '2 days ago';
  }
  if (addDays(date, 4) >= now) {
    return '3 days ago';
  }
  if (addDays(date, 5) >= now) {
    return '4 days ago';
  }
  if (addDays(date, 6) >= now) {
    return '5 days ago';
  }
  if (addDays(date, 7) >= now) {
    return '6 days ago';
  }
  if (addDays(date, 8) >= now) {
    return '1 wk ago';
  }
  if (addDays(date, 15) >= now) {
    return '2 wks ago';
  }
  if (addDays(date, 22) >= now) {
    return '3 wks ago';
  }
  if (addMonths(date, 1) >= now) {
    return '1 mth ago';
  }
  if (addMonths(date, 3) >= now) {
    return '3 mths ago';
  }
  if (addMonths(date, 6) >= now) {
    return '6 mths ago';
  }
  if (addMonths(date, 9) >= now) {
    return '9 mths ago';
  }
  if (addMonths(date, 12) >= now) {
    return '1 yr ago';
  }

  return 'over a yr ago';
}

export function formatSalary(
  salary: ProfileSalaryV2 | null | undefined,
  country: CountryCodes,
) {
  const mappedSalaryType: Record<string, string> = {
    [SalaryFrequency.Annual]: 'Annually',
    [SalaryFrequency.Monthly]: 'Monthly',
    [SalaryFrequency.Hourly]: 'Hourly',
  };

  const salaryType = DEFAULT_SALARY_TYPE_FROM_COUNTRY_CODES[country];

  if (salary && salary.expected) {
    for (const salaryAmount of salary.expected.amount) {
      if (salaryAmount.frequency === salaryType) {
        return `${salary.expected.currency} ${format(salaryAmount.value, {
          currency: salary.expected.currency,
          display: 'noSymbol',
          locale: 'en-AU',
          notation: 'compact',
        })}+ ${mappedSalaryType[salaryType].toLowerCase()}`;
      }
    }
  }

  return `${mappedSalaryType[salaryType]} -`;
}

export function formatLocation(profile: ProfileFieldsFragment) {
  return `${profile?.currentLocation || '-'}`;
}
