import { CountryCodes, ProfileTypes } from 'src/types';
import extractLocationList from '../utils/extractLocationList';
import { useGetLocationsQuery } from './GetLocations.generated';
import { useGetTalentSearchUncoupledCountQuery } from './GetTalentSearchUncoupledCount.generated';
import getLinkEventPayload from '../utils/getLinkEventPayload';
import { useMetrics } from 'src/shared/hooks/useMetrics';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';
import * as metrics from '../metrics';

interface Props {
  location: string;
  country: string;
  jobTitle: string;
  approachableOnly: boolean;
  profileTypes: ProfileTypes[];
  hirerCountryCode: CountryCodes;
}

export const useGetSeekOrCombinedProfiles = ({
  location,
  country,
  jobTitle,
  approachableOnly,
  profileTypes,
  hirerCountryCode: countryCode,
}: Props) => {
  const { dispatchEvent } = useMetrics();
  const { identity } = useTscAuth();
  const advertiserId = identity?.advertiserId;

  const { data: locationsData, loading: locationsLoading } =
    useGetLocationsQuery({
      variables: { location, country },
      skip: !advertiserId,
    });

  const locationList = extractLocationList(locationsData);

  const { data: seekOrCombinedData, loading: seekOrCombinedLoading } =
    useGetTalentSearchUncoupledCountQuery({
      variables: {
        input: {
          uncoupledFreeText: jobTitle,
          locationList,
          approachable: Boolean(approachableOnly),
          countryCode,
          ...(profileTypes && { profileTypes }),
        },
      },
      skip: !advertiserId || locationsLoading,
      onCompleted: (data) => {
        dispatchEvent(
          metrics.impressionLinkEvent,
          getLinkEventPayload({
            count: data.talentSearchUncoupledCountForQueryV2,
            locationList,
            jobTitle,
            approachableOnly,
            profileTypes,
          }),
        );
      },
    });

  return {
    loading: seekOrCombinedLoading || locationsLoading,
    seekOrCombinedProfilesCount:
      seekOrCombinedData?.talentSearchUncoupledCountForQueryV2 || 0,
    locationList,
  };
};
