import Callback from './Callback';
import Integration from './Integration';
import MatchingCandidates from './MatchingCandidates';
import SimilarCandidates from './SimilarCandidates';
import Buttons from './Buttons';
import { Root } from './Root/Root';
import { AccountSelectScene } from './AccountSelect/AccountSelectScene';

import { SCENE_KEY } from '../sharedConfig';
import { DebugScene } from './Debug/DebugScene';
import { LogOut } from './LogOut/LogOut';

//  Add all scenes to this export.
//  To see the current shared config see the sharedConfig.js file.
const scenes = {
  [SCENE_KEY.ACCOUNT_SELECT]: AccountSelectScene,
  [SCENE_KEY.CALLBACK]: Callback,
  [SCENE_KEY.DEBUG]: DebugScene,
  [SCENE_KEY.INTEGRATION]: Integration,
  [SCENE_KEY.LOG_OUT]: LogOut,
  [SCENE_KEY.MATCHING_CANDIDATES]: MatchingCandidates,
  [SCENE_KEY.SIMILAR_CANDIDATES]: SimilarCandidates,
  [SCENE_KEY.BUTTONS]: Buttons,
  [SCENE_KEY.ROOT]: Root,
};

export default scenes;
