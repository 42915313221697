import React, { useCallback, useEffect, useState } from 'react';

import TscAuthContext from './tsc-auth-context';
import { initialTscAuthState } from './tsc-auth-state';
import type {
  TscAuthContextInterface,
  TscAuthProviderProps,
  TscAuthState,
} from './types';
import { useAuthN } from './use-authN';
import { getAccountSelectionUrl } from './utils/getAccountSelectionUrl';

export const TscAuthProvider = ({
  children,
  context = TscAuthContext,
  authenticationLevel = 'None',
  canonicalUrl: providerCanonicalUrl,
}: TscAuthProviderProps) => {
  const [state, setState] = useState<TscAuthState>(initialTscAuthState);
  const authN = useAuthN();

  const { authenticated, loading, identity } = state;

  const advertiserId = identity?.advertiserId;

  const getToken: TscAuthContextInterface['getToken'] = useCallback(
    async (force) => {
      try {
        const token = (await authN.getToken({ ignoreCache: force })) ?? null;

        if (token === null) {
          throw new Error('Cannot get token');
        }

        // selective state change to avoid unnecessary re-renders
        const newState = {
          authenticated: true,
          identity: await authN.getIdentity(),
          loading: false,
        };

        if (
          authenticated !== newState.authenticated ||
          loading !== newState.loading
        ) {
          setState(newState);
        }
        return token;
      } catch (err) {
        // selective state change to avoid unnecessary re-renders
        const newState = {
          authenticated: false,
          loading: false,
          identity: null,
        };

        if (
          authenticated !== newState.authenticated ||
          loading !== newState.loading
        ) {
          setState(newState);
        }
      }

      return null;
    },
    [authN, authenticated, loading],
  );

  const login: TscAuthContextInterface['login'] = useCallback(
    async (_options) => {
      await authN.loginWithPopup();
      await getToken();
    },
    [authN, getToken],
  );

  const logout: TscAuthContextInterface['logout'] = useCallback(
    (options) => {
      const logoutRedirect = options?.returnPath
        ? `?returnTo=${encodeURIComponent(options.returnPath)}`
        : '';
      authN.logout(`${window.location.origin}${logoutRedirect}`);
    },
    [authN],
  );

  const authenticate: TscAuthContextInterface['authenticate'] = useCallback(
    async ({ level, canonicalUrl }) => {
      if (loading || level === 'None') {
        return;
      }

      if (!authenticated) {
        // Not signed in
        const redirectUrl = `/?returnUrl=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}`;
        window.location.assign(redirectUrl);

        return;
      }

      if (level === 'Hirer') {
        if (!advertiserId) {
          const url = getAccountSelectionUrl({ canonicalUrl });
          window.location.assign(url);
        }
      }
    },
    [authenticated, loading, advertiserId],
  );

  useEffect(() => {
    const fn = async () => {
      await getToken();
      await authenticate({
        level: authenticationLevel,
        canonicalUrl: providerCanonicalUrl,
      });
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fn();
  }, [getToken, authenticate, authenticationLevel, providerCanonicalUrl]);

  if (loading) {
    return null;
  }

  return (
    <context.Provider
      value={{
        ...state,
        getToken,
        login,
        logout,
        authenticate,
      }}
    >
      {children}
    </context.Provider>
  );
};
