import * as Types from '../../../types/generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTalentSearchAccountsQueryVariables = Types.Exact<{
  returnUri: Types.Scalars['String']['input'];
}>;

export type GetTalentSearchAccountsQuery = {
  talentSearchAccounts?: {
    items: Array<{
      advertiserId: number;
      advertiserName: string;
      hasConnectedTalentSearchAccess: boolean;
      parentAdvertiserId?: number | null;
      redirectUrl: string;
    }>;
  } | null;
};

export const GetTalentSearchAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTalentSearchAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'returnUri' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentSearchAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'advertiserId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'advertiserName' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'hasConnectedTalentSearchAccess',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentAdvertiserId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'redirectUrl' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'returnUri' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'returnUri' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetTalentSearchAccountsQuery__
 *
 * To run a query within a React component, call `useGetTalentSearchAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentSearchAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentSearchAccountsQuery({
 *   variables: {
 *      returnUri: // value for 'returnUri'
 *   },
 * });
 */
export function useGetTalentSearchAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTalentSearchAccountsQuery,
    GetTalentSearchAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTalentSearchAccountsQuery,
    GetTalentSearchAccountsQueryVariables
  >(GetTalentSearchAccountsDocument, options);
}
export function useGetTalentSearchAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTalentSearchAccountsQuery,
    GetTalentSearchAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTalentSearchAccountsQuery,
    GetTalentSearchAccountsQueryVariables
  >(GetTalentSearchAccountsDocument, options);
}
export type GetTalentSearchAccountsQueryHookResult = ReturnType<
  typeof useGetTalentSearchAccountsQuery
>;
export type GetTalentSearchAccountsLazyQueryHookResult = ReturnType<
  typeof useGetTalentSearchAccountsLazyQuery
>;
export type GetTalentSearchAccountsQueryResult = Apollo.QueryResult<
  GetTalentSearchAccountsQuery,
  GetTalentSearchAccountsQueryVariables
>;
