import React from 'react';

import SeekLogo from '../../../shared/components/SeekLogo/SeekLogo';

import { LayoutSize } from '../types';

interface Props {
  layoutSize: LayoutSize;
}

function Logo({ layoutSize }: Props) {
  return <SeekLogo height={layoutSize === LayoutSize.Large ? 40 : 30} />;
}

export default Logo;
