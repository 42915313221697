import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { AuthRoute } from '../../shared/components/AuthRoute/AuthRoute';

import SeekOrCombined from './components/SeekOrCombined';
import SeekAndCombined from './components/SeekAndCombined';

const VARIATIONS = {
  SEEK_AND_COMBINED: 'seekAndCombined',
  SEEK_OR_COMBINED: 'seekOrCombined',
} as const;

type Variations = (typeof VARIATIONS)[keyof typeof VARIATIONS];

const variations = {
  [VARIATIONS.SEEK_AND_COMBINED]: SeekAndCombined,
  [VARIATIONS.SEEK_OR_COMBINED]: SeekOrCombined,
};

function Scene() {
  const { path } = useRouteMatch();
  const { variation }: { variation: Variations } = useParams();

  const Variation =
    variations?.[variation] || variations[VARIATIONS.SEEK_OR_COMBINED];

  return (
    <AuthRoute exact path={path}>
      <Variation />
    </AuthRoute>
  );
}

export default Scene;
