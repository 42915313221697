import React, { useMemo } from 'react';
import { Box, Tiles, Stack } from 'braid-design-system';

import { useSearchParams } from 'src/shared/hooks/useSearchParams';
import { useMetrics } from 'src/shared/hooks/useMetrics';
import { useWindow } from 'src/shared/hooks/useWindow';
import { useWindowSize } from 'src/shared/hooks/useWindowSize';

import CandidateCard from './CandidateCard/CandidateCard';
import Header from './Header/Header';
import ViewAllLink from './ViewAllLink/ViewAllLink';
import NoResults from './NoResults/NoResults';
import Container from './Container/Container';
import Loading from './Loading/Loading';

import getViewAllUrl from '../utils/getViewAllUrl';

import { querySchema, layouts } from '../config';

import { LayoutParams } from '../types';
import { CountryCodes, Globals, ProfileTypes } from 'src/types';

import * as metrics from '../metrics';
import { getCountry } from 'src/shared/utils/generalUtils';
import { usePreferences } from 'src/shared/hooks/usePreferences';
import { useGetSimilarCandidatesSeekOrCombinedQuery } from '../data/GetSimilarCandidatesSeekOrCombined.generated';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';
import { useGetTalentSearchUserContextQuery } from 'src/shared/hooks/useTalentSearchContext/gql/GetTalentSearchUserContext.generated';

function SeekOrCombined() {
  const { dispatchEvent } = useMetrics();

  const { openTalentSearchUrl } = useWindow();

  const { email, seekProfilesOnly, layoutSize } = useSearchParams(querySchema);
  const { identity, loading: authLoading } = useTscAuth();
  const advertiserId = identity?.advertiserId;

  // The language is being hardcoded now.
  // This should have been retrieved from search params above when
  // we are ready to accept language from client.
  const language = 'en';

  const { getRegion } = usePreferences();
  const layout = useWindowSize<LayoutParams>(layouts[layoutSize]);

  const profileTypes = useMemo(
    () => (seekProfilesOnly ? [ProfileTypes.Seek] : [ProfileTypes.Combined]),
    [seekProfilesOnly],
  );
  const hirerCountry = useMemo(
    () => getCountry(getRegion()).toUpperCase() as CountryCodes,
    [getRegion],
  );

  const {
    data: contextData,
    error: contextError,
    loading: contextLoading,
  } = useGetTalentSearchUserContextQuery({
    skip: !advertiserId,
  });
  const atsName = contextData?.talentSearchUserContext.ats?.name ?? null;

  const {
    loading: similarCandidatesLoading,
    data: similarCandidatesData,
    error: similarCandidatesError,
  } = useGetSimilarCandidatesSeekOrCombinedQuery({
    variables: {
      email,
      profileTypes,
      isSeedProfileLocationOnly: true,
      first: layout.maxCandidates,
      countryCode: hirerCountry,
      language,
    },
    skip: !advertiserId,
    onCompleted: (data) => {
      dispatchEvent(metrics.impressionLinkEvent, {
        seekAndCombined: false,
        searchResultsTotal: data?.seekOrCombinedProfiles?.profiles.count,
      });
    },
  });

  const seedProfile =
    similarCandidatesData?.seekOrCombinedProfiles?.seedProfile ?? null;
  const profiles =
    similarCandidatesData?.seekOrCombinedProfiles?.profiles?.items ?? [];
  const count = similarCandidatesData?.seekOrCombinedProfiles?.profiles.count;

  const handleCandidateClicked = (
    eventParams: metrics.InteractionEventParams,
  ) => {
    const { profileId, profilePositionOnResults, profileType } = eventParams;
    const profilePath = `${Globals.ProfilePathname}/${profileId}?profileType=${profileType}`;

    dispatchEvent(metrics.interactedLinkEvent, {
      searchResultsTotal: count,
      profileId,
      profilePositionOnResults,
      profileType,
    });
    openTalentSearchUrl(profilePath);
  };

  const loading =
    !advertiserId || contextLoading || similarCandidatesLoading || authLoading;

  const error = contextError || similarCandidatesError;
  if (error || (!loading && profiles.length === 0)) {
    return <NoResults />;
  }

  return (
    <Box
      data-widget-id="similarCandidates"
      data-widget-variant="seekOrCombined"
      padding="medium"
    >
      <Container layoutSize={layoutSize}>
        {loading ? (
          <Loading />
        ) : (
          <Box data-widget-result={seekProfilesOnly ? 'seekOnly' : 'combined'}>
            <Stack space="medium">
              <Header
                layoutSize={layoutSize}
                count={count}
                foundInText="found in SEEK Talent Search"
              />
              <Tiles columns={layout.columns} dividers={false} space="medium">
                {profiles
                  .slice(0, layout.maxCandidates)
                  .map((profile, index) => (
                    <CandidateCard
                      key={profile.profileId}
                      profile={profile}
                      index={index}
                      onCandidateCardClicked={handleCandidateClicked}
                      hirerCountry={hirerCountry}
                      atsName={atsName}
                    />
                  ))}

                {seedProfile && (
                  <ViewAllLink
                    layoutSize={layoutSize}
                    onLinkClicked={() => {
                      const url = getViewAllUrl(seedProfile, {
                        seekProfilesOnly,
                      });
                      openTalentSearchUrl(url);
                    }}
                  />
                )}
              </Tiles>
            </Stack>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default SeekOrCombined;
