import { Box, Inline, Strong, Text } from 'braid-design-system';
import React from 'react';

import * as styles from './ImpersonationBanner.css';
import { useTscAuth } from 'src/shared/hooks/useTscAuth';
import { IdentitySubject } from 'node_modules/@seek/online-identity/lib-types';

const getActorDisplayText = ({ email }: IdentitySubject): string => {
  let displayText = 'Operator';
  if (email && email.indexOf('@') > -1) {
    displayText = email.substring(0, email.indexOf('@'));
  }
  return displayText;
};

export const ImpersonationBanner = () => {
  const { identity } = useTscAuth();
  const actor = identity?.act;
  if (!identity || !actor) {
    return null;
  }

  return (
    <Box className={styles.container} zIndex="sticky">
      <Box
        id="impersonation-banner"
        padding="small"
        background="brandAccent"
        textAlign="center"
      >
        <Inline alignY="center" space="small" align="center">
          <Text size="small">
            <Strong>{getActorDisplayText(actor)}</Strong> is impersonating{' '}
            <Strong>{identity.sub.email}</Strong>
          </Text>
        </Inline>
      </Box>
    </Box>
  );
};
