import { Country } from '@seek/melways-sites';

interface RegexMapper {
  regex: RegExp;
  country: Country;
  fallback: string;
}

interface Output {
  inferredCountry: Country;
  inferredLocation: string;
}

const countryNameMap = new Map<Country, string>([
  ['au', 'australia'],
  ['nz', 'new zealand'],
  ['my', 'malaysia'],
  ['sg', 'singapore'],
  ['ph', 'philippines'],
  ['id', 'indonesia'],
  ['hk', 'hong kong'],
  ['th', 'thailand'],
]);

const regexList: RegexMapper[] = [
  {
    regex: new RegExp(/\b(au|aus|australia)\b/),
    country: 'au',
    fallback: `all ${countryNameMap.get('au')}`,
  },
  {
    regex: new RegExp(/\b(nz|nzl|new zealand)\b/),
    country: 'nz',
    fallback: `all ${countryNameMap.get('nz')}`,
  },
  {
    regex: new RegExp(/\b(my|mys|malaysia)\b/),
    country: 'my',
    fallback: `all ${countryNameMap.get('my')}`,
  },
  {
    regex: new RegExp(/\b(sg|sgp|singapore)\b/),
    country: 'sg',
    fallback: `all ${countryNameMap.get('sg')}`,
  },
  {
    regex: new RegExp(/\b(ph|phl|philippines)\b/),
    country: 'ph',
    fallback: `all ${countryNameMap.get('ph')}`,
  },
  {
    regex: new RegExp(/\b(id|idn|indonesia)\b/),
    country: 'id',
    fallback: `all ${countryNameMap.get('id')}`,
  },
  {
    regex: new RegExp(/\b(hk|hkg|hong kong)\b/),
    country: 'hk',
    fallback: `all ${countryNameMap.get('hk')}`,
  },
  {
    regex: new RegExp(/\b(th|tha|thailand)\b/),
    country: 'th',
    fallback: `all ${countryNameMap.get('th')}`,
  },
];

function inferCountryFromLocation(
  input: string,
  fallbackCountry?: Country | null,
): Output {
  const sanitizedInput = decodeURIComponent(input.toLowerCase())
    .replace(/[^\w\s]/gi, '')
    .trim();

  if (sanitizedInput === '') {
    const inferredCountry = fallbackCountry ?? 'au';
    return {
      inferredCountry,
      inferredLocation: `all ${countryNameMap.get(inferredCountry)}`,
    };
  }

  return regexList.reduce(
    (acc: Output, { regex, country, fallback }) => {
      const match = sanitizedInput.match(regex);
      if (match?.length) {
        const [strMatch] = match;
        return {
          inferredCountry: country,
          inferredLocation:
            strMatch === sanitizedInput
              ? fallback
              : sanitizedInput.replace(strMatch, '').trim(),
        };
      }
      return acc;
    },
    {
      inferredCountry: fallbackCountry ?? 'au',
      inferredLocation: sanitizedInput,
    },
  );
}

export default inferCountryFromLocation;
