import React, { useState } from 'react';
import 'braid-design-system/reset';
import { BraidProvider } from 'braid-design-system';
import seekAnzTheme from 'braid-design-system/themes/seekAnz';
import { Switch, Route, Redirect } from 'react-router-dom';

import Providers from './components/Providers/Providers';
import Branch from './components/Branch/Branch';
import ErrorBoundaryHandler from './components/ErrorBoundary/ErrorBoundaryHandler';

import { ParentWindowProvider } from '../shared/hooks/useParentWindow';

import { PreferencesProvider } from '../shared/hooks/usePreferences';
import { useAppConfig } from '../shared/hooks/useAppConfig';
import { DatadogRumProvider } from '../shared/hooks/useDatadogRum';

import { routes } from '../sharedConfig';
import scenes from '../scenes';

import './global.css';

function App() {
  const [isBranchView, setIsBranchView] = useState<boolean>(false);
  const { appVersion, branchName } = useAppConfig();

  return (
    <Providers
      components={[
        DatadogRumProvider, // <-- in order to track all requests, Datadog RUM should get inialised as early as possible.
        [BraidProvider, { theme: seekAnzTheme }],
        ParentWindowProvider,
        PreferencesProvider,
      ]}
    >
      <ErrorBoundaryHandler>
        {isBranchView && (
          <Branch appVersion={appVersion} branchName={branchName} />
        )}
        <Switch>
          <Route
            exact
            path="/index-*.html"
            render={({ location: { hash } }) => {
              setIsBranchView(true);
              const redirectUrl = hash.substring(1, hash.length);
              return <Redirect to={`/${redirectUrl}`} />;
            }}
          />

          {routes.map(({ path, key, exact }) => {
            const SceneView = scenes[key];
            return (
              <Route key={key} path={path} exact={exact}>
                <SceneView />
              </Route>
            );
          })}
        </Switch>
      </ErrorBoundaryHandler>
    </Providers>
  );
}

export default App;
