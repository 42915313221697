import { BraidButtonSize } from '../../../types';

import { LayoutSize } from '../types';

const sizeMap: Record<string, unknown> = {
  [LayoutSize.Large]: 'standard',
  [LayoutSize.Medium]: 'small',
};

function getCustomButtonSize(layoutSize: LayoutSize): BraidButtonSize {
  let sizeAsProp;
  if (layoutSize in sizeMap) {
    sizeAsProp = sizeMap[layoutSize];
  } else {
    sizeAsProp = sizeMap[LayoutSize.Large];
  }
  return sizeAsProp as BraidButtonSize;
}

export default getCustomButtonSize;
