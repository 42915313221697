import {
  siteNames,
  siteConfig,
  SiteNamesMatchingClassification,
} from '@seek/melways-sites';
import { oauth, Realm } from '@seek/oauth-url-builders';

import { usePreferences } from '../usePreferences';
import { useCallback } from 'react';
import { getCountry } from 'src/shared/utils/generalUtils';
import { useAppConfig } from '../useAppConfig';
import { Environment } from 'src/types';
import { useAuthN } from '../useTscAuth';

export type EmployerSiteName = SiteNamesMatchingClassification<{
  product: 'employer';
}>;

function useWindow() {
  const authN = useAuthN();
  const { getRegion } = usePreferences();
  const { environment } = useAppConfig();

  return {
    openTalentSearchUrl: useCallback(
      async (location: string) => {
        const user = await authN.getUser();
        const realm: Realm = user.sub.startsWith('auth0|')
          ? 'Username-Password-Authentication'
          : 'seek-okta-global';

        const scope = [
          ...(user['http://seek/claims/advertiser_id']
            ? [`advertiser:${user['http://seek/claims/advertiser_id']}`]
            : []),
          ...(user['http://seek/claims/act_as/email']
            ? [`act:${user['http://seek/claims/act_as/email']}`]
            : []),
        ];

        const returnUri = `/talentsearch/${location}?referrer=tsc_widget`;

        const country = getCountry(getRegion());
        const siteName = siteNames.employer.seek[country];
        const origin = `https://${
          environment === Environment.Production
            ? siteConfig[siteName].host
            : siteConfig[siteName].stagingHost
        }`;

        const url = oauth.buildLoginWithScopeUrl({
          origin,
          realm,
          returnUri,
          scope,
        });

        window.open(url);
        window.opener = null;
        return false;
      },
      [authN, getRegion, environment],
    ),
  };
}

export default useWindow;
