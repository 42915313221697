import { Button } from 'braid-design-system/lib/components/Button/Button';

import {
  TalentSearchUserContext,
  LocationSearchResult as Location,
  TalentSearchProfileResultsV2,
} from './types/generated';

import { SCENE_KEY } from './sharedConfig';

export {
  TalentSearchAccount,
  TalentSearchAccounts,
  ProfileTypes,
  TalentSearchUncoupledCountForQueryInputV2,
  TalentSearchProfileResultsV2,
  ProfilesByEmailInput,
  TalentSearchSimilarCandidatesByIdInputV2,
  TalentSearchSimilarCandidatesResultV2,
} from './types/generated';

export { Location };

export type RouteInfo = {
  exact: boolean;
  path: string;
  key: (typeof SCENE_KEY)[keyof typeof SCENE_KEY];
};

export interface TalentSearchProfileResponse {
  profiles: TalentSearchProfileResultsV2;
}
export interface TalentSearchUserContextResponse {
  userContext: TalentSearchUserContext;
}

declare global {
  interface Window {
    APP: AppConfig;
    utag: {
      view: (payload: unknown) => void;
      link: (payload: unknown) => void;
    };
    utag_data: unknown;
    utag_cfg_ovrd: unknown;
  }
}

export enum CountryCodes {
  AU = 'AU',
  NZ = 'NZ',
  HK = 'HK',
  TH = 'TH',
  MY = 'MY',
  SG = 'SG',
  ID = 'ID',
  PH = 'PH',
}

export enum Globals {
  AllAULocationId = '3000',
  AllNZLocationId = '3001',
  UncoupledSearchPathname = 'search',
  UncoupledSearchResultsPathname = 'search/profiles',
  ProfilePathname = 'profile',
  SimilarCandidatesPathName = 'similar-candidates',
  MaxCountToDisplay = 500,
}

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export enum GraphqlUri {
  Development = 'http://localhost:4000/graphql',
  Staging = 'https://talent.staging.seek.com.au/graphql',
  Production = 'https://talent.seek.com.au/graphql',
}

export interface AppConfig {
  appVersion: string;
  branchName: string;
  environment: Environment;
  graphqlUri: GraphqlUri;
  tealiumUrl: string;
  metricsForwarderUrl: string;
}

export enum APIErrorCode {
  Unauthorised = 'UNAUTHENTICATED',
}

export interface ClientContext {
  appConfig: AppConfig;
}

//  Can extend this to accept required or default params
export interface DefaultQuerySchema {
  [key: string]: {
    type: any;
    defaultValue?: any;
    required?: boolean;
    pattern?: RegExp;
    oneOf?: string[];
    helpText?: {
      pattern?: string;
      required?: string;
      oneOf?: string;
    };
  };
}

export type QuerySchema<T extends DefaultQuerySchema> = {
  [K in keyof T]: ReturnType<T[K]['type']>;
};

export enum ErrorCode {
  NoConnectedTalentSearchAccess = 'no_connected_talent_search_access',
  NoATSRelationship = 'no_ats_relationship',
  Unknown = 'unknown',
}

export type BraidButtonSize = Pick<
  React.ComponentProps<typeof Button>,
  'size'
>['size'];
export type CustomButtonSize = BraidButtonSize | 'xsmall';
