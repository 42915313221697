import {
  type ClientConfig,
  talentsearch_seek_com_au,
  talentsearch_staging_seek_com_au,
} from '@seek/online-identity';
import type {
  AuthNConfigMapping,
  Location,
} from '@seek/online-identity/lib-types/modules/domainConfiguration/types';

export const getTscConfig = (host: string): ClientConfig => getConfig({ host });

const getConfig = ({ host }: Location): ClientConfig => {
  // Supporting dev env urls with :port, e.g., au.employer.seek.com.local:3000
  const hostName = (host ? host.split(':')[0] : host) as string;

  if (Object.keys(mappings).includes(hostName)) {
    return mappings[hostName] as ClientConfig;
  }

  const baseConfig = talentsearch_staging_seek_com_au;

  return {
    ...baseConfig,
    ...(host && { clientDomain: host }),
  };
};

const mappings: AuthNConfigMapping = (() => {
  const result: AuthNConfigMapping = {};
  const configs = [talentsearch_staging_seek_com_au, talentsearch_seek_com_au];

  for (const config of configs) {
    result[config.clientDomain] = config;
  }

  return result;
})();
